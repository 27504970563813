import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAXDRYV_c6zKlhnmZSYCu9i0eBhNDJfo94",
  authDomain: "alg-frontend.firebaseapp.com",
  projectId: "alg-frontend",
  storageBucket: "alg-frontend.appspot.com",
  messagingSenderId: "727083865163",
  appId: "1:727083865163:web:ac807ede63fd56689e29ce",
  measurementId: "G-J59TH8LBYT",
};
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export const createAdminProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const adminRef = firestore.doc(`admins/${userAuth.uid}`);

  const snapShot = await adminRef.get();
  if (!snapShot.exists) {
    const { name, email } = userAuth;
    const createdAt = new Date();
    try {
      await adminRef.set({
        name,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      console.log("error creating admin", error.message);
    }
  }
  return adminRef;
};

export const uploadLot = async (lotObj) => {
  const lotRef = firestore.doc(`lots/${lotObj.lotNo}`);
  const snapShot = await lotRef.get();
  if (!snapShot.exists) {
    try {
      await lotRef.set({
        ...lotObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await lotRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a lot with this given Lot no, please change the Lot no and try again"
    );
    return null;
  }
};
export const createNotice = async (noticeObj) => {
  const noticeRef = firestore.doc(`notices/${noticeObj.id}`);
  const snapShot = await noticeRef.get();
  if (!snapShot.exists) {
    try {
      await noticeRef.set({
        ...noticeObj,
      });

      const uploadedSnapShot = await noticeRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert("there is already a Notice with similar Id, please try again later");
  }
};
export const createBlog = async (blogObj) => {
  const blogRef = firestore.doc(`blogs/${blogObj.id}`);
  const snapShot = await blogRef.get();
  if (!snapShot.exists) {
    try {
      await blogRef.set({
        ...blogObj,
      });

      const uploadedSnapShot = await blogRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert("there is already a Blogs with similar Id, please try again later");
  }
};
export const createIntro = async (introObj) => {
  const introRef = firestore.doc(`intros/${introObj.id}`);
  const snapShot = await introRef.get();

  delete introObj.file;
  if (!snapShot.exists) {
    try {
      await introRef.set({
        ...introObj,
      });

      const uploadedSnapShot = await introRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already an Intro image with similar Id, please try again later"
    );
  }
};
export const uploadExpressRatesDocuments = async (countryObj) => {
  const countryRef = firestore.doc(
    `expressRatesDocuments/${countryObj.country}`
  );
  const snapShot = await countryRef.get();
  if (!snapShot.exists) {
    try {
      await countryRef.set({
        ...countryObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await countryRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a country with similar name, please change the country name and try again"
    );
  }
};
export const uploadOffice = async (officeObj) => {
  const officeRef = firestore.doc(`offices/${officeObj.officeId}`);
  const snapShot = await officeRef.get();
  if (!snapShot.exists) {
    try {
      await officeRef.set({
        ...officeObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await officeRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a office with similar name, please change the country name and try again"
    );
  }
};
export const uploadCnf = async (cnfObj) => {
  const cnfRef = firestore.doc(`cnfs/${cnfObj.cnfId}`);
  const snapShot = await cnfRef.get();
  if (!snapShot.exists) {
    try {
      await cnfRef.set({
        ...cnfObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await cnfRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a cnf with similar name, please change the name and try again"
    );
  }
};
export const uploadCustomer = async (cnfObj) => {
  let date = new Date().getTime();
  const cnfRef = firestore.doc(`exchange-customers/${date}`);
  const snapShot = await cnfRef.get();
  if (!snapShot.exists) {
    try {
      await cnfRef.set({
        ...cnfObj,
        cnfId: date,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await cnfRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a customer with similar name, please change the name and try again"
    );
  }
};
export const uploadCnfBill = async (billObj) => {
  let res = await firestore.runTransaction(async (t) => {
    const cnfBillRef = firestore.doc(`cnfBills/${billObj.id}`);
    const cnfBillMonthRef = firestore.doc(`cnfBillMonths/${billObj.month}`);

    const snapShot = await t.get(cnfBillRef);
    const cnfBillMonth = await t.get(cnfBillMonthRef);
    if (!snapShot.exists) {
      try {
        await t.set(cnfBillRef, {
          ...billObj,
        });
        console.log(snapShot.data());
      } catch (error) {
        alert(error);
      }
    } else {
      alert("there is already a bill with similar id, please try again later");
    }
    if (!cnfBillMonth.exists) {
      t.set(cnfBillMonthRef, { month: billObj.month });
    }
  });
  return res;
};
export const uploadEmployee = async (employeeObj) => {
  const employeeRef = firestore.doc(
    `employees-exchange/${employeeObj.employeeId}`
  );
  const snapShot = await employeeRef.get();
  if (!snapShot.exists) {
    try {
      await employeeRef.set({
        ...employeeObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await employeeRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a employee with similar name, please change the name and try again"
    );
  }
};
export const uploadTakaTransfer = async (expenseObj) => {
  const expenseRef = firestore.doc(`dailyTakaTransfer/${expenseObj.id}`);
  const snapShot = await expenseRef.get();
  if (!snapShot.exists) {
    try {
      await expenseRef.set({
        ...expenseObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await expenseRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert("there is already a expense with similar id,try again later");
  }
};
export const uploadForwarder = async (forwarderObj) => {
  const forwarderRef = firestore.doc(`freight-forwarder/${forwarderObj.id}`);
  const snapShot = await forwarderRef.get();
  if (!snapShot.exists) {
    try {
      await forwarderRef.set({
        ...forwarderObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await forwarderRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert("there is already a forwarder with similar id,try again later");
  }
};
export const uploadSourcing = async (sourcingObj) => {
  const sourcingRef = firestore.doc(`sourcing/${sourcingObj.id}`);
  const snapShot = await sourcingRef.get();
  if (!snapShot.exists) {
    try {
      await sourcingRef.set({
        ...sourcingObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await sourcingRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert("there is already a sourcing with similar id,try again later");
  }
};
export const uploadPurchasing = async (purchasingObj) => {
  const purchasingRef = firestore.doc(`purchasing/${purchasingObj.id}`);
  const snapShot = await purchasingRef.get();
  if (!snapShot.exists) {
    try {
      await purchasingRef.set({
        ...purchasingObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await purchasingRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert("there is already a purchasing with similar id,try again later");
  }
};
export const uploadCurrencySell = async (cashInObj) => {
  const cashInRef = firestore.doc(`dailyCurrencySell/${cashInObj.id}`);
  const snapShot = await cashInRef.get();
  if (!snapShot.exists) {
    try {
      await cashInRef.set({
        ...cashInObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await cashInRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert("there is already a cash in with similar id,try again later");
  }
};
export const uploadSpendCash = async (cashInObj) => {
  const cashInRef = firestore.doc(`dailySpendCash/${cashInObj.id}`);
  const snapShot = await cashInRef.get();
  if (!snapShot.exists) {
    try {
      await cashInRef.set({
        ...cashInObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await cashInRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert("there is already a cash in with similar id,try again later");
  }
};
export const uploadExpressRatesParcel = async (countryObj) => {
  const countryRef = firestore.doc(`expressRatesParcel/${countryObj.country}`);
  const snapShot = await countryRef.get();
  if (!snapShot.exists) {
    try {
      await countryRef.set({
        ...countryObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await countryRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a country with similar name, please change the country name and try again"
    );
  }
};
export const uploadD2DRates = async (freightType, country, typeObj) => {
  const productTypeRef = firestore.doc(
    `d2d-rates-${freightType}-${country}/${typeObj.id}`
  );
  const snapShot = await productTypeRef.get();
  if (!snapShot.exists) {
    try {
      await productTypeRef.set({
        ...typeObj,
      });
      console.log(snapShot.data());
      const uploadedSnapShot = await productTypeRef.get();
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a product type with similar name, please change the product type name and try again"
    );
  }
};

export const uploadOrder = async (orderObj) => {
  const orderRef = firestore.doc(`orders/${orderObj.parcelId}`);
  const snapShot = await orderRef.get();
  if (!snapShot.exists) {
    try {
      await orderRef.set({
        ...orderObj,
      });
      const uploadedSnapShot = await orderRef.get();
      console.log(uploadedSnapShot.data());
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already an order with similar parcel Id. please try again later."
    );
  }
};
export const changeLotOrder = async (orderObj, previousParcelId) => {
  const orderRef = firestore.doc(`orders/${orderObj.parcelId}`);
  const snapShot = await orderRef.get();
  if (!snapShot.exists) {
    try {
      await orderRef.set({
        ...orderObj,
      });
      const uploadedSnapShot = await orderRef.get();
      console.log(uploadedSnapShot.data());
      return uploadedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already an order with similar parcel Id. please try again later."
    );
    return null;
  }
};

export const rechargeUser = async (rechargeObj) => {
  // first upload the recharge day in rechargeDays
  console.log(rechargeObj);
  try {
    const rechargeDayRef = firestore.doc(
      `rechargeDays/${rechargeObj.rechargedAt}`
    );
    const snapShot = await rechargeDayRef.get();
    if (!snapShot.exists) {
      try {
        await rechargeDayRef.set({
          date: rechargeObj.rechargedAt,
          day: rechargeObj.day,
          total: rechargeObj.amount,
        });
      } catch (error) {
        alert(error);
      }
    } else {
      try {
        await rechargeDayRef.update({
          total: parseInt(snapShot.data().total) + parseInt(rechargeObj.amount),
        });
      } catch (error) {
        alert(error);
      }
    }

    // update rechargeRequest object to recharged or rejected
    const updatedRechargeRequestObj = await updateRechargeRequestStatus(
      rechargeObj
    );

    // upload full recharge object inside that day of recharge History
    const rechargeRef = firestore.doc(
      `rechargeHistory/${rechargeObj.rechargeId}`
    );
    const recharge = await rechargeRef.get();
    if (!recharge.exists) {
      try {
        await rechargeRef.set({
          ...rechargeObj,
        });
      } catch (error) {
        alert(error);
      }
    } else {
      alert("an error occurred. please try again later");
    }

    // update user object with recharge balance
    const userRef = firestore.doc(`users/${rechargeObj.uid}`);

    try {
      const userSnapShot = await userRef.get();
      console.log(userSnapShot.data());
      await userRef.update({
        myWallet:
          parseInt(userSnapShot.data().myWallet) + parseInt(rechargeObj.amount),

        totalRecharge: userSnapShot.data().totalRecharge
          ? parseInt(userSnapShot.data().totalRecharge) +
            parseInt(rechargeObj.amount)
          : parseInt(rechargeObj.amount),
      });
      const rechargedUserSnapShot = await userRef.get();
      return rechargedUserSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } catch (error) {
    alert(error);
  }
};
export const rechargeUserFromRechargeRequest = async (rechargeObj) => {
  // first upload the recharge day in rechargeDays
  console.log(rechargeObj);
  try {
    const rechargeDayRef = firestore.doc(
      `rechargeDays/${rechargeObj.rechargedAt}`
    );
    const snapShot = await rechargeDayRef.get();
    if (!snapShot.exists) {
      try {
        await rechargeDayRef.set({
          date: rechargeObj.rechargedAt,
          day: rechargeObj.day,
          total: rechargeObj.amount,
        });
      } catch (error) {
        alert(error);
      }
    } else {
      try {
        await rechargeDayRef.update({
          total: parseInt(snapShot.data().total) + parseInt(rechargeObj.amount),
        });
      } catch (error) {
        alert(error);
      }
    }

    // update rechargeRequest object to recharged or rejected
    const updatedRechargeRequestObj = await updateRechargeRequestStatus(
      rechargeObj
    );

    // upload full recharge object inside that day of recharge History
    const rechargeRef = firestore.doc(
      `rechargeHistory/${rechargeObj.rechargeId}`
    );
    const recharge = await rechargeRef.get();
    if (!recharge.exists) {
      try {
        await rechargeRef.set({
          ...rechargeObj,
        });
      } catch (error) {
        alert(error);
      }
    } else {
      alert("an error occurred. please try again later");
    }

    // update user object with recharged balance
    const userRef = firestore.doc(`users/${rechargeObj.uid}`);

    try {
      const userSnapShot = await userRef.get();
      console.log(userSnapShot.data());
      await userRef.update({
        myWallet:
          parseInt(userSnapShot.data().myWallet) + parseInt(rechargeObj.amount),

        totalRecharge: userSnapShot.data().totalRecharge
          ? parseInt(userSnapShot.data().totalRecharge) +
            parseInt(rechargeObj.amount)
          : parseInt(rechargeObj.amount),
      });
      return updatedRechargeRequestObj;
    } catch (error) {
      alert(error);
    }
  } catch (error) {
    alert(error);
  }
};

export const updateOrder = async (orderObj) => {
  const orderRef = firestore.doc(`orders/${orderObj.parcelId}`);
  const order = await orderRef.get();
  try {
    if (order.data().invoiceStatus === "Paid") {
      const updatedSnapShot = await orderRef.get();

      return updatedSnapShot.data();
    } else {
      if (!orderObj.from) {
        orderObj.editApproved = true;
      }
      await orderRef.update({
        ...orderObj,
      });
      const updatedSnapShot = await orderRef.get();

      return updatedSnapShot.data();
    }
  } catch (error) {
    alert(error);
  }
};
export const updateOrderDeliveryStatus = async (orderObj) => {
  const orderRef = firestore.doc(`orders/${orderObj.parcelId}`);
  const order = await orderRef.get();
  try {
    if (!orderObj.from) {
      orderObj.editApproved = true;
    }
    await orderRef.update({
      ...orderObj,
    });
    const updatedSnapShot = await orderRef.get();

    return updatedSnapShot.data();
  } catch (error) {
    alert(error);
  }
};

const getDay = () => {
  const t = new Date();
  const dayInDigit = t.getDay();
  let day;
  if (dayInDigit == 0) {
    day = "Sunday";
  }
  if (dayInDigit == 1) {
    day = "Monday";
  }
  if (dayInDigit == 2) {
    day = "Tuesday";
  }
  if (dayInDigit == 3) {
    day = "Wednesday";
  }
  if (dayInDigit == 4) {
    day = "Thursday";
  }
  if (dayInDigit == 5) {
    day = "Friday";
  }
  if (dayInDigit == 6) {
    day = "Saturday";
  }
  return day;
};

export const getMonthName = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
};

export const makePayment = async (
  total,
  invoicesToPay,
  currentUser,
  admin,
  parcelsArray,
  paymentMethod
) => {
  const res = await firestore.runTransaction(async (t) => {
    //first create a payment object
    const paymentObj = {
      paymentId: Math.floor(Math.random() * Date.now()),
      paidAt: new Date().toLocaleDateString("en-US").replaceAll("/", "-"),
      amount: total,
      paymentMethod,
      paidInvoice: [...invoicesToPay],
      approvedBy: admin.name,
    };

    // for transaction all reads should be done before all writes
    const paymentDayRef = firestore.doc(`paymentDays/${paymentObj.paidAt}`);
    const paymentDay = await t.get(paymentDayRef);

    const paymentHistoryRef = firestore.doc(
      `paymentHistory/${paymentObj.paymentId}`
    );
    const paymentHistory = await t.get(paymentHistoryRef);
    // updatating the status invoiceStatus=Paid in parcelArray in admin
    for (let i = 0; i < parcelsArray.length; i++) {
      const orderRef = firestore.doc(`orders/${parcelsArray[i].parcelId}`);
      let date = new Date();
      let cashInObj = {
        id: `${date.getTime().toString()}${i}`,
        category: paymentMethod,
        subCategory: `Lot No-${parcelsArray[i].lotNo}`,
        month: getMonthName(),
        date: date.toLocaleDateString("en-GB"),
        note: `Carton No-${parcelsArray[i].cartonNo}`,
        amount: parcelsArray[i].finalTotal,
        receiveBy: admin.name || currentUser.displayName,
        status: "pending",
      };
      console.log(cashInObj);
      const cashInRef = firestore.doc(`dailyCashIn/${cashInObj.id}`);

      t.set(cashInRef, {
        ...cashInObj,
      });

      t.update(orderRef, {
        ...parcelsArray[i],
        invoiceStatus: "Paid",
      });
    }

    // make all writes
    // make a payment in paymentdays
    console.log(paymentDay.data());
    const day = getDay();
    if (!paymentDay.exists) {
      t.set(paymentDayRef, {
        date: paymentObj.paidAt,
        total: total,
        day,
      });
    } else {
      t.update(paymentDayRef, {
        total: paymentDay.data().total + total,
      });
    }
    console.log(paymentDay.data());

    // make a payment in paymentHistory

    console.log(paymentHistory.data());
    if (!paymentHistory.exists) {
      t.set(paymentHistoryRef, {
        Email: currentUser
          ? currentUser.email && currentUser.email
          : admin.email,
        Name: currentUser
          ? currentUser.displayName && currentUser.displayName
          : admin.name,
        Id: currentUser ? currentUser.userId : "admin",
        uid: currentUser ? currentUser.uid : admin.adminId,
        Mobile: currentUser
          ? currentUser.mobileNo
            ? currentUser.mobileNo
            : ""
          : admin.mobileNo,
        ...paymentObj,
        day,
      });
    } else {
      alert("Your paymentId already exist. please try again later.");
    }
    console.log(paymentHistory.data());

    const newArray = parcelsArray.map((parcel) => {
      return { ...parcel, invoiceStatus: "Paid" };
    });

    console.log(parcelsArray[0]);
    console.log(newArray[0]);
    return newArray;
  });
  return res;
};
export const makePaymentSourcing = async (
  total,
  invoicesToPay,
  currentUser,
  admin,
  parcelsArray,
  paymentMethod
) => {
  const res = await firestore.runTransaction(async (t) => {
    //first create a payment object
    const paymentObj = {
      paymentId: Math.floor(Math.random() * Date.now()),
      paidAt: new Date().toLocaleDateString("en-US").replaceAll("/", "-"),
      amount: total,
      paymentMethod,
      paidInvoice: [...invoicesToPay],
      approvedBy: admin.name,
    };

    // for transaction all reads should be done before all writes
    const paymentDayRef = firestore.doc(`paymentDays/${paymentObj.paidAt}`);
    const paymentDay = await t.get(paymentDayRef);

    const paymentHistoryRef = firestore.doc(
      `paymentHistory/${paymentObj.paymentId}`
    );
    const paymentHistory = await t.get(paymentHistoryRef);
    // updatating the status invoiceStatus=Paid in parcelArray in admin
    for (let i = 0; i < parcelsArray.length; i++) {
      if (parcelsArray[i].category === "sold-products") {
        const orderRef = firestore.doc(
          `${parcelsArray[i].category}/${parcelsArray[i].paymentId}`
        );
        let date = new Date();
        let cashInObj = {
          id: `${date.getTime().toString()}${i}`,
          category: paymentMethod,
          subCategory: `${parcelsArray[i].category}`,
          month: getMonthName(),
          date: date.toLocaleDateString("en-GB"),
          note: `Invoice No- ${
            parcelsArray[i].id || parcelsArray[i].paymentId
          }`,
          amount:
            parseInt(parcelsArray[i].totalBill) ||
            parseInt(parcelsArray[i].amount) *
              parseInt(parcelsArray[i].quantity) -
              (parseInt(parcelsArray[i].totalPaid) || 0),
          receiveBy: admin.name || currentUser.displayName,
          status: "pending",
        };
        console.log(cashInObj);
        const cashInRef = firestore.doc(`dailyCashIn/${cashInObj.id}`);

        t.set(cashInRef, {
          ...cashInObj,
        });

        t.update(orderRef, {
          ...parcelsArray[i],
          invoiceStatus: "Paid",
        });
      } else {
        const orderRef = firestore.doc(
          `${parcelsArray[i].category}/${parcelsArray[i].id}`
        );
        let date = new Date();
        let cashInObj = {
          id: `${date.getTime().toString()}${i}`,
          category: paymentMethod,
          subCategory: `${parcelsArray[i].category}`,
          month: getMonthName(),
          date: date.toLocaleDateString("en-GB"),
          note: `Invoice No- ${
            parcelsArray[i].id || parcelsArray[i].paymentId
          }`,
          amount:
            parseInt(parcelsArray[i].totalBill) ||
            parseInt(parcelsArray[i].amount) *
              parseInt(parcelsArray[i].quantity) -
              (parseInt(parcelsArray[i].totalPaid) || 0),
          receiveBy: admin.name || currentUser.displayName,
          status: "pending",
        };
        console.log(cashInObj);
        const cashInRef = firestore.doc(`dailyCashIn/${cashInObj.id}`);

        t.set(cashInRef, {
          ...cashInObj,
        });

        t.update(orderRef, {
          ...parcelsArray[i],
          invoiceStatus: "Paid",
        });
      }
    }

    // make all writes
    // make a payment in paymentdays
    console.log(paymentDay.data());
    const day = getDay();
    if (!paymentDay.exists) {
      t.set(paymentDayRef, {
        date: paymentObj.paidAt,
        total: total,
        day,
      });
    } else {
      t.update(paymentDayRef, {
        total: paymentDay.data().total + total,
      });
    }
    console.log(paymentDay.data());

    // make a payment in paymentHistory

    console.log(paymentHistory.data());
    if (!paymentHistory.exists) {
      t.set(paymentHistoryRef, {
        Email: currentUser
          ? currentUser.email && currentUser.email
          : admin.email,
        Name: currentUser
          ? currentUser.displayName && currentUser.displayName
          : admin.name,
        Id: currentUser ? currentUser.userId : "admin",
        uid: currentUser ? currentUser.uid : admin.adminId,
        Mobile: currentUser
          ? currentUser.mobileNo
            ? currentUser.mobileNo
            : ""
          : admin.mobileNo,
        ...paymentObj,
        day,
      });
    } else {
      alert("Your paymentId already exist. please try again later.");
    }
    console.log(paymentHistory.data());

    const newArray = parcelsArray.map((parcel) => {
      return { ...parcel, invoiceStatus: "Paid" };
    });

    console.log(parcelsArray[0]);
    console.log(newArray[0]);
    return newArray;
  });
  return res;
};

export const makePaymentExpress = async (
  total,
  invoicesToPay,
  currentUser,
  admin,
  parcelsArray,
  paymentMethod
) => {
  const res = await firestore.runTransaction(async (t) => {
    //first create a payment object
    const paymentObj = {
      paymentId: Math.floor(Math.random() * Date.now()),
      paidAt: new Date().toLocaleDateString("en-US").replaceAll("/", "-"),
      amount: total,
      paymentMethod,
      paidInvoice: [...invoicesToPay],
      approvedBy: admin.name,
    };

    // for transaction all reads should be done before all writes
    const paymentDayRef = firestore.doc(
      `paymentDaysExpress/${paymentObj.paidAt}`
    );
    const paymentDay = await t.get(paymentDayRef);
    const paymentHistoryRef = firestore.doc(
      `paymentHistoryExpress/${paymentObj.paymentId}`
    );
    const paymentHistory = await t.get(paymentHistoryRef);
    // updatating the status invoiceStatus=Paid in parcelArray in admin
    for (let i = 0; i < parcelsArray.length; i++) {
      let booking = parcelsArray[i];
      const orderRef = firestore.doc(`bookingRequest/${booking.bookingId}`);
      let date = new Date();
      let cashInObj = {
        id: date.getTime().toString(),
        category: paymentMethod,
        subCategory: `Booking Id - ${booking.bookingId}`,
        month: getMonthName(),
        date: date.toLocaleDateString("en-GB"),
        note: `${booking.weight ? `${booking.weight}Kg` : booking.parcelBox}`,
        amount: booking.totalCost || booking.total,
        receiveBy: admin.name || currentUser.displayName,
        status: "pending",
      };
      console.log(cashInObj);
      const cashInRef = firestore.doc(`dailyCashIn/${cashInObj.id}`);

      t.set(cashInRef, {
        ...cashInObj,
      });

      t.update(orderRef, {
        ...booking,
        paid: true,
      });
    }

    // make all writes
    // make a payment in paymentdays
    console.log(paymentDay.data());
    const day = getDay();
    if (!paymentDay.exists) {
      t.set(paymentDayRef, {
        date: paymentObj.paidAt,
        total: total,
        day,
      });
    } else {
      t.update(paymentDayRef, {
        total: paymentDay.data().total + total,
      });
    }
    console.log(paymentDay.data());

    // make a payment in paymentHistory

    console.log(paymentHistory.data());
    if (!paymentHistory.exists) {
      t.set(paymentHistoryRef, {
        Email: currentUser
          ? currentUser.email && currentUser.email
          : admin.email,
        Name: currentUser
          ? currentUser.displayName && currentUser.displayName
          : admin.name,
        Id: currentUser ? currentUser.userId : "admin",
        uid: currentUser ? currentUser.uid : admin.adminId,
        Mobile: currentUser
          ? currentUser.mobileNo
            ? currentUser.mobileNo
            : ""
          : admin.mobileNo,
        ...paymentObj,
        day,
      });
    } else {
      alert("Your paymentId already exist. please try again later.");
    }
    console.log(paymentHistory.data());

    const newArray = parcelsArray.map((parcel) => {
      return { ...parcel, invoiceStatus: "Paid" };
    });

    console.log(parcelsArray[0]);
    console.log(newArray[0]);
    return newArray;
  });
  return res;
};

export const updateOrderInInvoice = async (orderObj) => {
  const orderRef = firestore.doc(`orders/${orderObj.parcelId}`);
  try {
    await orderRef.update({
      ...orderObj,
    });
    const updatedSnapShot = await orderRef.get();
    return updatedSnapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const uploadImageQcCheck = async (file) => {
  const imageRef = storage.ref(`qcCheck/${file.name}`);
  try {
    await imageRef.put(file);
    var imgUrl = [];
    await imageRef.getDownloadURL().then((url) => {
      console.log(url);
      imgUrl.push(url);
    });

    return imgUrl[0];
  } catch (error) {
    return null;
  }
};

export const uploadImageIntro = async (file) => {
  const imageRef = storage.ref(`intro/${file.name}`);
  try {
    await imageRef.put(file);
    var imgUrl = [];
    await imageRef.getDownloadURL().then((url) => {
      console.log(url);
      imgUrl.push(url);
    });

    return imgUrl[0];
  } catch (error) {
    return null;
  }
};

export const updateUserStatus = async (userObj) => {
  const userRef = firestore.doc(`users/${userObj.uid}`);
  try {
    const snapShot = await userRef.get();
    console.log(snapShot.data());
    await userRef.update({
      status: userObj.status,
    });
    const updatedSnapShot = await userRef.get();
    return updatedSnapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const updateAdminStatus = async (adminObj) => {
  const adminRef = firestore.doc(`admins/${adminObj.adminId}`);
  try {
    const snapShot = await adminRef.get();
    console.log(snapShot.data());
    await adminRef.update({
      status: adminObj.status,
    });
    const updatedSnapShot = await adminRef.get();
    return updatedSnapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const uploadProductTax = async (productObj) => {
  const productRef = firestore.doc(`taxes/${productObj.id}`);
  const snapShot = await productRef.get();
  const newProductObj = { ...productObj };
  if (!snapShot.exists) {
    try {
      productRef.set({
        ...newProductObj,
      });
    } catch (error) {
      alert(error);
    }
  } else {
    alert(
      "there is already a product with this given prodcut Id, please change the product Id and upload again"
    );
  }
};
export const uploadProduct = async (productObj) => {
  const productRef = firestore.doc(`stock-products/${productObj.id}`);
  const snapShot = await productRef.get();
  const newProductObj = { ...productObj };
  if (!snapShot.exists) {
    try {
      await productRef.set({
        ...newProductObj,
      });
      const updatedSnapShot = await productRef.get();
      return updatedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  } else {
    try {
      await productRef.update({
        ...newProductObj,
      });
      const updatedSnapShot = await productRef.get();
      return updatedSnapShot.data();
    } catch (error) {
      alert(error);
    }
  }
};

export const getAllProducts = async () => {
  const productsCollectionRef = firestore
    .collection("stock-products")
    .where("status", "==", "stock");
  try {
    const products = await productsCollectionRef.get();
    const productsArray = [];
    products.forEach((doc) => {
      productsArray.push(doc.data());
    });
    return productsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllSoldProducts = async () => {
  const productsCollectionRef = firestore.collection("sold-products");

  try {
    const products = await productsCollectionRef.get();
    const productsArray = [];
    products.forEach((doc) => {
      productsArray.push(doc.data());
    });
    return productsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllSoldProductsMonthly = async (month) => {
  const productsCollectionRef = firestore
    .collection("sold-products")
    .where("month", "==", month);

  try {
    const products = await productsCollectionRef.get();
    const productsArray = [];
    products.forEach((doc) => {
      productsArray.push(doc.data());
    });
    return productsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllLots = async () => {
  const lotsCollectionRef = firestore.collection("lots");

  try {
    const lots = await lotsCollectionRef.orderBy("shipmentDate", "desc").get();
    const lotsArray = [];
    lots.forEach((doc) => {
      lotsArray.push(doc.data());
    });

    return lotsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllDeliveredLots = async () => {
  const lotsCollectionRef = firestore
    .collection("lots")
    .where("allDelivered", "==", true);
  try {
    const lots = await lotsCollectionRef.orderBy("shipmentDate", "desc").get();
    const lotsArray = [];
    lots.forEach((doc) => {
      lotsArray.push(doc.data());
    });
    return lotsArray;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const uploadImage = async (currentAdmin, file) => {
  const imageRef = storage.ref(`users/${file.name}`);
  try {
    await imageRef.put(file);
    var imgUrl = [];
    await imageRef.getDownloadURL().then((url) => {
      console.log(url);
      imgUrl.push(url);
    });
    const adminRef = firestore.doc(`admins/${currentAdmin.adminId}`);
    const snapShot = await adminRef.get();
    console.log(snapShot.data());
    try {
      adminRef.update({
        imageUrl: imgUrl[0],
      });
    } catch (error) {
      alert(error);
    }
    const updatedSnapShot = await adminRef.get();
    return updatedSnapShot.data();
  } catch (error) {
    return null;
  }
};

export const uploadImageRechargeRequest = async (file) => {
  const imageRef = storage.ref(`rechargeRequests/${file.name}`);

  try {
    await imageRef.put(file);
    var imgUrl = [];
    await imageRef.getDownloadURL().then((url) => {
      console.log(url);
      imgUrl.push(url);
    });
    return imgUrl[0];
  } catch (error) {
    return null;
  }
};

export const updateAdmin = async (currentAdmin) => {
  const adminRef = firestore.doc(`admins/${currentAdmin.adminId}`);
  const snapShot = await adminRef.get();
  console.log(snapShot.data());
  try {
    await adminRef.update({
      ...currentAdmin,
    });
    const updatedSnapShot = await adminRef.get();
    return updatedSnapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const getAllNotices = async () => {
  const noticesCollectionRef = firestore.collection("notices");
  try {
    const notices = await noticesCollectionRef.get();
    const noticesArray = [];
    notices.forEach((doc) => {
      noticesArray.push(doc.data());
    });
    return noticesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllBlogs = async () => {
  const blogsCollectionRef = firestore.collection("blogs");
  try {
    const blogs = await blogsCollectionRef.get();
    const blogsArray = [];
    blogs.forEach((doc) => {
      blogsArray.push(doc.data());
    });
    return blogsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllIntros = async () => {
  const introsCollectionRef = firestore.collection("intros");
  try {
    const intros = await introsCollectionRef.get();
    const introsArray = [];
    intros.forEach((doc) => {
      introsArray.push(doc.data());
    });
    return introsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllRechargeDays = async () => {
  const rechargeDaysCollectionRef = firestore.collection("rechargeDays");

  try {
    const rechargeDays = await rechargeDaysCollectionRef
      .orderBy("date", "desc")
      .get();
    const rechargeDaysArray = [];
    rechargeDays.forEach((doc) => {
      rechargeDaysArray.push(doc.data());
    });
    return rechargeDaysArray;
  } catch (error) {
    alert(error);
    return [];
  }
};
export const getAllPaymentDays = async () => {
  const paymentDaysCollectionRef = firestore
    .collection("paymentDays")
    .orderBy("date", "desc");
  try {
    const paymentDays = await paymentDaysCollectionRef.get();
    const paymentDaysArray = [];
    paymentDays.forEach((doc) => {
      paymentDaysArray.push(doc.data());
    });
    return paymentDaysArray.sort((a, b) => new Date(b.date) - new Date(a.date));
  } catch (error) {
    alert(error);
    return [];
  }
};
export const getAllPaymentDaysExpress = async () => {
  const paymentDaysCollectionRef = firestore
    .collection("paymentDaysExpress")
    .orderBy("date", "desc");
  try {
    const paymentDays = await paymentDaysCollectionRef.get();
    const paymentDaysArray = [];
    paymentDays.forEach((doc) => {
      paymentDaysArray.push(doc.data());
    });
    return paymentDaysArray.sort((a, b) => new Date(b.date) - new Date(a.date));
  } catch (error) {
    alert(error);
    return [];
  }
};

export const makeBookingReceived = async (bookingObj) => {
  console.log(bookingObj);

  const bookingMonthRef = firestore.doc(`bookingMonths/${bookingObj.month}`);
  const bookingMonth = await bookingMonthRef.get();

  if (!bookingMonth.exists) {
    bookingMonthRef.set({
      totalOrder: 1,
      deliveredOrder: 0,
      pendingOrder: 1,
      month: bookingObj.month,
    });
  } else {
    bookingMonthRef.update({
      totalOrder: bookingMonth.data().totalOrder + 1,
      pendingOrder: bookingMonth.data().pendingOrder + 1,
    });
  }
};
export const updateExpressMonth = async (month) => {
  const bookingMonthRef = firestore.doc(`bookingMonths/${month}`);
  const bookingMonth = await bookingMonthRef.get();
  console.log(bookingMonth.data());
  bookingMonthRef.update({
    pendingOrder: bookingMonth.data().pendingOrder - 1,
    deliveredOrder: bookingMonth.data().deliveredOrder + 1,
  });
};

export const getAllRechargeRequest = async () => {
  const rechargeRequestCollectionRef = firestore
    .collection("rechargeRequest")
    .where("status", "==", "pending");

  try {
    const rechargeRequest = await rechargeRequestCollectionRef.get();
    const rechargeRequestArray = [];
    rechargeRequest.forEach((doc) => {
      rechargeRequestArray.push(doc.data());
    });
    console.log(rechargeRequestArray);
    return rechargeRequestArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllPaymentRequest = async () => {
  const paymentRequestCollectionRef = firestore
    .collection("paymentRequest")
    .where("status", "==", "pending");

  try {
    const paymentRequest = await paymentRequestCollectionRef.get();
    const paymentRequestArray = [];
    paymentRequest.forEach((doc) => {
      paymentRequestArray.push(doc.data());
    });
    console.log(paymentRequestArray);
    return paymentRequestArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllPaymentRequestSourcings = async () => {
  const paymentRequestCollectionRef = firestore
    .collection("paymentRequestSourcing")
    .where("status", "==", "pending");

  try {
    const paymentRequest = await paymentRequestCollectionRef.get();
    const paymentRequestArray = [];
    paymentRequest.forEach((doc) => {
      paymentRequestArray.push(doc.data());
    });
    console.log(paymentRequestArray);
    return paymentRequestArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllPaymentRequestExpress = async () => {
  const paymentRequestCollectionRef = firestore
    .collection("paymentRequestExpress")
    .where("status", "==", "pending");

  try {
    const paymentRequest = await paymentRequestCollectionRef.get();
    const paymentRequestArray = [];
    paymentRequest.forEach((doc) => {
      paymentRequestArray.push(doc.data());
    });
    console.log(paymentRequestArray);
    return paymentRequestArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllBookings = async (bookingStatus) => {
  console.log(bookingStatus);
  const bookingsCollectionRef = firestore
    .collection("bookingRequest")
    .where("bookingStatus", "==", bookingStatus);

  try {
    const bookings = await bookingsCollectionRef.get();
    const bookingsArray = [];
    bookings.forEach((doc) => {
      bookingsArray.push(doc.data());
    });
    return bookingsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllReceivedExpressBookings = async (month) => {
  if (month) {
    const bookingsCollectionRef = firestore
      .collection("bookingRequest")
      .where("bookingStatus", "==", "Received")
      .where("month", "==", month);

    try {
      const bookings = await bookingsCollectionRef.get();
      const bookingsArray = [];
      bookings.forEach((doc) => {
        bookingsArray.push(doc.data());
      });
      return bookingsArray;
    } catch (error) {
      alert(error);
    }
  } else {
    const bookingsCollectionRef = firestore
      .collection("bookingRequest")
      .where("bookingStatus", "==", "Received");
    try {
      const bookings = await bookingsCollectionRef.get();
      const bookingsArray = [];
      bookings.forEach((doc) => {
        bookingsArray.push(doc.data());
      });
      return bookingsArray;
    } catch (error) {
      alert(error);
    }
  }
};
export const getAllRefundRequest = async (refundStatus) => {
  const refundsCollectionRef = firestore
    .collection("refundRequest")
    .where("refundStatus", "==", refundStatus);
  try {
    const refunds = await refundsCollectionRef.get();
    const refundsArray = [];
    refunds.forEach((doc) => {
      refundsArray.push(doc.data());
    });
    return refundsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllDocumentExpressRates = async () => {
  const expressRatesDocumentsCollectionRef = firestore.collection(
    "expressRatesDocuments"
  );
  try {
    const expressRatesDocuments =
      await expressRatesDocumentsCollectionRef.get();
    const expressRatesDocumentsArray = [];
    expressRatesDocuments.forEach((doc) => {
      expressRatesDocumentsArray.push(doc.data());
    });
    return expressRatesDocumentsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllOffices = async () => {
  const officesCollectionRef = firestore.collection("offices");
  try {
    const offices = await officesCollectionRef.get();
    const officesArray = [];
    offices.forEach((doc) => {
      officesArray.push(doc.data());
    });
    return officesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllCnfs = async () => {
  const cnfsCollectionRef = firestore.collection("cnfs");
  try {
    const cnfs = await cnfsCollectionRef.get();
    const cnfsArray = [];
    cnfs.forEach((doc) => {
      cnfsArray.push(doc.data());
    });
    return cnfsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllCustomers = async () => {
  const cnfsCollectionRef = firestore.collection("exchange-customers");
  try {
    const cnfs = await cnfsCollectionRef.get();
    const cnfsArray = [];
    cnfs.forEach((doc) => {
      cnfsArray.push(doc.data());
    });
    return cnfsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllCustomerCurrencySell = async (name) => {
  console.log(name);
  const cnfsCollectionRef = firestore
    .collection("dailyCurrencySell")
    .where("from", "==", name);
  const cnfsCollectionRef2 = firestore
    .collection("dailyCurrencySell")
    .where("to", "==", name);
  try {
    const cnfs = await cnfsCollectionRef.get();
    const cnfs2 = await cnfsCollectionRef2.get();

    const cnfsArray = [];
    cnfs.forEach((doc) => {
      cnfsArray.push(doc.data());
    });
    cnfs2.forEach((doc) => {
      cnfsArray.push(doc.data());
    });
    return cnfsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllCustomerTakaTransfer = async (cnfId) => {
  console.log(cnfId);
  const expensesCollectionRef = firestore
    .collection("dailyTakaTransfer")
    .where("from", "==", cnfId);
  const expensesCollectionRef2 = firestore
    .collection("dailyTakaTransfer")
    .where("to", "==", cnfId);
  try {
    const expenses = await expensesCollectionRef.get();
    const expenses2 = await expensesCollectionRef2.get();

    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    expenses2.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllCustomerSpendCash = async (cnfId) => {
  console.log(cnfId);
  const expensesCollectionRef = firestore
    .collection("dailySpendCash")
    .where("from", "==", cnfId);
  const expensesCollectionRef2 = firestore
    .collection("dailySpendCash")
    .where("to", "==", cnfId);
  try {
    const expenses = await expensesCollectionRef.get();
    const expenses2 = await expensesCollectionRef2.get();

    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    expenses2.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllEmployees = async () => {
  const employeesCollectionRef = firestore.collection("employees-exchange");
  try {
    const employees = await employeesCollectionRef.get();
    const employeesArray = [];
    employees.forEach((doc) => {
      employeesArray.push(doc.data());
    });
    return employeesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllExpenses = async (day) => {
  const expensesCollectionRef = firestore
    .collection("dailyTakaTransfer")
    .where("date", "==", day);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllSpendCash = async (day) => {
  const expensesCollectionRef = firestore
    .collection("dailySpendCash")
    .where("date", "==", day);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllSalary = async (name) => {
  const expensesCollectionRef = firestore
    .collection("dailySpendCash")
    .where("subCategory", "==", name);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllForwarders = async () => {
  const forwardersCollectionRef = firestore.collection("freight-forwarder");

  try {
    const forwarders = await forwardersCollectionRef.get();
    const forwardersArray = [];
    forwarders.forEach((doc) => {
      forwardersArray.push(doc.data());
    });
    return forwardersArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllSourcings = async (month) => {
  const sourcingsCollectionRef = firestore
    .collection("sourcing")
    .where("month", "==", month);
  try {
    const sourcings = await sourcingsCollectionRef.get();
    const sourcingsArray = [];
    sourcings.forEach((doc) => {
      sourcingsArray.push(doc.data());
    });
    return sourcingsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllSourcingsAgent = async (agent, month) => {
  const sourcingsCollectionRef = firestore
    .collection("sourcing")
    .where("agent", "==", agent)
    .where("month", "==", month);
  try {
    const sourcings = await sourcingsCollectionRef.get();
    const sourcingsArray = [];
    sourcings.forEach((doc) => {
      sourcingsArray.push(doc.data());
    });
    return sourcingsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllPurchasings = async (month) => {
  const purchasingsCollectionRef = firestore
    .collection("purchasing")
    .where("month", "==", month);
  try {
    const purchasings = await purchasingsCollectionRef.get();
    const purchasingsArray = [];
    purchasings.forEach((doc) => {
      purchasingsArray.push(doc.data());
    });
    return purchasingsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllPurchasingsAgent = async (agent, month) => {
  const purchasingsCollectionRef = firestore
    .collection("purchasing")
    .where("agent", "==", agent)
    .where("month", "==", month);
  try {
    const purchasings = await purchasingsCollectionRef.get();
    const purchasingsArray = [];
    purchasings.forEach((doc) => {
      purchasingsArray.push(doc.data());
    });
    return purchasingsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllCashIns = async (day) => {
  const expensesCollectionRef = firestore
    .collection("dailyCurrencySell")
    .where("date", "==", day);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllMonthlyTakaTransfer = async () => {
  const expensesCollectionRef = firestore.collection("monthlyTakaTransfer");
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllMonthlyCashSummary = async () => {
  const expensesCollectionRef = firestore.collection("cashSummaryExchange");
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllMonthlyCurrencySell = async () => {
  const expensesCollectionRef = firestore.collection("monthlyCurrencySell");
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllMonthlySpendCash = async () => {
  const expensesCollectionRef = firestore.collection("monthlySpendCash");
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllMonthly = async (category, subCategory) => {
  const expensesCollectionRef = firestore
    .collection(`categoryMonthlyExpense-exchange`)
    .where("category", "==", category)
    .where("subCategory", "==", subCategory);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllFunds = async () => {
  const expensesCollectionRef = firestore
    .collection(`dailyExpenses-exchange`)
    .where("category", "==", "FUND");

  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllMonthsCnfBill = async (month) => {
  const monthRef = firestore.doc(`cnfBillMonths/${month}`);
  const monthdata = await monthRef.get();

  if (!monthdata.exists) {
    await monthRef.set({
      month: month,
    });
  }

  const monthsCollectionRef = firestore.collection(`cnfBillMonths`);
  try {
    const expenses = await monthsCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllMonthlySalary = async () => {
  const expensesCollectionRef = firestore.collection(`monthlySalary`);

  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getSingleMonthlyTakaTransfer = async (month) => {
  const expensesCollectionRef = firestore
    .collection("dailyTakaTransfer")
    .where("month", "==", month);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getSingleMonthlySpendCash = async (month) => {
  const expensesCollectionRef = firestore
    .collection("dailySpendCash")
    .where("month", "==", month);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getSingleMonthlyCashSummary = async (month) => {
  const expensesCollectionRef = firestore
    .collection("cashSummaryExchange")
    .where("month", "==", month);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getSingleCashSummary = async () => {
  const date = new Date();
  const cashSummaryRef = firestore.doc(
    `cashSummaryExchange/${date
      .toLocaleDateString("en-GB")
      .replaceAll("/", "-")}`
  );
  const cashSummary = await cashSummaryRef.get();
  if (cashSummary.exists) {
    return cashSummary.data();
  } else {
    await cashSummaryRef.set({
      month: getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      totalCurrencySell: 0,
      totalTakaTransfers: 0,
      totalSpendCash: 0,
    });
    const updatedSnapShot = await cashSummaryRef.get();
    return updatedSnapShot.data();
  }
};
export const createMonth = async () => {
  const date = new Date();
  const monthRef = firestore.doc(`months/${getMonthName()}`);
  const month = await monthRef.get();
  if (month.exists) {
    let monthsArray = [];
    const monthsCollectionRef = firestore.collection("months");
    const monthsCollection = await monthsCollectionRef.get();
    monthsCollection.forEach((doc) => {
      monthsArray.push(doc.data());
    });
    return monthsArray;
  } else {
    await monthRef.set({
      month: getMonthName(),
    });
    let monthsArray = [];
    const monthsCollectionRef = firestore.collection("months");
    const monthsCollection = await monthsCollectionRef.get();
    monthsCollection.forEach((doc) => {
      monthsArray.push(doc.data());
    });
    return monthsArray;
  }
};
export const getSingleMonthlyCurrencySell = async (month) => {
  const expensesCollectionRef = firestore
    .collection("dailyCurrencySell")
    .where("month", "==", month);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getSingleMonthly = async (month, category, subCategory) => {
  const expensesCollectionRef = firestore
    .collection("dailyExpenses-exchange")
    .where("month", "==", month)
    .where("category", "==", category)
    .where("subCategory", "==", subCategory);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getSingleMonthlySalary = async (month, category) => {
  const expensesCollectionRef = firestore
    .collection("dailyExpenses-exchange")
    .where("month", "==", month)
    .where("category", "==", category);

  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};

export const getSingleMonthlyLoanCashIn = async (month, category) => {
  const cashInsCollectionRef = firestore
    .collection("dailyCashIn")
    .where("month", "==", month)
    .where("category", "==", category);

  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};
export const getSingleMonthlyExpense = async (month, name) => {
  let cashInsCollectionRef;
  if (name == "all") {
    cashInsCollectionRef = firestore
      .collection("dailySpendCash")
      .where("month", "==", month);
  } else if (name == "salary") {
    cashInsCollectionRef = firestore
      .collection("dailySpendCash")
      .where("category", "==", "SALARY");
  } else if (name == "other-cost") {
    cashInsCollectionRef = firestore
      .collection("dailySpendCash")
      .where("category", "==", "DAILY COST");
  } else if (name == "office-cost") {
    cashInsCollectionRef = firestore
      .collection("dailySpendCash")
      .where("category", "==", "OFFICE");
  }
  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};

export const getSingleMonthlyLoanCashOut = async (month, category) => {
  const expensesCollectionRef = firestore
    .collection("dailyExpenses-exchange")
    .where("month", "==", month)
    .where("category", "==", category);

  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllPendingTakaTransferByDay = async (day) => {
  const expensesCollectionRef = firestore
    .collection("dailyTakaTransfer")
    .where("date", "==", day)
    .where("status", "==", "pending");
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllPendingCurrencySellByDay = async (day) => {
  const cashInsCollectionRef = firestore
    .collection("dailyCurrencySell")
    .where("date", "==", day)
    .where("status", "==", "pending");
  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllPendingSpendCashByDay = async (day) => {
  const cashInsCollectionRef = firestore
    .collection("dailySpendCash")
    .where("date", "==", day)
    .where("status", "==", "pending");
  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllPendingExpenses = async () => {
  const expensesCollectionRef = firestore
    .collection("dailyExpenses-exchange")
    .where("status", "==", "pending");
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllPendingTakaTransfer = async () => {
  const expensesCollectionRef = firestore
    .collection("dailyTakaTransfer")
    .where("status", "==", "pending");
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllLoansCashOuts = async () => {
  const expensesCollectionRef = firestore
    .collection("dailyExpenses-exchange")
    .where("category", "==", "LOAN");
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllLoansCashOutCustomer = async (customer) => {
  const expensesCollectionRef = firestore
    .collection("dailyExpenses-exchange")
    .where("category", "==", "LOAN")
    .where("uid", "==", customer);
  try {
    const expenses = await expensesCollectionRef.get();
    const expensesArray = [];
    expenses.forEach((doc) => {
      expensesArray.push(doc.data());
    });
    return expensesArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllPendingCashIns = async () => {
  const cashInsCollectionRef = firestore
    .collection("dailyCashIn")
    .where("status", "==", "pending");
  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllPendingCurrencySell = async () => {
  const cashInsCollectionRef = firestore
    .collection("dailyCurrencySell")
    .where("status", "==", "pending");
  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllPendingSpendCash = async () => {
  const cashInsCollectionRef = firestore
    .collection("dailySpendCash")
    .where("status", "==", "pending");
  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllLoansCashIns = async () => {
  const cashInsCollectionRef = firestore
    .collection("dailyCashIn")
    .where("category", "==", "LOAN");
  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllLoansCashInCustomer = async (customer) => {
  const cashInsCollectionRef = firestore
    .collection("dailyCashIn")
    .where("category", "==", "LOAN")
    .where("uid", "==", customer);
  try {
    const cashIns = await cashInsCollectionRef.get();
    const cashInsArray = [];
    cashIns.forEach((doc) => {
      cashInsArray.push(doc.data());
    });
    return cashInsArray;
  } catch (error) {
    alert(error);
  }
};

export const updateCashSummaryTakaTransfer = async (
  cashOutMonth,
  date,
  total
) => {
  // get the last day remaining balance and update today's cash
  const cashSummaryRef = firestore.doc(
    `cashSummaryExchange/${date.replaceAll("/", "-")}`
  );
  const cashSummary = await cashSummaryRef.get();
  if (cashSummary.exists) {
    cashSummaryRef.update({
      totalTakaTransfers:
        parseInt(cashSummary.data().totalTakaTransfers) + parseInt(total),
    });
  } else {
    cashSummaryRef.set({
      month: cashOutMonth,
      date: date,
      totalCurrencySell: 0,
      totalTakaTransfers: total,
      totalSpendCash: 0,
    });
  }
};
export const updateCashSummarySpendCash = async (cashOutMonth, date, total) => {
  // get the last day remaining balance and update today's cash
  const cashSummaryRef = firestore.doc(
    `cashSummaryExchange/${date.replaceAll("/", "-")}`
  );
  const cashSummary = await cashSummaryRef.get();
  if (cashSummary.exists) {
    cashSummaryRef.update({
      totalSpendCash:
        parseInt(cashSummary.data().totalSpendCash) + parseInt(total),
    });
  } else {
    cashSummaryRef.set({
      month: cashOutMonth,
      date: date,
      totalCurrencySell: 0,
      totalTakaTransfers: 0,
      totalSpendCash: total,
    });
  }
};
export const updateCashSummaryCurrencySell = async (
  cashOutMonth,
  date,
  total
) => {
  // get the last day remaining balance and update today's cash
  const cashSummaryRef = firestore.doc(
    `cashSummaryExchange/${date.replaceAll("/", "-")}`
  );
  const cashSummary = await cashSummaryRef.get();
  if (cashSummary.exists) {
    cashSummaryRef.update({
      totalCurrencySell:
        parseInt(cashSummary.data().totalCurrencySell) + parseInt(total),
    });
  } else {
    cashSummaryRef.set({
      month: cashOutMonth,
      date: date,
      totalCurrencySell: total,
      totalTakaTransfers: 0,
      totalSpendCash: 0,
    });
  }
};

export const approveTakaTransfer = async (expense) => {
  // first update expense status from pending to approved

  await firestore.runTransaction(async (t) => {
    console.log(expense);
    const expenseRef = firestore.doc(`dailyTakaTransfer/${expense.id}`);
    const snapShot = await t.get(expenseRef);
    // transaction needs all reads to be executed before all writes
    const monthlyExpenseRef = firestore.doc(
      `monthlyTakaTransfer/${snapShot.data().month}`
    );
    console.log(expense.from);
    console.log(expense.to);

    const customerFromRef = firestore.doc(`exchange-customers/${expense.from}`);
    const customerToRef = firestore.doc(`exchange-customers/${expense.to}`);
    const customerFrom = await t.get(customerFromRef);
    console.log(customerFrom.data());
    const customerTo = await t.get(customerToRef);
    console.log(customerTo.data());
    const monthlyExpense = await t.get(monthlyExpenseRef);
    // writes and update starts here
    t.update(expenseRef, { status: "approved" });
    // montly expense update
    if (monthlyExpense.exists) {
      console.log(monthlyExpense.data().amount);
      console.log(snapShot.data().amount);
      t.update(monthlyExpenseRef, {
        amount:
          parseInt(monthlyExpense.data().amount) +
          parseInt(snapShot.data().amount),
      });
    } else {
      t.set(monthlyExpenseRef, {
        id: `${snapShot.data().month}`,
        amount: parseInt(snapShot.data().amount),
      });
    }

    t.update(customerFromRef, {
      due: parseInt(customerFrom.data().due) + parseInt(snapShot.data().amount),
    });
    t.update(customerToRef, {
      due: parseInt(customerTo.data().due) - parseInt(snapShot.data().amount),
    });
  });
};

export const approveCurrencySell = async (expense) => {
  // first update expense status from pending to approved
  const type = expense.type;
  await firestore.runTransaction(async (t) => {
    console.log(expense);
    const expenseRef = firestore.doc(`dailyCurrencySell/${expense.id}`);

    const snapShot = await t.get(expenseRef);
    // transaction needs all reads to be executed before all writes

    const monthlyExpenseRef = firestore.doc(
      `monthlyCurrencySell/${snapShot.data().month}`
    );

    const customerFromRef = firestore.doc(`exchange-customers/${expense.from}`);
    const customerToRef = firestore.doc(`exchange-customers/${expense.to}`);
    const customerFrom = await t.get(customerFromRef);
    const customerTo = await t.get(customerToRef);
    const monthlyExpense = await t.get(monthlyExpenseRef);
    // writes and update starts here
    t.update(expenseRef, { status: "approved" });
    // montly expense update
    if (monthlyExpense.exists) {
      t.update(monthlyExpenseRef, {
        amount: parseInt(
          monthlyExpense.data().amount +
            (type == "rupee"
              ? parseInt(
                  snapShot.data().amount * (100 / snapShot.data().sellRate)
                )
              : parseInt(snapShot.data().amount * snapShot.data().sellRate))
        ),
      });
    } else {
      t.set(monthlyExpenseRef, {
        id: `${snapShot.data().month}`,
        amount:
          type == "rupee"
            ? parseInt(
                snapShot.data().amount * (100 / snapShot.data().sellRate)
              )
            : parseInt(snapShot.data().amount * snapShot.data().sellRate),
      });
    }
    t.update(customerFromRef, {
      due:
        parseInt(customerFrom.data().due) +
        (type == "rupee"
          ? parseInt(snapShot.data().amount * (100 / snapShot.data().buyRate))
          : parseInt(snapShot.data().amount * snapShot.data().buyRate)),
    });
    t.update(customerToRef, {
      due:
        parseInt(customerTo.data().due) -
        (type == "rupee"
          ? parseInt(snapShot.data().amount * (100 / snapShot.data().sellRate))
          : parseInt(snapShot.data().amount * snapShot.data().sellRate)),
    });
  });
};
export const approveSpendCash = async (expense) => {
  // first update expense status from pending to approved
  await firestore.runTransaction(async (t) => {
    console.log(expense);
    const expenseRef = firestore.doc(`dailySpendCash/${expense.id}`);

    const snapShot = await t.get(expenseRef);
    // transaction needs all reads to be executed before all writes

    const monthlyExpenseRef = firestore.doc(
      `monthlySpendCash/${snapShot.data().month}`
    );
    const monthlyExpenseByCategoryRef = firestore.doc(
      `monthlySpendCash${expense.category}/${snapShot.data().month}`
    );

    const customerFromRef = firestore.doc(`exchange-customers/${expense.from}`);

    const customerFrom = await t.get(customerFromRef);

    const monthlyExpense = await t.get(monthlyExpenseRef);
    const monthlyExpenseByCategory = await t.get(monthlyExpenseByCategoryRef);
    // writes and update starts here
    t.update(expenseRef, { status: "approved" });
    // montly expense update
    if (monthlyExpense.exists) {
      t.update(monthlyExpenseRef, {
        amount: parseInt(
          monthlyExpense.data().amount + parseInt(snapShot.data().amount)
        ),
      });
    } else {
      t.set(monthlyExpenseRef, {
        id: `${snapShot.data().month}`,
        amount: parseInt(snapShot.data().amount),
      });
    }
    if (monthlyExpenseByCategory.exists) {
      t.update(monthlyExpenseByCategoryRef, {
        amount: parseInt(
          monthlyExpenseByCategory.data().amount +
            parseInt(snapShot.data().amount)
        ),
      });
    } else {
      t.set(monthlyExpenseByCategoryRef, {
        id: `${snapShot.data().month}`,
        amount: parseInt(snapShot.data().amount),
      });
    }
    t.update(customerFromRef, {
      due: parseInt(customerFrom.data().due) + parseInt(snapShot.data().amount),
    });
  });
};

export const getAllExpressRatesParcel = async () => {
  const expressRatesParcelCollectionRef =
    firestore.collection("expressRatesParcel");
  try {
    const expressRatesParcel = await expressRatesParcelCollectionRef.get();
    const expressRatesParcelArray = [];
    expressRatesParcel.forEach((doc) => {
      expressRatesParcelArray.push(doc.data());
    });
    return expressRatesParcelArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllD2DRates = async (freightType, country) => {
  const d2dRatesCollectionRef = firestore.collection(
    `d2d-rates-${freightType}-${country}`
  );
  try {
    const d2dRates = await d2dRatesCollectionRef.get();
    const d2dRatesArray = [];
    d2dRates.forEach((doc) => {
      d2dRatesArray.push(doc.data());
    });
    return d2dRatesArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllExpressOrders = async () => {
  const expressOrdersCollectionRef = firestore.collection("bookingMonths");
  try {
    const ordersExpress = await expressOrdersCollectionRef.get();
    const ordersExpressArray = [];
    ordersExpress.forEach((doc) => {
      ordersExpressArray.push(doc.data());
    });
    return ordersExpressArray;
  } catch (error) {
    alert(error);
  }
};
export const updateExpressOrder = async (orderId) => {
  const expressOrdersCollectionRef = firestore.collection("ordersExpress");
  try {
    const ordersExpress = await expressOrdersCollectionRef.get();
    const ordersExpressArray = [];
    ordersExpress.forEach((doc) => {
      if (doc.data().id === orderId) {
      }
    });
    return ordersExpressArray;
  } catch (error) {
    alert(error);
  }
};

export const updateRechargeRequestStatus = async (rechargeRequestObj) => {
  console.log(rechargeRequestObj);
  const rechargeRequestRef = firestore.doc(
    `rechargeRequest/${rechargeRequestObj.rechargeId}`
  );
  const snapshot = await rechargeRequestRef.get();
  if (snapshot.exists) {
    try {
      await rechargeRequestRef.update({
        status: rechargeRequestObj.status,
      });
      const updatedRechargeRequestObj = await rechargeRequestRef.get();
      return updatedRechargeRequestObj.data();
    } catch (error) {
      alert(error);
    }
  } else {
    return null;
  }
};

export const updatePaymentRequestStatus = async (paymentRequestObj) => {
  console.log(paymentRequestObj);
  const paymentRequestRef = firestore.doc(
    `paymentRequest/${paymentRequestObj.paymentId}`
  );
  const snapshot = await paymentRequestRef.get();
  if (snapshot.exists) {
    try {
      paymentRequestObj.paidInvoices.forEach(async (invoiceId) => {
        const orderRef = firestore.doc(`orders/${invoiceId}`);
        await orderRef.update({
          paymentRequest: paymentRequestObj.status,
        });
      });
      await paymentRequestRef.update({
        status: paymentRequestObj.status,
      });
      const updatedPaymentRequestObj = await paymentRequestRef.get();
      return updatedPaymentRequestObj.data();
    } catch (error) {
      alert(error);
    }
  } else {
    return null;
  }
};
export const updatePaymentRequestSourcingStatus = async (paymentRequestObj) => {
  console.log(paymentRequestObj);
  const paymentRequestRef = firestore.doc(
    `paymentRequestSourcing/${paymentRequestObj.paymentId}`
  );
  const snapshot = await paymentRequestRef.get();
  if (snapshot.exists) {
    try {
      paymentRequestObj.paidInvoices.forEach(async (invoice) => {
        if (invoice.category === "sold-products") {
          const orderRef = firestore.doc(
            `${invoice.category}/${invoice.paymentId}`
          );
          await orderRef.update({
            paymentRequest: paymentRequestObj.status,
          });
        } else {
          const orderRef = firestore.doc(`${invoice.category}/${invoice.id}`);
          await orderRef.update({
            paymentRequest: paymentRequestObj.status,
          });
        }
      });
      await paymentRequestRef.update({
        status: paymentRequestObj.status,
      });
      const updatedPaymentRequestObj = await paymentRequestRef.get();
      return updatedPaymentRequestObj.data();
    } catch (error) {
      alert(error);
    }
  } else {
    return null;
  }
};
export const updatePaymentRequestStatusExpress = async (paymentRequestObj) => {
  console.log(paymentRequestObj);
  const paymentRequestRef = firestore.doc(
    `paymentRequestExpress/${paymentRequestObj.paymentId}`
  );
  const snapshot = await paymentRequestRef.get();
  if (snapshot.exists) {
    try {
      paymentRequestObj.paidInvoices.forEach(async (bookingId) => {
        const orderRef = firestore.doc(`bookingRequest/${bookingId}`);
        await orderRef.update({
          paymentRequest: paymentRequestObj.status,
        });
      });
      await paymentRequestRef.update({
        status: paymentRequestObj.status,
      });
      const updatedPaymentRequestObj = await paymentRequestRef.get();
      return updatedPaymentRequestObj.data();
    } catch (error) {
      alert(error);
    }
  } else {
    return null;
  }
};

export const getAllOrdersOfSingleLot = async (lotObj) => {
  const ordersCollectionRef = firestore
    .collection(`orders`)
    .where("lotNo", "==", lotObj.lotNo);
  try {
    const collection = await ordersCollectionRef.get();
    let ordersArray = [];
    collection.forEach((doc) => {
      ordersArray.push(doc.data());
    });
    return ordersArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllInvoiceDue = async () => {
  const ordersCollectionRef = firestore
    .collection(`orders`)
    .where("invoiceStatus", "!=", "Paid")
    .where("invoiceGenerated", "==", true);

  const collection = await ordersCollectionRef.get();
  let ordersArray = [];
  collection.forEach((doc) => {
    ordersArray.push(doc.data());
  });
  return ordersArray;
};

export const getMultipleOrders = async (parcelIdArray) => {
  const ordersArray = [];
  for (let i = 0; i < parcelIdArray.length; i++) {
    const orderRef = firestore.doc(`orders/${parcelIdArray[i]}`);
    const snapShot = await orderRef.get();
    ordersArray.push(snapShot.data());
  }

  return ordersArray;
};

// if anytime doesn't work should do the same as getMultipleOrder . change the forEach to primary for loop
export const getMultipleOrdersExpress = async (bookingIdArray) => {
  const ordersArray = [];
  await bookingIdArray.forEach(async (bookingId) => {
    const orderRef = firestore.doc(`bookingRequest/${bookingId}`);
    const snapShot = await orderRef.get();
    ordersArray.push(snapShot.data());
  });
  return ordersArray;
};
export const getMultipleSourcings = async (parcelsArray) => {
  const ordersArray = [];
  for (let i = 0; i < parcelsArray.length; i++) {
    if (parcelsArray[i].category == "sold-products") {
      const orderRef = firestore.doc(
        `${parcelsArray[i].category}/${parcelsArray[i].paymentId}`
      );
      const snapShot = await orderRef.get();
      ordersArray.push(snapShot.data());
    } else {
      const orderRef = firestore.doc(
        `${parcelsArray[i].category}/${parcelsArray[i].id}`
      );
      const snapShot = await orderRef.get();
      ordersArray.push(snapShot.data());
    }
  }

  return ordersArray;
};
export const getAllRechargesOfSingleDate = async (date) => {
  const rechargesCollectionRef = firestore
    .collection(`rechargeHistory`)
    .where("rechargedAt", "==", date);
  try {
    const recharges = await rechargesCollectionRef.get();
    let rechargesArray = [];
    recharges.forEach((doc) => rechargesArray.push(doc.data()));
    return rechargesArray;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getAllPaymentsOfSingleDate = async (date) => {
  const paymentsCollectionRef = firestore
    .collection(`paymentHistory`)
    .where("paidAt", "==", date);
  try {
    let paymentsArray = [];
    const payments = await paymentsCollectionRef.get();
    payments.forEach((payment) => {
      paymentsArray.push(payment.data());
    });
    return paymentsArray;
  } catch (error) {
    alert(error);
    return [];
  }
};
export const getAllPaymentsOfSingleDateExpress = async (date) => {
  const paymentsCollectionRef = firestore
    .collection(`paymentHistoryExpress`)
    .where("paidAt", "==", date);
  try {
    let paymentsArray = [];
    const payments = await paymentsCollectionRef.get();
    payments.forEach((payment) => {
      paymentsArray.push(payment.data());
    });
    return paymentsArray;
  } catch (error) {
    alert(error);
    return [];
  }
};

export const getAllOrdersInvoiceRateSingleLot = async (lotObj) => {
  // try {
  const ordersCollectionRef = firestore
    .collection("orders")
    .where("lotNo", "==", lotObj.lotNo);
  // .where("invoiceStatus", "==", "Paid");

  const orders = await ordersCollectionRef.get();
  let ordersArray = [];
  orders.forEach((snapShot) => {
    ordersArray.push(snapShot.data());
  });
  let totalRevenue = 0;
  let totalWeight = 0;
  let totalPaidFor = 0;
  let totalMoneyToPay = 0;

  console.log(ordersArray);
  ordersArray.forEach((order) => {
    totalWeight = totalWeight + parseFloat(order.grossWeight);
    return totalWeight;
  });
  console.log(totalWeight);

  const totalGeneratedInvoices = ordersArray.filter(
    (order) => order.finalTotal
  );
  console.log(totalGeneratedInvoices);
  if (ordersArray.length !== totalGeneratedInvoices.length) {
    totalMoneyToPay = 0;
  } else {
    ordersArray.forEach((order) => {
      console.log(order.finalTotal);
      totalMoneyToPay = totalMoneyToPay + order.finalTotal;
      console.log(totalMoneyToPay);
      return totalMoneyToPay;
    });
  }
  console.log(totalMoneyToPay);

  ordersArray
    .filter((order) => order.invoiceStatus == "Paid")
    .forEach((order) => {
      totalRevenue = totalRevenue + order.finalTotal;
      return totalRevenue;
    });
  console.log(totalRevenue);
  ordersArray
    .filter((order) => order.invoiceStatus == "Paid")
    .forEach((order) => {
      totalPaidFor = totalPaidFor + parseFloat(order.grossWeight);
      return totalPaidFor;
    });
  console.log(totalPaidFor);

  console.log(totalRevenue);
  console.log(totalWeight);
  console.log(totalPaidFor);
  return { totalWeight, totalPaidFor, totalRevenue, totalMoneyToPay };
  // } catch (error) {
  //   return { totalWeight: null, totalPaidFor: null, totalRevenue: null };
  // }
};

export const getAllProductsTax = async () => {
  const productsCollectionRef = firestore.collection("taxes");
  try {
    const products = await productsCollectionRef.get();
    const productsArray = [];
    products.forEach((doc) => {
      productsArray.push(doc.data());
    });
    return productsArray;
  } catch (error) {
    alert(error);
  }
};

export const updateProduct = async (productObj) => {
  const productRef = firestore.doc(`stock-products/${productObj.id}`);
  const product = await productRef.get();
  try {
    delete productObj.file;
    await productRef.update({ ...productObj });
    const updatedSnapShot = await productRef.get();
    return updatedSnapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const handleProductPay = async (productObj) => {
  await firestore.runTransaction(async (t) => {
    const productRef = firestore.doc(`stock-products/${productObj.id}`);
    const soldProductRef = firestore.doc(
      `sold-products/${productObj.paymentId}`
    );
    const cashInRef = firestore.doc(`dailyCashIn/${productObj.paymentId}`);

    const product = await t.get(productRef);
    const soldProduct = await t.get(soldProductRef);
    const snapShot = await t.get(cashInRef);
    if (!snapShot.exists) {
      try {
        await cashInRef.set({
          amount: parseInt(productObj.quantity) * parseInt(productObj.amount),
          category: "Stock Product Sell",
          subCategory: `${productObj.id}-${productObj.name}`,
          note: `${productObj.quantity}pc`,
          date: productObj.date,
          id: productObj.paymentId,
          month: productObj.month,
          receiveBy: productObj.receiveBy,
          status: "pending",
        });
      } catch (error) {
        alert(error);
      }
    }
    let newQuantity =
      parseInt(product.data().quantity) - parseInt(productObj.quantity);
    if (newQuantity <= 0) {
      newQuantity = 0;
    }
    await t.update(productRef, {
      quantity: newQuantity,
    });
    if (soldProduct.exists) {
      await t.update(soldProductRef, {
        ...productObj,
      });
    } else {
      await t.set(soldProductRef, {
        ...productObj,
      });
    }
  });
  const productRef = firestore.doc(`stock-products/${productObj.id}`);
  const snapShot = await productRef.get();
  return snapShot.data();
};

export const handleProductPayGenerate = async (productObj) => {
  await firestore.runTransaction(async (t) => {
    const productRef = firestore.doc(`stock-products/${productObj.id}`);
    const soldProductRef = firestore.doc(
      `sold-products/${productObj.paymentId}`
    );
    const product = await t.get(productRef);
    const soldProduct = await t.get(soldProductRef);
    let newQuantity =
      parseInt(product.data().quantity) - parseInt(productObj.quantity);
    if (newQuantity <= 0) {
      newQuantity = 0;
    }
    await t.update(productRef, {
      quantity: newQuantity,
    });

    await t.set(soldProductRef, {
      ...productObj,
    });
  });
  const productRef = firestore.doc(`stock-products/${productObj.id}`);
  const snapShot = await productRef.get();
  return snapShot.data();
};

export const handleSourcingCashIn = async (sourcingObj) => {
  await firestore.runTransaction(async (t) => {
    const sourcingRef = firestore.doc(`sourcing/${sourcingObj.id}`);

    const cashInRef = firestore.doc(`dailyCashIn/${sourcingObj.paymentId}`);

    const sourcing = await t.get(sourcingRef);

    const snapShot = await t.get(cashInRef);
    if (!snapShot.exists) {
      try {
        await cashInRef.set({
          amount: parseInt(sourcingObj.totalPaid),
          category: "Sourcing",
          subCategory: `${sourcingObj.agent}`,
          note: `Invoice:${sourcingObj.id}`,
          date: sourcingObj.date,
          id: sourcingObj.paymentId,
          month: sourcingObj.month,
          receiveBy: sourcingObj.receiveBy,
          status: "pending",
        });
      } catch (error) {
        alert(error);
      }
    }

    await t.update(sourcingRef, {
      totalPaid:
        parseInt(sourcing.data().totalPaid) + parseInt(sourcingObj.totalPaid),
      status: "approved",
      initialProfit:
        parseInt(sourcing.data().totalPaid) +
        parseInt(sourcingObj.totalPaid) -
        parseInt(sourcing.data().buyingCost),
      invoiceStatus:
        parseInt(sourcing.data().totalPaid) + parseInt(sourcingObj.totalPaid) >=
        sourcing.data().totalBill
          ? "Paid"
          : "Not Paid",
    });
  });
  const sourcingRef = firestore.doc(`sourcing/${sourcingObj.id}`);
  const snapShot = await sourcingRef.get();
  return snapShot.data();
};
export const handlePurchasingCashIn = async (purchasingObj) => {
  await firestore.runTransaction(async (t) => {
    const purchasingRef = firestore.doc(`purchasing/${purchasingObj.id}`);

    const cashInRef = firestore.doc(`dailyCashIn/${purchasingObj.paymentId}`);

    const purchasing = await t.get(purchasingRef);

    const snapShot = await t.get(cashInRef);
    if (!snapShot.exists) {
      try {
        await cashInRef.set({
          amount: parseInt(purchasingObj.totalPaid),
          category: "Purchasing",
          subCategory: `${purchasingObj.agent}`,
          note: `Invoice:${purchasingObj.id}`,
          date: purchasingObj.date,
          id: purchasingObj.paymentId,
          month: purchasingObj.month,
          receiveBy: purchasingObj.receiveBy,
          status: "pending",
        });
      } catch (error) {
        alert(error);
      }
    }

    await t.update(purchasingRef, {
      totalPaid:
        parseInt(purchasing.data().totalPaid) +
        parseInt(purchasingObj.totalPaid),
      status: "approved",
      finalProfit:
        parseInt(purchasing.data().totalPaid) +
        parseInt(purchasingObj.totalPaid) -
        parseInt(purchasing.data().buyingCost),
      invoiceStatus:
        parseInt(purchasing.data().totalPaid) +
          parseInt(purchasingObj.totalPaid) >=
        purchasing.data().totalBill
          ? "Paid"
          : "Not Paid",
    });
  });
  const purchasingRef = firestore.doc(`purchasing/${purchasingObj.id}`);
  const snapShot = await purchasingRef.get();
  return snapShot.data();
};
export const handleSourcingCashOut = async (sourcingObj) => {
  await firestore.runTransaction(async (t) => {
    const sourcingRef = firestore.doc(`sourcing/${sourcingObj.id}`);

    const expenseRef = firestore.doc(`dailyExpenses/${sourcingObj.paymentId}`);

    const sourcing = await t.get(sourcingRef);

    const snapShot = await t.get(expenseRef);
    if (!snapShot.exists) {
      try {
        await expenseRef.set({
          amount: parseInt(sourcingObj.buyingCost),
          category: "Sourcing",
          subCategory: `${sourcingObj.agent}(buying cost)`,
          note: `Invoice:${sourcingObj.id}`,
          date: sourcingObj.date,
          id: sourcingObj.paymentId,
          month: sourcingObj.month,
          receiveBy: sourcingObj.receiveBy,
          status: "pending",
        });
      } catch (error) {
        alert(error);
      }
    }

    await t.update(sourcingRef, {
      buyingCost:
        parseInt(sourcing.data().buyingCost) + parseInt(sourcingObj.buyingCost),
      status: "approved",
      initialProfit:
        parseInt(sourcing.data().totalPaid) -
        (parseInt(sourcing.data().buyingCost) +
          parseInt(sourcingObj.buyingCost)),
    });
  });
  const sourcingRef = firestore.doc(`sourcing/${sourcingObj.id}`);
  const snapShot = await sourcingRef.get();
  return snapShot.data();
};
export const handleSourcingAgentPay = async (sourcingObj) => {
  await firestore.runTransaction(async (t) => {
    const sourcingRef = firestore.doc(`sourcing/${sourcingObj.id}`);

    const expenseRef = firestore.doc(`dailyExpenses/${sourcingObj.paymentId}`);

    const sourcing = await t.get(sourcingRef);

    const snapShot = await t.get(expenseRef);
    if (!snapShot.exists) {
      try {
        await expenseRef.set({
          amount: parseInt(sourcingObj.agentPaid),
          category: "Sourcing",
          subCategory: `${sourcingObj.agent}(agent %)`,
          note: `Invoice:${sourcingObj.id}`,
          date: sourcingObj.date,
          id: sourcingObj.paymentId,
          month: sourcingObj.month,
          receiveBy: sourcingObj.receiveBy,
          status: "pending",
        });
      } catch (error) {
        alert(error);
      }
    }

    await t.update(sourcingRef, {
      agentPaid:
        parseInt(sourcing.data().agentPaid) + parseInt(sourcingObj.agentPaid),
      status: "approved",
      finalProfit:
        parseInt(sourcing.data().totalPaid) -
        (parseInt(sourcing.data().buyingCost) +
          parseInt(sourcing.data().agentPaid) +
          parseInt(sourcingObj.agentPaid)),
    });
  });
  const sourcingRef = firestore.doc(`sourcing/${sourcingObj.id}`);
  const snapShot = await sourcingRef.get();
  return snapShot.data();
};
export const handlePurchasingCashOut = async (purchasingObj) => {
  await firestore.runTransaction(async (t) => {
    const purchasingRef = firestore.doc(`purchasing/${purchasingObj.id}`);

    const expenseRef = firestore.doc(
      `dailyExpenses/${purchasingObj.paymentId}`
    );

    const purchasing = await t.get(purchasingRef);

    const snapShot = await t.get(expenseRef);
    if (!snapShot.exists) {
      try {
        await expenseRef.set({
          amount: parseInt(purchasingObj.buyingCost),
          category: "Purchasing",
          subCategory: `${purchasingObj.agent}`,
          note: `Invoice:${purchasingObj.id}`,
          date: purchasingObj.date,
          id: purchasingObj.paymentId,
          month: purchasingObj.month,
          receiveBy: purchasingObj.receiveBy,
          status: "pending",
        });
      } catch (error) {
        alert(error);
      }
    }

    await t.update(purchasingRef, {
      buyingCost:
        parseInt(purchasing.data().buyingCost) +
        parseInt(purchasingObj.buyingCost),
      status: "approved",
      finalProfit:
        parseInt(purchasing.data().totalPaid) -
        (parseInt(purchasing.data().buyingCost) +
          parseInt(purchasingObj.buyingCost)),
    });
  });
  const purchasingRef = firestore.doc(`purchasing/${purchasingObj.id}`);
  const snapShot = await purchasingRef.get();
  return snapShot.data();
};

export const deleteProduct = async (id) => {
  const productRef = firestore.doc(`stock-products/${id}`);
  try {
    await productRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteSourcing = async (id) => {
  const sourcingRef = firestore.doc(`sourcing/${id}`);
  try {
    await sourcingRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deletePurchasing = async (id) => {
  const purchasingRef = firestore.doc(`purchasing/${id}`);
  try {
    await purchasingRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteLot = async (id) => {
  const lotRef = firestore.doc(`lots/${id}`);
  try {
    await lotRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteNotice = async (id) => {
  const noticeRef = firestore.doc(`notices/${id}`);
  try {
    await noticeRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteBlog = async (id) => {
  const blogRef = firestore.doc(`blogs/${id}`);
  try {
    await blogRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteIntro = async (id) => {
  const introRef = firestore.doc(`intros/${id}`);
  try {
    await introRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const selectIntro = async (id) => {
  const introRef = firestore.doc(`intros/${id}`);
  const introCollectionRef = firestore
    .collection("intros")
    .where("selected", "==", true);
  const introCollection = await introCollectionRef.get();
  introCollection.forEach(async (intro) => {
    const previousIntroRef = firestore.doc(`intros/${intro.data().id}`);
    try {
      await previousIntroRef.update({
        selected: false,
      });
    } catch (error) {
      alert(error);
    }
  });
  try {
    await introRef.update({
      selected: true,
    });
  } catch (error) {
    alert(error);
  }
};

export const getAllBookingsOfSingleUser = async (userId) => {
  const bookingsCollectionRef = firestore
    .collection("bookingRequest")
    .where("userId", "==", userId);
  try {
    const bookings = await bookingsCollectionRef.get();
    const bookingsArray = [];
    bookings.forEach((doc) => {
      bookingsArray.push(doc.data());
    });
    return bookingsArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllParcelsOfSingleUser = async (userId) => {
  const ordersCollectionRef = firestore
    .collection("orders")
    .where("customerUid", "==", userId);
  try {
    const orders = await ordersCollectionRef.get();
    const ordersArray = [];
    orders.forEach((doc) => {
      ordersArray.push(doc.data());
    });
    return ordersArray;
  } catch (error) {
    alert(error);
  }
};
export const getAllRechargeRequestsOfSingleUser = async (userId) => {
  const rechargeRequestsCollectionRef = firestore
    .collection("rechargeRequest")
    .where("userId", "==", userId);
  try {
    const rechargeRequests = await rechargeRequestsCollectionRef.get();
    const rechargeRequestssArray = [];
    rechargeRequests.forEach((doc) => {
      rechargeRequestssArray.push(doc.data());
    });
    return rechargeRequestssArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllRechargeOfSingleUser = async (userId) => {
  const rechargeCollectionRef = firestore
    .collection("rechargeHistory")
    .where("uid", "==", userId);
  try {
    const recharges = await rechargeCollectionRef.get();
    const rechargesArray = [];
    recharges.forEach((doc) => {
      rechargesArray.push(doc.data());
    });
    return rechargesArray;
  } catch (error) {
    alert(error);
  }
};

export const getAllPaymentOfSingleUser = async (userId) => {
  const paymentCollectionRef = firestore
    .collection("paymentHistory")
    .where("uid", "==", userId);
  try {
    const payments = await paymentCollectionRef.get();
    const paymentsArray = [];
    payments.forEach((doc) => {
      paymentsArray.push(doc.data());
    });
    return paymentsArray;
  } catch (error) {
    alert(error);
  }
};

export const deleteExpressRatesDocuments = async (id) => {
  const countryRef = firestore.doc(`expressRatesDocuments/${id}`);
  const snapShot = await countryRef.get();
  console.log(snapShot.data());
  try {
    await countryRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteOffice = async (officeId) => {
  const officeRef = firestore.doc(`offices/${officeId}`);
  const snapShot = await officeRef.get();
  console.log(snapShot.data());
  try {
    await officeRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteCnf = async (cnfId) => {
  const cnfRef = firestore.doc(`cnfs/${cnfId}`);
  const snapShot = await cnfRef.get();
  console.log(snapShot.data());
  try {
    await cnfRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteEmployee = async (employeeId) => {
  const employeeRef = firestore.doc(`employees-exchange/${employeeId}`);
  const snapShot = await employeeRef.get();
  console.log(snapShot.data());
  try {
    await employeeRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteTakaTransfer = async (expenseId) => {
  const expenseRef = firestore.doc(`dailyTakaTransfer/${expenseId}`);
  const snapShot = await expenseRef.get();
  console.log(snapShot.data());
  if (snapShot.data().status == "approved") {
    alert("This transfer is already approved!");
    return;
  }
  try {
    await expenseRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteForwarder = async (forwarderId) => {
  const forwarderRef = firestore.doc(`freight-forwarder/${forwarderId}`);
  const snapShot = await forwarderRef.get();
  console.log(snapShot.data());
  try {
    await forwarderRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteCurrencySell = async (cashId) => {
  const cashInRef = firestore.doc(`dailyCurrencySell/${cashId}`);
  const snapShot = await cashInRef.get();
  console.log(snapShot.data());
  if (snapShot.data().status == "approved") {
    alert("This transfer is already approved!");
    return;
  }
  try {
    await cashInRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteSpendCash = async (cashId) => {
  const cashInRef = firestore.doc(`dailySpendCash/${cashId}`);
  const snapShot = await cashInRef.get();
  console.log(snapShot.data());
  if (snapShot.data().status == "approved") {
    alert("This transfer is already approved!");
    return;
  }
  try {
    await cashInRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteExpressRatesParcel = async (id) => {
  const countryRef = firestore.doc(`expressRatesParcel/${id}`);
  const snapShot = await countryRef.get();
  console.log(snapShot.data());
  try {
    await countryRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const deleteD2DRates = async (freightType, country, id) => {
  const productTypeRef = firestore.doc(
    `d2d-rates-${freightType}-${country}/${id}`
  );
  const snapShot = await productTypeRef.get();
  console.log(snapShot.data());
  try {
    await productTypeRef.delete();
  } catch (error) {
    alert(error);
  }
};

export const deleteProductTax = async (id) => {
  const productRef = firestore.doc(`taxes/${id}`);
  try {
    await productRef.delete();
  } catch (error) {
    alert(error);
  }
};

export const updateProductTax = async (productObj) => {
  const productRef = firestore.doc(`taxes/${productObj.id}`);
  try {
    await productRef.update({ ...productObj });
  } catch (error) {
    alert(error);
  }
};

export const updateLot = async (lotObj) => {
  const lotRef = firestore.doc(`lots/${lotObj.lotNo}`);
  try {
    await lotRef.update({ ...lotObj });
    const snapShot = await lotRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const updateNotice = async (noticeObj) => {
  const noticeRef = firestore.doc(`notices/${noticeObj.id}`);
  try {
    await noticeRef.update({ ...noticeObj });
    const snapShot = await noticeRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const updateBlog = async (blogObj) => {
  const blogRef = firestore.doc(`blogs/${blogObj.id}`);
  try {
    await blogRef.update({ ...blogObj });
    const snapShot = await blogRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const updateBooking = async (bookingObj) => {
  const bookingRef = firestore.doc(`bookingRequest/${bookingObj.bookingId}`);
  try {
    await bookingRef.update({
      bookingStatus: bookingObj.bookingStatus,
      ...bookingObj,
    });
    const snapShot = await bookingRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateRefund = async (refundObj) => {
  try {
    // update refundStatus in main ordersArray
    const orderRef = firestore.doc(`orders/${refundObj.parcelId}`);
    const order = await orderRef.get();

    await orderRef.update({
      ...refundObj,
    });
    // create a recharge object

    // update refundStatus in users parcelArray
    // also input it in rechargeArray and transaction array of user
    // input it in rechargeHistory and rechargeDays of admin
    const userRef = firestore.doc(`users/${refundObj.customerUid}`);
    const userSnapShot = await userRef.get();
    await userRef.update({
      myWallet:
        parseInt(userSnapShot.data().myWallet) +
        parseInt(refundObj.refundAmount),
    });

    // update refund object status in refund request
    const refundRef = firestore.doc(`refundRequest/${refundObj.refundId}`);
    await refundRef.update({
      refundStatus: refundObj.refundStatus,
      refundAmount: refundObj.refundAmount,
    });
    const snapShot = await refundRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const updateExpressRatesDocuments = async (countryObj) => {
  const countryRef = firestore.doc(
    `expressRatesDocuments/${countryObj.country}`
  );
  try {
    await countryRef.update({ ...countryObj });
    const snapShot = await countryRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateOffice = async (officeObj) => {
  const officeRef = firestore.doc(`offices/${officeObj.officeId}`);
  try {
    await officeRef.update({ ...officeObj });
    const snapShot = await officeRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateCnf = async (cnfObj) => {
  const cnfRef = firestore.doc(`cnfs/${cnfObj.cnfId}`);
  try {
    await cnfRef.update({ ...cnfObj });
    const snapShot = await cnfRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateCustomer = async (cnfObj) => {
  const cnfRef = firestore.doc(`exchange-customers/${cnfObj.cnfId}`);
  try {
    await cnfRef.update({ ...cnfObj });
    const snapShot = await cnfRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateEmployee = async (employeeObj) => {
  const employeeRef = firestore.doc(
    `employees-exchange/${employeeObj.employeeId}`
  );
  try {
    await employeeRef.update({ ...employeeObj });
    const snapShot = await employeeRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateTakaTransfer = async (expenseObj) => {
  const expenseRef = firestore.doc(`dailyTakaTransfer/${expenseObj.id}`);
  const snapShot = await expenseRef.get();
  if (snapShot.data().status === "approved") {
    alert("this transfer is already approved!");
    return snapShot.data();
  }
  try {
    await expenseRef.update({ ...expenseObj });
    const updatedsnapShot = await expenseRef.get();
    return updatedsnapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateForwarder = async (forwarderObj) => {
  const forwarderRef = firestore.doc(`freight-forwarder/${forwarderObj.id}`);
  try {
    await forwarderRef.update({ ...forwarderObj });
    const snapShot = await forwarderRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateSourcing = async (sourcingObj) => {
  const sourcingRef = firestore.doc(`sourcing/${sourcingObj.id}`);
  try {
    await sourcingRef.update({ ...sourcingObj });
    const snapShot = await sourcingRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateSalary = async (employee) => {
  const categoryMonthlyExpenseRef = firestore.doc(
    `categoryMonthlyExpense/${employee.id}`
  );
  const categoryMonthlyExpnese = await categoryMonthlyExpenseRef.get();
  await categoryMonthlyExpenseRef.update({
    ...categoryMonthlyExpnese.data(),
    salary: employee.salary,
  });
  const updatedSnapShot = await categoryMonthlyExpenseRef.get();
  return updatedSnapShot.data();
};
export const createSalaryMonth = async (month, employee) => {
  const categoryMonthlyExpenseRef = firestore.doc(
    `categoryMonthlyExpense/${month}-SALARY-${employee.name}`
  );
  const categoryMonthlyExpnese = await categoryMonthlyExpenseRef.get();
  if (!categoryMonthlyExpnese.exists) {
    await categoryMonthlyExpenseRef.set({
      id: `${month}-SALARY-${employee.name}`,
      amount: 0,
      category: "SALARY",
      subCategory: employee.name,
      month: month,
      paid: false,
      salary: 0,
    });
  }
};

export const updatePurchasing = async (purchasingObj) => {
  const purchasingRef = firestore.doc(`purchasing/${purchasingObj.id}`);
  try {
    await purchasingRef.update({ ...purchasingObj });
    const snapShot = await purchasingRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const updateCurrencySell = async (cashInObj) => {
  const cashInRef = firestore.doc(`dailyCurrencySell/${cashInObj.id}`);
  const snapShot = await cashInRef.get();
  if (snapShot.data().status === "approved") {
    alert("this transfer is already approved!");
    return snapShot.data();
  }
  try {
    await cashInRef.update({ ...cashInObj });
    const updatedsnapShot = await cashInRef.get();
    return updatedsnapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateSpendCash = async (cashInObj) => {
  const cashInRef = firestore.doc(`dailySpendCash/${cashInObj.id}`);
  const snapShot = await cashInRef.get();
  if (snapShot.data().status === "approved") {
    alert("this transfer is already approved!");
    return snapShot.data();
  }
  try {
    await cashInRef.update({ ...cashInObj });
    const updatedsnapShot = await cashInRef.get();
    return updatedsnapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const updateExpressRatesParcel = async (countryObj) => {
  const countryRef = firestore.doc(`expressRatesParcel/${countryObj.country}`);
  try {
    await countryRef.update({ ...countryObj });
    const snapShot = await countryRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};
export const updateD2DRates = async (freightType, country, productTypeObj) => {
  const productTypeRef = firestore.doc(
    `d2d-rates-${freightType}-${country}/${productTypeObj.id}`
  );
  try {
    await productTypeRef.update({ ...productTypeObj });
    const snapShot = await productTypeRef.get();
    return snapShot.data();
  } catch (error) {
    alert(error);
  }
};

export const getSingleProductTax = async (id) => {
  const productRef = firestore.doc(`taxes/${id}`);
  try {
    const product = await productRef.get();
    return product.data();
  } catch (error) {
    alert(error);
  }
};
export const getSingleLot = async (id) => {
  const lotRef = firestore.doc(`lots/${id}`);
  try {
    const lot = await lotRef.get();
    return lot.data();
  } catch (error) {
    alert(error);
  }
};

// get all users
export const getAllUsers = async () => {
  const usersCollectionRef = firestore.collection("users");
  try {
    const users = await usersCollectionRef.get();
    const usersArray = [];
    users.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      usersArray.push({ uid: doc.id, ...doc.data() });
    });
    return usersArray;
  } catch (error) {
    alert(error);
  }
};

export const deleteUser = async (id) => {
  const productRef = firestore.doc(`users/${id}`);
  try {
    await productRef.delete();
  } catch (error) {
    alert(error);
  }
};
export const getAllCustomerLoans = async () => {
  const usersCollectionRef = firestore.collection("customerLoans");
  try {
    const users = await usersCollectionRef.get();
    const usersArray = [];
    users.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      usersArray.push({ uid: doc.id, ...doc.data() });
    });
    return usersArray;
  } catch (error) {
    alert(error);
  }
};

// Orders management (get all orders)

export const getAllOrders = async () => {
  const ordersCollectionRef = firestore.collection("orders");
  try {
    const orders = await ordersCollectionRef.get();
    const ordersArray = [];
    orders.forEach((doc) => {
      ordersArray.push({ orderId: doc.id, ...doc.data() });
    });
    return ordersArray;
  } catch (error) {
    alert(error);
  }
};

export const deleteOrder = async (id) => {
  const orderRef = firestore.doc(`orders/${id}`);
  try {
    await orderRef.delete();
  } catch (error) {
    alert(error);
  }
};

export const deleteSingleOrder = async (orderObj) => {
  const orderRef = firestore.doc(`orders/${orderObj.parcelId}`);
  try {
    await orderRef.delete();
  } catch (error) {
    alert(error);
  }
};

export const getSingleOrder = async (parcelId) => {
  const orderRef = firestore.doc(`orders/${parcelId}`);
  try {
    const snapShot = await orderRef.get();
    return snapShot.data();
  } catch (error) {
    return null;
  }
};
export const getSingleBooking = async (bookingId) => {
  const bookingRef = firestore.doc(`bookingRequest/${bookingId}`);
  try {
    const snapShot = await bookingRef.get();
    return snapShot.data();
  } catch (error) {
    return null;
  }
};
export const getSingleCustomer = async (cnfId) => {
  const bookingRef = firestore.doc(`exchange-customers/${cnfId}`);
  try {
    const snapShot = await bookingRef.get();
    return snapShot.data();
  } catch (error) {
    return null;
  }
};

// paymet management
export const getAllPayments = async () => {
  const paymentsCollectionRef = firestore.collection("payments");
  try {
    const payments = await paymentsCollectionRef.get();
    const paymentsArray = [];
    payments.forEach((doc) => {
      paymentsArray.push({ uid: doc.id, ...doc.data() });
    });
    return paymentsArray;
  } catch (error) {
    alert(error);
  }
};

export const deletePayment = async (orderId) => {
  const paymentRef = firestore.doc(`payments/${orderId}`);
  try {
    await paymentRef.delete();
  } catch (error) {
    alert(error);
  }
};

// admins
export const getAllAdmins = async () => {
  const adminsCollectionRef = firestore.collection("admins");
  try {
    const admins = await adminsCollectionRef.get();
    const adminsArray = [];
    admins.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      adminsArray.push({ adminId: doc.id, ...doc.data() });
    });
    return adminsArray;
  } catch (error) {
    alert(error);
  }
};

export const updateProfileImage = async (imgUrl, id) => {
  const adminRef = firestore.doc(`admins/${id}`);
  try {
    const admin = await adminRef.get();
    await adminRef.update({ ...admin.data(), image: imgUrl });
  } catch (error) {
    alert(error);
  }
};
