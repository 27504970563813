import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { ToastContainer, toast } from "react-toastify";
import CountUp from "react-countup";
import {
  getAllCnfRedux,
  uploadTakaTransferRedux,
  uploadCurrencySellRedux,
  uploadSpendCashRedux,
  getAllPendingTakaTransferByDayRedux,
  getAllPendingCurrencySellByDayRedux,
  getAllPendingSpendCashByDayRedux,
  deleteTakaTransferRedux,
  deleteCurrencySellRedux,
  deleteSpendCashRedux,
  updateTakaTransferRedux,
  updateSpendCashRedux,
  updateCurrencySellRedux,
  getSingleCashSummaryRedux,
  getAllCustomerRedux,
  getAllEmployeeRedux,
} from "../../actions/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Clock from "./clock";
import { DollarSign } from "react-feather";
import { CircleLoader } from "react-spinners";
import "./daily-expense.css";
export class DailyExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerFrom: "",
      customerTo: "",
      showSuggestionFrom: true,
      showSuggestion: true,
      open: false,
      toggleModal: true,
      singleLot: null,
      time: "",
      from: "",
      to: "",
      note: "",
      sellRate: "",
      buyRate: "",
      amount: "",
      expenseObj: null,
      cursor: -1,
      loader: false,
      method: "",
      category: "",
      subCategory: "",
    };
  }

  componentDidMount = async () => {
    let date = new Date();
    this.props.getAllCustomerRedux();
    this.props.getAllEmployeeRedux();
    await this.props.getSingleCashSummaryRedux();
    this.props.getAllPendingTakaTransferByDayRedux(
      date.toLocaleDateString("en-GB")
    );
    this.props.getAllPendingCurrencySellByDayRedux(
      date.toLocaleDateString("en-GB")
    );
    this.props.getAllPendingSpendCashByDayRedux(
      date.toLocaleDateString("en-GB")
    );
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangeCustomerFrom = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, showSuggestionFrom: true, cursor: -1 });
  };

  handleChangeCustomer = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, showSuggestion: true, cursor: -1 });
  };

  handleKeyDownFrom = (e) => {
    const { cursor } = this.state;
    let result = [];
    if (this.state.from) {
      const suggestionById = this.props.allCnfs.filter((cnf) =>
        cnf.name.toLowerCase().includes(this.state.from.toLowerCase())
      );

      result = [...suggestionById].slice(0, 10);

      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursor > -1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (e.keyCode === 40 && cursor < result.length - 1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13 && cursor > -1) {
        this.setState({
          customerFrom: result[cursor].cnfId,
          from: result[cursor].name,
          showSuggestionFrom: false,
        });
      }
    } else {
      result = [];
    }
  };

  handleKeyDownTo = (e) => {
    const { cursor } = this.state;
    let result = [];
    if (this.state.to) {
      const suggestionById = this.props.allCnfs.filter((cnf) =>
        cnf.name.includes(this.state.to.toLowerCase())
      );

      result = [...suggestionById].slice(0, 10);

      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursor > -1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (e.keyCode === 40 && cursor < result.length - 1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13 && cursor > -1) {
        this.setState({
          customerTo: result[cursor].cnfId,
          to: result[cursor].name,
          showSuggestion: false,
        });
      }
    } else {
      result = [];
    }
  };

  renderShowSuggestionFrom = () => {
    let suggestionArray = [];

    if (this.state.from) {
      const suggestionById = this.props.allCnfs
        .filter((user) =>
          user.name.toLowerCase().includes(this.state.from.toLowerCase())
        )
        .filter((user) => user.cnfId != this.state.customerTo);

      suggestionArray = [...suggestionById];
      const uniqueUser = [...new Set(suggestionArray)];
      console.log(suggestionArray);
      return uniqueUser.slice(0, 10).map((user, index) => (
        <li
          key={user.cnfId}
          style={{
            minWidth: "195px",
            backgroundColor: this.state.cursor == index ? "gainsboro" : "white",
          }}
          onClick={() =>
            this.setState({
              customerFrom: `${user.cnfId}`,
              customerUid: user.cnfId,
              showSuggestionFrom: false,
              from: `${user.name}`,
            })
          }
        >
          {user.name}
        </li>
      ));
    }
  };
  renderShowSuggestionTo = () => {
    let suggestionArray = [];
    console.log(this.state.customerTo);
    if (this.state.to) {
      const suggestionById = this.props.allCnfs
        .filter((user) =>
          user.name.toLowerCase().includes(this.state.to.toLowerCase())
        )
        .filter((user) => user.cnfId !== this.state.customerFrom);

      suggestionArray = [...suggestionById];
      const uniqueUser = [...new Set(suggestionArray)];
      console.log(suggestionArray);
      return uniqueUser.slice(0, 10).map((user, index) => (
        <li
          key={user.cnfId}
          style={{
            minWidth: "195px",
            backgroundColor: this.state.cursor == index ? "gainsboro" : "white",
          }}
          onClick={() =>
            this.setState({
              customerTo: `${user.cnfId}`,
              customerUid: user.cnfid,
              showSuggestion: false,
              to: `${user.name}`,
            })
          }
        >
          {user.name}
        </li>
      ));
    }
  };

  renderSubCategory = () => {
    return (
      <div>
        <label style={{ marginBottom: 5 }}>TO</label>
        <input
          title="Please choose a package"
          style={{ padding: 18 }}
          type="text"
          name="to"
          className="form-control"
          placeholder="ENTER CUSTOMER ID"
          aria-required="true"
          aria-invalid="false"
          onChange={this.handleChangeCustomer}
          value={this.state.to}
          required
          autoComplete="off"
          onKeyDown={this.handleKeyDownTo}
        />
        {this.state.to && (
          <ul
            className="below-searchbar-recommendation"
            style={{
              display: this.state.showSuggestion ? "flex" : "none",
            }}
          >
            {this.renderShowSuggestionTo()}
          </ul>
        )}
      </div>
    );
  };

  renderSubCategory2 = () => {
    const { category } = this.state;
    const { allOffices, allCnfs, allEmployees } = this.props;
    if (category === "DAILY COST" || category === "OFFICE") {
      return (
        <div className="col">
          <label style={{ marginBottom: 5 }}>{category}</label>
          <select
            title="Please choose a package"
            required
            name="subCategory"
            className="custom-select"
            aria-required="true"
            aria-invalid="false"
          >
            <option value={category}>{category}</option>
          </select>
        </div>
      );
    } else if (category === "SALARY") {
      return (
        <div className="col">
          <label style={{ marginBottom: 5 }}>{category}</label>
          <select
            title="Please choose a package"
            required
            name="subCategory"
            className="custom-select"
            aria-required="true"
            aria-invalid="false"
            onChange={this.handleChange}
            value={this.state.subCategory}
          >
            <option value="">SELECT EMPLOYEE</option>
            {allEmployees.map((employee, index) => (
              <option value={`${employee.name}`} key={index}>
                {employee.name}-{employee.designation}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  getMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };

  handleSubmitTakaTransfer = async (e) => {
    e.preventDefault();
    if (this.state.loader) {
      return;
    }
    if (!this.state.customerFrom) {
      alert("Please selete a From Customer Id");
      return;
    }
    if (!this.state.customerTo) {
      alert("Please select a To Customer Id");
      return;
    }
    this.setState({ loader: true });
    const { currentAdmin } = this.props;
    let date = new Date();

    let expenseObj = {
      id: date.getTime().toString(),
      from: `${this.state.customerFrom}`,
      to: `${this.state.customerTo}`,
      customerFrom: this.state.from,
      customerTo: this.state.to,
      month: this.getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      note: this.state.note,
      method: this.state.method,
      amount: this.state.amount,
      expenseBy: currentAdmin.name,
      status: "pending",
    };

    await this.props.uploadTakaTransferRedux(expenseObj);
    this.setState({
      time: "",
      from: "",
      to: "",
      note: "",
      amount: "",
      customerFrom: "",
      customerTo: "",
      customerUid: "",
      loader: false,
      method: "",
    });
  };

  handleSubmitCurrencySell = async (e, type) => {
    e.preventDefault();
    if (this.state.loader) {
      return;
    }
    if (!this.state.from) {
      alert("Please select a From Customer Id");
      return;
    }
    if (!this.state.to) {
      alert("Please select a To Customer Id");
      return;
    }
    this.setState({
      loader: true,
    });
    const { currentAdmin } = this.props;
    let date = new Date();

    let cashInObj = {
      id: date.getTime().toString(),
      from: `${this.state.customerFrom}`,
      to: `${this.state.customerTo}`,
      customerFrom: this.state.from,
      customerTo: this.state.to,
      month: this.getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      note: this.state.note,
      amount: this.state.amount,
      receiveBy: currentAdmin.name,
      status: "pending",
      buyRate: this.state.buyRate,
      sellRate: this.state.sellRate,
      type: type,
    };

    await this.props.uploadCurrencySellRedux(cashInObj);
    this.setState({
      time: "",
      from: "",
      to: "",
      note: "",
      method: "",
      amount: "",
      customerFrom: "",
      customerTo: "",
      customerUid: "",
      loader: false,
      buyRate: "",
      sellRate: "",
    });
  };
  handleSubmitSpendCash = async (e) => {
    e.preventDefault();
    if (this.state.loader) {
      return;
    }
    if (!this.state.from) {
      alert("Please select a From Customer Id");
      return;
    }
    if (!this.state.customerFrom) {
      alert("Please select a From Customer Id");
      return;
    }

    this.setState({
      loader: true,
    });
    const { currentAdmin } = this.props;
    let date = new Date();

    let cashInObj = {
      id: date.getTime().toString(),
      from: `${this.state.customerFrom}`,
      customerFrom: this.state.from,
      month: this.getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      note: this.state.note,
      amount: this.state.amount,
      receiveBy: currentAdmin.name,
      status: "pending",
      category: this.state.category,
      subCategory: this.state.subCategory
        ? this.state.subCategory
        : this.state.category,
      type: "spendCash",
    };

    await this.props.uploadSpendCashRedux(cashInObj);
    this.setState({
      time: "",
      from: "",
      to: "",
      note: "",
      method: "",
      amount: "",
      customerFrom: "",
      customerTo: "",
      customerUid: "",
      loader: false,
      buyRate: "",
      sellRate: "",
      category: "",
      subCategory: "",
    });
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
    this.setState({
      from: "",
      to: "",
      note: "",
      amount: "",
    });
  };

  render() {
    const { open, expenseObj } = this.state;
    const {
      expenses,
      allOffices,
      allCnfs,
      allEmployees,
      cashIns,
      todaysCash,
      spendCash,
    } = this.props;
    let date = new Date();
    let total = 0;
    let totalCashIns = 0;
    let totalSpendCash = 0;
    expenses.map((expense) => (total += parseInt(expense.amount)));
    spendCash.map((expense) => (totalSpendCash += parseInt(expense.amount)));
    cashIns.map(
      (expense) =>
        (totalCashIns +=
          expense.type === "rupee"
            ? parseInt(expense.amount * (100 / expense.sellRate))
            : parseInt(expense.amount * expense.sellRate))
    );
    console.log(this.props.allCnfs);

    let totalCustomerDue = 0;
    this.props.allCnfs.map((customer) => {
      if (customer.due < 0) {
        totalCustomerDue += parseInt(customer.due);
      }
    });
    let totalCustomerDebt = 0;
    this.props.allCnfs.map((customer) => {
      if (customer.due > 0) {
        totalCustomerDebt += parseInt(customer.due);
      }
    });
    return (
      <Fragment>
        <Breadcrumb title={date.toDateString()} parent="Cash" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "#16004c" }}
                  >
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <DollarSign className="font-warning" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{
                            fontWeight: "bold",
                            fontSize: "130%",
                            color: "white",
                          }}
                        >
                          Total Customer Due
                        </span>
                        <h3 className="mb-0">
                          <CountUp className="counter" end={totalCustomerDue} />
                          Tk
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden  widget-cards">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "darkgreen" }}
                  >
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <DollarSign className="font-secondary" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{
                            fontWeight: "bold",
                            fontSize: "130%",
                            color: "white",
                          }}
                        >
                          {" "}
                          Todays Taka Transfer
                        </span>
                        <h3 className="mb-0">
                          <CountUp
                            className="counter"
                            end={todaysCash && todaysCash.totalTakaTransfers}
                          />
                          Tk
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "#e50000" }}
                  >
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <DollarSign className="font-primary" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{
                            fontWeight: "bold",
                            fontSize: "130%",
                            color: "white",
                          }}
                        >
                          {" "}
                          Todays Currency Transfer
                        </span>
                        <h3 className="mb-0">
                          <CountUp
                            className="counter"
                            end={todaysCash && todaysCash.totalCurrencySell}
                          />
                          Tk
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 xl-50">
                <div className="card o-hidden widget-cards">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "gray" }}
                  >
                    <div className="media static-top-widget row">
                      <div className="icons-widgets col-4">
                        <div className="align-self-center text-center">
                          <DollarSign className="font-danger" />
                        </div>
                      </div>
                      <div className="media-body col-8">
                        <span
                          className="m-0"
                          style={{
                            fontWeight: "bold",
                            fontSize: "130%",
                            color: "white",
                          }}
                        >
                          Total company Debt
                        </span>
                        <h3 className="mb-0">
                          <CountUp
                            className="counter"
                            end={totalCustomerDebt}
                          />
                          Tk
                          <small></small>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <Tabs style={{ minWidth: "100%" }}>
              <TabList
                className="nav nav-tabs tab-coupon"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Tab
                  className="nav-link transfer-method"
                  onClick={(e) => this.clickActive(e)}
                >
                  Taka Transfer
                </Tab>

                <Tab
                  className="nav-link transfer-method"
                  onClick={(e) => this.clickActive(e)}
                >
                  Currency Transfer
                </Tab>
                <Tab
                  className="nav-link transfer-method"
                  onClick={(e) => this.clickActive(e)}
                >
                  Rupee Transfer
                </Tab>
                <Tab
                  className="nav-link transfer-method"
                  onClick={(e) => this.clickActive(e)}
                >
                  Spend Cash
                </Tab>
              </TabList>
              <TabPanel>
                <div className="col-sm-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      <h5 className="transfer-method-header">
                        <i
                          className="icofont-bill"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Taka Transfer
                      </h5>
                      <Clock />
                    </div>
                    <div
                      className="card-body"
                      style={{ paddingTop: 20, backgroundColor: "#fbfbfb" }}
                    >
                      <div className="clearfix"></div>
                      <div>
                        <div id="basicScenario" className="product-physical">
                          <form
                            style={{
                              padding: 20,
                              paddingBottom: 0,
                              paddingTop: 0,
                            }}
                            onSubmit={this.handleSubmitTakaTransfer}
                          >
                            <div className="form-row input-container">
                              <div>
                                <label style={{ marginBottom: 5 }}>FROM</label>
                                <input
                                  title="Please choose a package"
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="from"
                                  className="form-control"
                                  placeholder="ENTER CUSTOMER ID"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChangeCustomerFrom}
                                  value={this.state.from}
                                  required
                                  autoComplete="off"
                                  onKeyDown={this.handleKeyDownFrom}
                                />
                                {this.state.from && (
                                  <ul
                                    className="below-searchbar-recommendation"
                                    style={{
                                      display: this.state.showSuggestionFrom
                                        ? "flex"
                                        : "none",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {this.renderShowSuggestionFrom()}
                                  </ul>
                                )}
                              </div>
                              {this.renderSubCategory()}
                              <div>
                                <label style={{ marginBottom: 5 }}>
                                  METHOD
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="method"
                                  className="form-control"
                                  placeholder="ENTER BANK NAME"
                                  onChange={this.handleChange}
                                  value={this.state.method}
                                  required
                                />
                              </div>
                            </div>
                            <div
                              className="form-row mb-2 input-container"
                              style={{ marginTop: 20 }}
                            >
                              <div>
                                <label style={{ marginBottom: 5 }}>NOTE</label>
                                <input
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="note"
                                  className="form-control"
                                  placeholder="ENTER NOTE"
                                  onChange={this.handleChange}
                                  value={this.state.note}
                                  required
                                />
                              </div>
                              <div>
                                <label style={{ marginBottom: 5 }}>
                                  AMOUNT
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="number"
                                  name="amount"
                                  className="form-control"
                                  placeholder="ENTER AMOUNT"
                                  onChange={this.handleChange}
                                  value={this.state.amount}
                                  required
                                />
                              </div>
                            </div>
                            <div
                              className="form-row"
                              style={{
                                marginTop: 30,
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                className="btn btn-primary"
                                style={{
                                  padding: "13px 20px",
                                  fontSize: 18,
                                  minWidth: 100,
                                  minHeight: 50,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                type="submit"
                              >
                                {!this.state.loader && "SUBMIT"}
                                <CircleLoader
                                  loading={this.state.loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      <h5 className="pending-header">
                        <i
                          className="icofont-money"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Today's Pending Taka Transfer
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="clearfix"></div>
                      <div id="basicScenario" className="product-physical">
                        <table
                          className={`table table-bordered table-striped table-hover ${
                            window.innerWidth < 600 ? "table-responsive" : ""
                          }`}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                #
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                From
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                To
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Method
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Note
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Amount
                              </th>

                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Entry By
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {expenses.map((expense, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{expense.customerFrom}</td>
                                <td>{expense.customerTo}</td>
                                <td>{expense.method}</td>
                                <td>{expense.note}</td>
                                <td>{expense.amount}Tk</td>
                                <td>{expense.expenseBy}</td>
                                <td>
                                  <div
                                    className="row"
                                    style={{ justifyContent: "center" }}
                                  >
                                    <i
                                      className="icofont-edit"
                                      data-toggle="modal"
                                      data-target="#personalInfoModal"
                                      onClick={() => {
                                        this.setState({
                                          expenseObj: expense,
                                          type: "expense",
                                        });
                                      }}
                                      style={{
                                        color: "green",
                                        marginRight: 8,
                                        cursor: "pointer",
                                      }}
                                    />{" "}
                                    <i
                                      className="icofont-trash"
                                      data-toggle="modal"
                                      data-target="#deleteExpenseModal"
                                      onClick={() => {
                                        this.setState({
                                          expenseObj: expense,
                                          type: "expense",
                                        });
                                      }}
                                      style={{
                                        color: "red",
                                        marginLeft: 8,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={4} style={{ border: 0 }}></td>
                              <td style={{ fontWeight: "bold", border: 0 }}>
                                Total
                              </td>
                              <td style={{ fontWeight: "bold" }} colSpan={3}>
                                {total}Tk
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="col-sm-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      <h5 className="transfer-method-header">
                        <i
                          className="icofont-bill"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Currency Transfer
                      </h5>
                      <Clock />
                    </div>
                    <div
                      className="card-body"
                      style={{ paddingTop: 20, backgroundColor: "#fbfbfb" }}
                    >
                      <div className="clearfix"></div>
                      <div>
                        <div id="basicScenario" className="product-physical">
                          <form
                            style={{
                              padding: 20,
                              paddingBottom: 0,
                              paddingTop: 0,
                            }}
                            onSubmit={(e) =>
                              this.handleSubmitCurrencySell(e, "currency")
                            }
                          >
                            <div className="form-row input-container">
                              <div>
                                <label style={{ marginBottom: 5 }}>FROM</label>
                                <input
                                  title="Please choose a package"
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="from"
                                  className="form-control"
                                  placeholder="ENTER CUSTOMER ID"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChangeCustomerFrom}
                                  value={this.state.from}
                                  required
                                  autoComplete="off"
                                  onKeyDown={this.handleKeyDownFrom}
                                />
                                {this.state.from && (
                                  <ul
                                    className="below-searchbar-recommendation"
                                    style={{
                                      display: this.state.showSuggestionFrom
                                        ? "flex"
                                        : "none",
                                    }}
                                  >
                                    {this.renderShowSuggestionFrom()}
                                  </ul>
                                )}
                              </div>

                              {this.renderSubCategory()}

                              <div>
                                <label style={{ marginBottom: 5 }}>
                                  BUY RATE
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="number"
                                  name="buyRate"
                                  className="form-control"
                                  placeholder="ENTER RATE"
                                  onChange={this.handleChange}
                                  value={this.state.buyRate}
                                  required
                                />
                              </div>
                            </div>
                            <div
                              className="form-row mb-2 input-container"
                              style={{ marginTop: 20 }}
                            >
                              <div>
                                <label style={{ marginBottom: 5 }}>
                                  SELL RATE
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="number"
                                  name="sellRate"
                                  className="form-control"
                                  placeholder="ENTER RATE"
                                  onChange={this.handleChange}
                                  value={this.state.sellRate}
                                  required
                                />
                              </div>{" "}
                              <div>
                                <label style={{ marginBottom: 5 }}>NOTE</label>
                                <input
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="note"
                                  className="form-control"
                                  placeholder="ENTER ADDITIONAL NOTE"
                                  onChange={this.handleChange}
                                  value={this.state.note}
                                  required
                                />
                              </div>{" "}
                              <div>
                                <label style={{ marginBottom: 5 }}>
                                  AMOUNT
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="number"
                                  name="amount"
                                  className="form-control"
                                  placeholder="ENTER AMOUNT"
                                  onChange={this.handleChange}
                                  value={this.state.amount}
                                  required
                                />
                              </div>
                            </div>
                            <div
                              className="form-row"
                              style={{
                                marginTop: 30,
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                className="btn btn-primary"
                                style={{
                                  padding: "13px 20px",
                                  fontSize: 18,
                                  minWidth: 100,
                                  minHeight: 50,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                type="submit"
                              >
                                {!this.state.loader && "SUBMIT"}
                                <CircleLoader
                                  loading={this.state.loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      <h5 className="pending-header">
                        <i
                          className="icofont-money"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Today's Pending Currency Transfer
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="clearfix"></div>
                      <div id="basicScenario" className="product-physical">
                        <table
                          className={`table table-bordered table-striped table-hover ${
                            window.innerWidth < 600 ? "table-responsive" : ""
                          }`}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                #
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                From
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                To
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Buy Rate
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Sell Rate
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Note
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Total Amount
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Entry By
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cashIns.map((expense, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{expense.customerFrom}</td>
                                <td>{expense.customerTo}</td>
                                <td>{expense.buyRate}</td>
                                <td>{expense.sellRate}</td>
                                <td>{expense.note}</td>
                                <td>{expense.amount}</td>
                                <td>
                                  {expense.type === "rupee"
                                    ? parseInt(
                                        expense.amount *
                                          (100 / expense.sellRate)
                                      )
                                    : parseInt(
                                        expense.amount * expense.sellRate
                                      )}
                                  Tk
                                </td>
                                <td>{expense.receiveBy}</td>
                                <td>
                                  <div
                                    className="row"
                                    style={{ justifyContent: "center" }}
                                  >
                                    <i
                                      className="icofont-edit"
                                      data-toggle="modal"
                                      data-target="#personalInfoModal2"
                                      onClick={() => {
                                        this.setState({
                                          expenseObj: expense,
                                          type: "cashIn",
                                        });
                                      }}
                                      style={{
                                        color: "green",
                                        marginRight: 8,
                                        cursor: "pointer",
                                      }}
                                    />{" "}
                                    <i
                                      className="icofont-trash"
                                      data-toggle="modal"
                                      data-target="#deleteExpenseModal2"
                                      onClick={() => {
                                        this.setState({
                                          expenseObj: expense,
                                          type: "cashIn",
                                        });
                                      }}
                                      style={{
                                        color: "red",
                                        marginLeft: 8,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={6} style={{ border: 0 }}></td>
                              <td style={{ fontWeight: "bold", border: 0 }}>
                                Total
                              </td>
                              <td style={{ fontWeight: "bold" }} colSpan={3}>
                                {totalCashIns}Tk
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="col-sm-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      <h5 className="transfer-method-header">
                        <i
                          className="icofont-bill"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Currency Transfer
                      </h5>
                      <Clock />
                    </div>
                    <div
                      className="card-body"
                      style={{ paddingTop: 20, backgroundColor: "#fbfbfb" }}
                    >
                      <div className="clearfix"></div>
                      <div>
                        <div id="basicScenario" className="product-physical">
                          <form
                            style={{
                              padding: 20,
                              paddingBottom: 0,
                              paddingTop: 0,
                            }}
                            onSubmit={(e) =>
                              this.handleSubmitCurrencySell(e, "rupee")
                            }
                          >
                            <div className="form-row input-container">
                              <div>
                                <label style={{ marginBottom: 5 }}>FROM</label>
                                <input
                                  title="Please choose a package"
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="from"
                                  className="form-control"
                                  placeholder="ENTER CUSTOMER ID"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChangeCustomerFrom}
                                  value={this.state.from}
                                  required
                                  autoComplete="off"
                                  onKeyDown={this.handleKeyDownFrom}
                                />
                                {this.state.from && (
                                  <ul
                                    className="below-searchbar-recommendation"
                                    style={{
                                      display: this.state.showSuggestionFrom
                                        ? "flex"
                                        : "none",
                                    }}
                                  >
                                    {this.renderShowSuggestionFrom()}
                                  </ul>
                                )}
                              </div>

                              {this.renderSubCategory()}

                              <div>
                                <label style={{ marginBottom: 5 }}>
                                  BUY RATE
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="number"
                                  name="buyRate"
                                  className="form-control"
                                  placeholder="ENTER RATE"
                                  onChange={this.handleChange}
                                  value={this.state.buyRate}
                                  required
                                />
                              </div>
                            </div>
                            <div
                              className="form-row mb-2 input-container"
                              style={{ marginTop: 20 }}
                            >
                              <div>
                                <label style={{ marginBottom: 5 }}>
                                  SELL RATE
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="number"
                                  name="sellRate"
                                  className="form-control"
                                  placeholder="ENTER RATE"
                                  onChange={this.handleChange}
                                  value={this.state.sellRate}
                                  required
                                />
                              </div>{" "}
                              <div>
                                <label style={{ marginBottom: 5 }}>NOTE</label>
                                <input
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="note"
                                  className="form-control"
                                  placeholder="ENTER ADDITIONAL NOTE"
                                  onChange={this.handleChange}
                                  value={this.state.note}
                                  required
                                />
                              </div>{" "}
                              <div>
                                <label style={{ marginBottom: 5 }}>
                                  AMOUNT
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="number"
                                  name="amount"
                                  className="form-control"
                                  placeholder="ENTER AMOUNT"
                                  onChange={this.handleChange}
                                  value={this.state.amount}
                                  required
                                />
                              </div>
                            </div>
                            <div
                              className="form-row"
                              style={{
                                marginTop: 30,
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                className="btn btn-primary"
                                style={{
                                  padding: "13px 20px",
                                  fontSize: 18,
                                  minWidth: 100,
                                  minHeight: 50,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                type="submit"
                              >
                                {!this.state.loader && "SUBMIT"}
                                <CircleLoader
                                  loading={this.state.loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      <h5 className="pending-header">
                        <i
                          className="icofont-money"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Today's Pending Currency Transfer
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="clearfix"></div>
                      <div id="basicScenario" className="product-physical">
                        <table
                          className={`table table-bordered table-striped table-hover ${
                            window.innerWidth < 600 ? "table-responsive" : ""
                          }`}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                #
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                From
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                To
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Buy Rate
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Sell Rate
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Note
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Total Amount
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Entry By
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cashIns.map((expense, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{expense.customerFrom}</td>
                                <td>{expense.customerTo}</td>
                                <td>{expense.buyRate}</td>
                                <td>{expense.sellRate}</td>
                                <td>{expense.note}</td>
                                <td>{expense.amount}</td>
                                <td>
                                  {expense.type === "rupee"
                                    ? parseInt(
                                        expense.amount *
                                          (100 / expense.sellRate)
                                      )
                                    : parseInt(
                                        expense.amount * expense.sellRate
                                      )}
                                  Tk
                                </td>
                                <td>{expense.receiveBy}</td>
                                <td>
                                  <div
                                    className="row"
                                    style={{ justifyContent: "center" }}
                                  >
                                    <i
                                      className="icofont-edit"
                                      data-toggle="modal"
                                      data-target="#personalInfoModal2"
                                      onClick={() => {
                                        this.setState({
                                          expenseObj: expense,
                                          type: "cashIn",
                                        });
                                      }}
                                      style={{
                                        color: "green",
                                        marginRight: 8,
                                        cursor: "pointer",
                                      }}
                                    />{" "}
                                    <i
                                      className="icofont-trash"
                                      data-toggle="modal"
                                      data-target="#deleteExpenseModal2"
                                      onClick={() => {
                                        this.setState({
                                          expenseObj: expense,
                                          type: "cashIn",
                                        });
                                      }}
                                      style={{
                                        color: "red",
                                        marginLeft: 8,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={6} style={{ border: 0 }}></td>
                              <td style={{ fontWeight: "bold", border: 0 }}>
                                Total
                              </td>
                              <td style={{ fontWeight: "bold" }} colSpan={3}>
                                {totalCashIns}Tk
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="col-sm-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      <h5 className="transfer-method-header">
                        <i
                          className="icofont-bill"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Spend Cash
                      </h5>
                      <Clock />
                    </div>
                    <div
                      className="card-body"
                      style={{ paddingTop: 20, backgroundColor: "#fbfbfb" }}
                    >
                      <div className="clearfix"></div>
                      <div>
                        <div id="basicScenario" className="product-physical">
                          <form
                            style={{
                              padding: 20,
                              paddingBottom: 0,
                              paddingTop: 0,
                            }}
                            onSubmit={(e) => this.handleSubmitSpendCash(e)}
                          >
                            <div className="form-row input-container">
                              <div className="col">
                                <label style={{ marginBottom: 5 }}>
                                  CATEGORY
                                </label>
                                <select
                                  title="Please choose a package"
                                  required
                                  name="category"
                                  className="custom-select"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChange}
                                  value={this.state.category}
                                >
                                  <option value="">SELECT CATEGORY </option>
                                  <option value="DAILY COST">DAILY COST</option>
                                  <option value="OFFICE">OFFICE COST</option>
                                  <option value="SALARY">SALARY</option>
                                </select>
                              </div>
                              {!this.state.category ? (
                                <div className="col">
                                  <label
                                    style={{
                                      color: "#6c757d",
                                      marginBottom: 5,
                                    }}
                                  >
                                    {" "}
                                    SUB CATEGORY
                                  </label>

                                  <select
                                    title="Please choose a package"
                                    required
                                    name="selectCountry"
                                    className="custom-select"
                                    aria-required="true"
                                    aria-invalid="false"
                                    onChange={this.handleChange}
                                    value={this.state.selectCountry}
                                    aria-disabled
                                    disabled
                                  >
                                    <option value="">
                                      ***CHOOSE CATGEGORY FIRST
                                    </option>
                                  </select>
                                </div>
                              ) : (
                                this.renderSubCategory2()
                              )}

                              <div className="col">
                                <label style={{ marginBottom: 5 }}>FROM</label>
                                <input
                                  title="Please choose a package"
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="from"
                                  className="form-control"
                                  placeholder="ENTER CUSTOMER ID"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChangeCustomerFrom}
                                  value={this.state.from}
                                  required
                                  autoComplete="off"
                                  onKeyDown={this.handleKeyDownFrom}
                                />
                                {this.state.from && (
                                  <ul
                                    className="below-searchbar-recommendation"
                                    style={{
                                      display: this.state.showSuggestionFrom
                                        ? "flex"
                                        : "none",
                                    }}
                                  >
                                    {this.renderShowSuggestionFrom()}
                                  </ul>
                                )}
                              </div>
                            </div>
                            <div
                              className="form-row mb-2 input-container"
                              style={{ marginTop: 20 }}
                            >
                              <div className="col">
                                <label style={{ marginBottom: 5 }}>NOTE</label>
                                <input
                                  style={{ padding: 18 }}
                                  type="text"
                                  name="note"
                                  className="form-control"
                                  placeholder="ENTER ADDITIONAL NOTE"
                                  onChange={this.handleChange}
                                  value={this.state.note}
                                  required
                                />
                              </div>{" "}
                              <div className="col">
                                <label style={{ marginBottom: 5 }}>
                                  AMOUNT
                                </label>
                                <input
                                  style={{ padding: 18 }}
                                  type="number"
                                  name="amount"
                                  className="form-control"
                                  placeholder="ENTER AMOUNT"
                                  onChange={this.handleChange}
                                  value={this.state.amount}
                                  required
                                />
                              </div>
                              <div className="col"></div>
                            </div>
                            <div
                              className="form-row"
                              style={{
                                marginTop: 30,
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                className="btn btn-primary"
                                style={{
                                  padding: "13px 20px",
                                  fontSize: 18,
                                  minWidth: 100,
                                  minHeight: 50,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                type="submit"
                              >
                                {!this.state.loader && "SUBMIT"}
                                <CircleLoader
                                  loading={this.state.loader}
                                  color="white"
                                  size={15}
                                />
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid gainsboro",
                      }}
                    >
                      <h5 className="pending-header">
                        <i
                          className="icofont-money"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Today's Pending Spend Cash
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="clearfix"></div>
                      <div id="basicScenario" className="product-physical">
                        <table
                          className={`table table-bordered table-striped table-hover ${
                            window.innerWidth < 600 ? "table-responsive" : ""
                          }`}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                #
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Category
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Subcategory
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                From
                              </th>

                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Note
                              </th>

                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Total Amount
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Entry By
                              </th>
                              <th
                                scope="col"
                                style={{
                                  padding: "30px 15px",
                                  color: "white",
                                  backgroundColor: "#00254c",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {spendCash.map((expense, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{expense.category}</td>
                                <td>{expense.subCategory}</td>
                                <td>{expense.customerFrom}</td>
                                <td>{expense.note}</td>
                                <td>{expense.amount}</td>
                                <td>{expense.receiveBy}</td>
                                <td>
                                  <div
                                    className="row"
                                    style={{ justifyContent: "center" }}
                                  >
                                    <i
                                      className="icofont-edit"
                                      data-toggle="modal"
                                      data-target="#personalInfoModal3"
                                      onClick={() => {
                                        this.setState({
                                          expenseObj: expense,
                                          type: "spendCash",
                                        });
                                      }}
                                      style={{
                                        color: "green",
                                        marginRight: 8,
                                        cursor: "pointer",
                                      }}
                                    />{" "}
                                    <i
                                      className="icofont-trash"
                                      data-toggle="modal"
                                      data-target="#deleteExpenseModal3"
                                      onClick={() => {
                                        this.setState({
                                          expenseObj: expense,
                                          type: "cashIn",
                                        });
                                      }}
                                      style={{
                                        color: "red",
                                        marginLeft: 8,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={4} style={{ border: 0 }}></td>
                              <td style={{ fontWeight: "bold", border: 0 }}>
                                Total
                              </td>
                              <td style={{ fontWeight: "bold" }} colSpan={3}>
                                {totalSpendCash}Tk
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Taka Transfer
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      From
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerFrom : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      To
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerTo : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Method
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="method"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            method: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.method : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Method"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Note
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: { ...this.state.expenseObj, note: value },
                        });
                      }}
                      value={expenseObj ? expenseObj.note : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      AMOUNT
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            amount: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.amount : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.props.updateTakaTransferRedux(expenseObj);
                  }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content delete-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Taka Transfer
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Taka Transfer?</div>
                </div>
                <table
                  className={`table table-bordered table-striped table-hover ${
                    window.innerWidth < 600 ? "table-responsive" : ""
                  }`}
                >
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{expenseObj && expenseObj.customerFrom}</td>
                      <td>{expenseObj && expenseObj.customerTo}</td>
                      <td>{expenseObj && expenseObj.method}</td>
                      <td>{expenseObj && expenseObj.note}</td>
                      <td>{expenseObj && expenseObj.amount}Tk</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteTakaTransferRedux(expenseObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="personalInfoModal2"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Currency Sell
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      From
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerFrom : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      To
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerTo : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Buy Rate
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="buyRate"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            buyRate: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.buyRate : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Sell Rate
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sellRate"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            sellRate: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.sellRate : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Note
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            note: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.note : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      AMOUNT
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            amount: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.amount : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.props.updateCurrencySellRedux(expenseObj);
                  }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="personalInfoModal3"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Spend Cash
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Category
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.category : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Sub category
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.subCategory : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      From
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerFrom : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Note
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.note : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            note: value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      AMOUNT
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      value={expenseObj ? expenseObj.amount : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            amount: value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.props.updateSpendCashRedux(expenseObj);
                  }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal2"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content delete-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Currency Sell
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Currency Sell?</div>
                </div>
                <table
                  className={`table table-bordered table-striped table-hover ${
                    window.innerWidth < 600 ? "table-responsive" : ""
                  }`}
                >
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{expenseObj && expenseObj.from}</td>
                      <td>{expenseObj && expenseObj.to}</td>
                      <td>{expenseObj && expenseObj.buyRate}</td>
                      <td>{expenseObj && expenseObj.sellRate}</td>
                      <td>{expenseObj && expenseObj.amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteCurrencySellRedux(expenseObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal3"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content delete-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Spend Cash
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Spend Cash?</div>
                </div>
                <table
                  className={`table table-bordered table-striped table-hover ${
                    window.innerWidth < 600 ? "table-responsive" : ""
                  }`}
                >
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{expenseObj && expenseObj.category}</td>
                      <td>{expenseObj && expenseObj.subCategory}</td>
                      <td>{expenseObj && expenseObj.customerFrom}</td>
                      <td>{expenseObj && expenseObj.note}</td>
                      <td>{expenseObj && expenseObj.amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteSpendCashRedux(expenseObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allExpressDocumentsRates: state.expressRatesDocuments.expressRatesDocuments,
    allOffices: state.offices.offices,
    allCnfs: state.cnfs.cnfs,
    allEmployees: state.employees.employees,
    currentAdmin: state.admins.currentAdmin,
    expenses: state.expenses.expenses,
    cashIns: state.cashIns.cashIns,
    spendCash: state.spendCash.spendCash,
    todaysCash: state.expenses.todaysCash,
    allUsers: state.users.users,
  };
};

export default connect(mapStateToProps, {
  getAllCnfRedux,
  uploadTakaTransferRedux,
  uploadCurrencySellRedux,
  uploadSpendCashRedux,
  deleteSpendCashRedux,
  updateSpendCashRedux,
  deleteTakaTransferRedux,
  deleteCurrencySellRedux,
  updateTakaTransferRedux,
  updateCurrencySellRedux,
  getAllPendingTakaTransferByDayRedux,
  getAllPendingCurrencySellByDayRedux,
  getAllPendingSpendCashByDayRedux,
  getSingleCashSummaryRedux,
  getAllCustomerRedux,
  getAllEmployeeRedux,
})(DailyExpense);
