import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import {
  getAllCnfRedux,
  uploadTakaTransferRedux,
  uploadCurrencySellRedux,
  getAllPendingTakaTransferByDayRedux,
  getAllPendingCurrencySellByDayRedux,
  getAllPendingSpendCashByDayRedux,
  deleteTakaTransferRedux,
  deleteCurrencySellRedux,
  updateTakaTransferRedux,
  updateCurrencySellRedux,
  getSingleCashSummaryRedux,
  deleteSpendCashRedux,
  updateSpendCashRedux,
} from "../../../actions/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Clock from "./clock";
import { CircleLoader } from "react-spinners";
import {
  approveTakaTransfer,
  approveCurrencySell,
  approveSpnedCash,
  updateCashSummaryTakaTransfer,
  updateCashSummaryCurrencySell,
  updateCashSummarySpendCash,
  approveSpendCash,
} from "../../../firebase/firebase.utils";
export class DailyExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
    };
  }

  componentDidMount = async () => {
    const { expenses, allOffices, allCnfs, allEmployees } = this.props;
    let [type, day, month, year] = this.props.match.params.date.split("-");

    day = parseInt(day);
    month = parseInt(month) - 1;
    year = parseInt(year);
    let date = new Date(year, month, day);
    console.log(date.toLocaleDateString("en-GB"));
    if (type === "expense") {
      this.props.getAllPendingTakaTransferByDayRedux(
        date.toLocaleDateString("en-GB")
      );
    } else if (type == "spendCash") {
      this.props.getAllPendingSpendCashByDayRedux(
        date.toLocaleDateString("en-GB")
      );
    } else {
      this.props.getAllPendingCurrencySellByDayRedux(
        date.toLocaleDateString("en-GB")
      );
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { expenseObj } = this.state;
    const { expenses, allOffices, allCnfs, allEmployees, cashIns, spendCash } =
      this.props;
    let [type, day, month, year] = this.props.match.params.date.split("-");
    day = parseInt(day);
    month = parseInt(month) - 1;
    year = parseInt(year);
    let date = new Date(year, month, day);
    console.log(date.toDateString());

    console.log(this.props);
    let total = 0;
    let totalCashIns = 0;
    let totalSpendCash = 0;
    expenses.map((expense) => (total += parseInt(expense.amount)));
    spendCash.map((expense) => (totalSpendCash += parseInt(expense.amount)));
    cashIns.map(
      (expense) =>
        (totalCashIns +=
          expense.type === "rupee"
            ? parseInt(expense.amount * (100 / expense.sellRate))
            : parseInt(expense.amount * expense.sellRate))
    );
    return (
      <Fragment>
        <Breadcrumb
          title={
            type === "expense"
              ? "Taka Transfer"
              : type == "spendCash"
              ? "Spend Cash"
              : "Currency Sell"
          }
          parent="Daily Expense"
        />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#ff8084",
                      }}
                    ></i>
                    {date.toDateString()}
                  </h5>
                  <div>
                    <button
                      style={{
                        padding: 12,
                        backgroundColor: "darkgreen",
                        color: "white",
                        borderRadius: 5,
                        border: "none",
                        cursor: "pointer",
                      }}
                      data-toggle="modal"
                      data-target="#approveExpenseModal"
                    >
                      <i className="icofont-tick-mark" /> Approve
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table
                      className={`table table-bordered table-striped table-hover ${
                        window.innerWidth < 600 ? "table-responsive" : ""
                      }`}
                    >
                      {type === "expense" ? (
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              From
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              To
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Method
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Note
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Entry By
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                      ) : type == "spendCash" ? (
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Category
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Sub Category
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              From
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Note
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Entry By
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                      ) : (
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              From
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              To
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Buy Rate
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Sell Rate
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Note
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Total
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Entry By
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                      )}
                      {type === "expense" ? (
                        <tbody>
                          {expenses.map((expense, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{expense.customerFrom}</td>
                              <td>{expense.customerTo}</td>
                              <td>{expense.method}</td>
                              <td>{expense.note}</td>
                              <td>{expense.amount}Tk</td>
                              <td>{expense.receiveBy || expense.expenseBy}</td>
                              <td>
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <i
                                    className="icofont-edit"
                                    data-toggle="modal"
                                    data-target="#personalInfoModal"
                                    onClick={() => {
                                      this.setState({
                                        expenseObj: expense,
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      marginRight: 8,
                                      cursor: "pointer",
                                    }}
                                  />{" "}
                                  <i
                                    className="icofont-trash"
                                    data-toggle="modal"
                                    data-target="#deleteExpenseModal"
                                    onClick={() => {
                                      this.setState({
                                        expenseObj: expense,
                                      });
                                    }}
                                    style={{
                                      color: "red",
                                      marginLeft: 8,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={4} style={{ border: 0 }}></td>
                            <td style={{ fontWeight: "bold", border: 0 }}>
                              Total
                            </td>
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              {total}Tk
                            </td>
                          </tr>
                        </tbody>
                      ) : type == "spendCash" ? (
                        <tbody>
                          {spendCash.map((expense, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{expense.category}</td>
                              <td>{expense.subCategory}</td>
                              <td>{expense.customerFrom}</td>

                              <td>{expense.note}</td>
                              <td>{expense.amount}Tk</td>
                              <td>{expense.receiveBy || expense.expenseBy}</td>
                              <td>
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <i
                                    className="icofont-edit"
                                    data-toggle="modal"
                                    data-target="#personalInfoModal3"
                                    onClick={() => {
                                      this.setState({
                                        expenseObj: expense,
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      marginRight: 8,
                                      cursor: "pointer",
                                    }}
                                  />{" "}
                                  <i
                                    className="icofont-trash"
                                    data-toggle="modal"
                                    data-target="#deleteExpenseModal3"
                                    onClick={() => {
                                      this.setState({
                                        expenseObj: expense,
                                      });
                                    }}
                                    style={{
                                      color: "red",
                                      marginLeft: 8,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={4} style={{ border: 0 }}></td>
                            <td style={{ fontWeight: "bold", border: 0 }}>
                              Total
                            </td>
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              {totalSpendCash}Tk
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {cashIns.map((expense, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{expense.customerFrom}</td>
                              <td>{expense.customerTo}</td>
                              <td>{expense.buyRate}</td>
                              <td>{expense.sellRate}</td>
                              <td>{expense.note}</td>
                              <td>{expense.amount}Tk</td>
                              <td>
                                {expense.type === "rupee"
                                  ? parseInt(
                                      expense.amount * (100 / expense.sellRate)
                                    )
                                  : parseInt(expense.amount * expense.sellRate)}
                                Tk
                              </td>
                              <td>{expense.receiveBy || expense.expenseBy}</td>
                              <td>
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <i
                                    className="icofont-edit"
                                    data-toggle="modal"
                                    data-target="#personalInfoModal2"
                                    onClick={() => {
                                      this.setState({
                                        expenseObj: expense,
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      marginRight: 8,
                                      cursor: "pointer",
                                    }}
                                  />{" "}
                                  <i
                                    className="icofont-trash"
                                    data-toggle="modal"
                                    data-target="#deleteExpenseModal2"
                                    onClick={() => {
                                      this.setState({
                                        expenseObj: expense,
                                      });
                                    }}
                                    style={{
                                      color: "red",
                                      marginLeft: 8,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={6} style={{ border: 0 }}></td>
                            <td style={{ fontWeight: "bold", border: 0 }}>
                              Total
                            </td>
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              {totalCashIns}Tk
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Taka Transfer
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      From
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerFrom : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      To
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerTo : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      METHOD
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="method"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            method: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.method : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      NOTE
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: { ...this.state.expenseObj, note: value },
                        });
                      }}
                      value={expenseObj ? expenseObj.note : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      AMOUNT
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="amount"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            amount: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.amount : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.props.updateTakaTransferRedux(expenseObj);
                  }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="personalInfoModal3"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Spend Cash
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Category
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.category : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Sub category
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.subCategory : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      From
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerFrom : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Note
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.note : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            note: value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      AMOUNT
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      value={expenseObj ? expenseObj.amount : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            amount: value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.props.updateSpendCashRedux(expenseObj);
                  }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content delete-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Taka Transfer
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Taka Transfer?</div>
                </div>
                <table
                  className={`table table-bordered table-striped table-hover ${
                    window.innerWidth < 600 ? "table-responsive" : ""
                  }`}
                >
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{expenseObj && expenseObj.customerFrom}</td>
                      <td>{expenseObj && expenseObj.customerTo}</td>
                      <td>{expenseObj && expenseObj.method}</td>
                      <td>{expenseObj && expenseObj.note}</td>
                      <td>{expenseObj && expenseObj.amount}Tk</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteTakaTransferRedux(expenseObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal3"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content delete-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Spend Cash
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Spend Cash?</div>
                </div>
                <table
                  className={`table table-bordered table-striped table-hover ${
                    window.innerWidth < 600 ? "table-responsive" : ""
                  }`}
                >
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{expenseObj && expenseObj.category}</td>
                      <td>{expenseObj && expenseObj.subCategory}</td>
                      <td>{expenseObj && expenseObj.customerFrom}</td>
                      <td>{expenseObj && expenseObj.note}</td>
                      <td>{expenseObj && expenseObj.amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteSpendCashRedux(expenseObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="personalInfoModal2"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Currency Sell
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      From
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerFrom : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      To
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      value={expenseObj ? expenseObj.customerTo : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                      readOnly
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Buy Rate
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="buyRate"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            buyRate: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.buyRate : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Sell Rate
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sellRate"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            sellRate: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.sellRate : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Note
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="note"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            note: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.note : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Additional Note"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      AMOUNT
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="amount"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        this.setState({
                          expenseObj: {
                            ...this.state.expenseObj,
                            amount: value,
                          },
                        });
                      }}
                      value={expenseObj ? expenseObj.amount : ""}
                      id="exampleFormControlInput1"
                      placeholder="Enter Amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.props.updateCurrencySellRedux(expenseObj);
                  }}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal2"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content delete-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Currency Sell
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Currency Sell?</div>
                </div>
                <table
                  className={`table table-bordered table-striped table-hover ${
                    window.innerWidth < 600 ? "table-responsive" : ""
                  }`}
                >
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{expenseObj && expenseObj.customerFrom}</td>
                      <td>{expenseObj && expenseObj.customerTo}</td>
                      <td>{expenseObj && expenseObj.buyRate}</td>
                      <td>{expenseObj && expenseObj.sellRate}</td>
                      <td>{expenseObj && expenseObj.note}</td>
                      <td>{expenseObj && expenseObj.amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteCurrencySellRedux(expenseObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="approveExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Approve{" "}
                  {type === "expense"
                    ? "Taka Transfer"
                    : type == "spendCash"
                    ? "Spend Cash"
                    : "Currency Sell"}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>
                    Are you sure you want to approve{" "}
                    {type === "expense"
                      ? "Taka Transfer"
                      : type == "spendCash"
                      ? "Spend Cash"
                      : "Currency Sell"}{" "}
                    of{" "}
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {date.toDateString()}
                    </span>
                    ?
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  style={{ minHeight: 40 }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  // data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    minHeight: 40,
                    minWidth: 70,
                    textAlign: "center",
                  }}
                  onClick={async () => {
                    this.setState({ loader: true });
                    if (type === "expense") {
                      let expenseDate = expenses[0].date;
                      let expenseMonth = expenses[0].month;
                      for (let i = 0; i < expenses.length; i++) {
                        await approveTakaTransfer(expenses[i]);
                      }
                      await updateCashSummaryTakaTransfer(
                        expenseMonth,
                        expenseDate,
                        total
                      );
                    } else if (type == "spendCash") {
                      let expenseDate = spendCash[0].date;
                      let expenseMonth = spendCash[0].month;
                      for (let i = 0; i < spendCash.length; i++) {
                        await approveSpendCash(spendCash[i]);
                      }
                      await updateCashSummarySpendCash(
                        expenseMonth,
                        expenseDate,
                        totalSpendCash
                      );
                    } else {
                      let cashInDate = cashIns[0].date;
                      let cashInMonth = cashIns[0].month;
                      for (let i = 0; i < cashIns.length; i++) {
                        await approveCurrencySell(cashIns[i]);
                      }
                      await updateCashSummaryCurrencySell(
                        cashInMonth,
                        cashInDate,
                        totalCashIns
                      );
                    }

                    this.setState({ loader: false });
                    document.getElementById("dismiss-modal").click();
                    this.props.history.push("/expense/approve-expense");
                  }}
                >
                  {!this.state.loader && "Yes"}

                  {this.state.loader && (
                    <div style={{ marginTop: -5, marginLeft: -8 }}>
                      <CircleLoader
                        loading={this.state.loader}
                        color="white"
                        size={15}
                      />
                    </div>
                  )}
                </button>
              </div>
            </div>
            <button data-dismiss="modal" id="dismiss-modal"></button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentAdmin: state.admins.currentAdmin,
    expenses: state.expenses.expenses,
    cashIns: state.cashIns.cashIns,
    spendCash: state.spendCash.spendCash,
  };
};

export default connect(mapStateToProps, {
  getAllCnfRedux,
  getAllPendingTakaTransferByDayRedux,
  getAllPendingCurrencySellByDayRedux,
  getAllPendingSpendCashByDayRedux,
  deleteTakaTransferRedux,
  deleteCurrencySellRedux,
  deleteSpendCashRedux,
  updateTakaTransferRedux,
  updateCurrencySellRedux,
  updateSpendCashRedux,
  getSingleCashSummaryRedux,
})(DailyExpense);
