import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "./cnfDatatable";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { getAllOfficeRedux, getAllCustomerRedux } from "../../../actions/index";
import { Link } from "react-router-dom";
import CreateDocumentModal from "./createDocumentModal";
import { connect } from "react-redux";
import { Search } from "react-feather";
export class Cnf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      cnf: null,
      searchFor: "",
    };
  }

  componentDidMount = async () => {
    this.props.getAllCustomerRedux();
  };

  startToggleModal = async (cnfObj) => {
    if (cnfObj == null) {
      this.setState({ toggleModal: !this.state.toggleModal, cnf: null });
    } else {
      this.setState({
        toggleModal: !this.state.toggleModal,
        cnf: cnfObj,
      });
    }
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  render() {
    const { open } = this.state;
    let dueCustomers = this.props.allCnfs.filter((cnf) => cnf.due < 0);
    let debtALG = this.props.allCnfs.filter((cnf) => cnf.due >= 0);
    console.log(this.props);
    return (
      <Fragment>
        <CreateDocumentModal
          toggleModal={this.state.toggleModal}
          startToggleModal={this.startToggleModal}
          cnf={this.state.cnf}
        />
        <Breadcrumb title="Customer" parent="history" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <Tabs>
            <TabList
              className="nav nav-tabs tab-coupon"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Tab
                className="nav-link transfer-method"
                onClick={(e) => this.clickActive(e)}
                style={{ fontSize: 25 }}
              >
                CUSTOMER DUE
              </Tab>

              <Tab
                className="nav-link transfer-method"
                onClick={(e) => this.clickActive(e)}
                style={{ fontSize: 25 }}
              >
                AL-AMIN EXCHANGE DEBT
              </Tab>
            </TabList>
            <TabPanel>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>
                        <i
                          className="icofont-building"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "darkblue",
                          }}
                        ></i>
                        Customers
                      </h5>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {" "}
                        <li
                          style={{
                            border: "1px solid gainsboro",
                            borderRadius: "5rem",
                            padding: "0px 20px",
                            background: "whitesmoke",
                            marginRight: "20px",
                          }}
                        >
                          <form
                            className="form-inline search-form"
                            onSubmit={this.handleSubmit}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <input
                                className={
                                  "form-control-plaintext " +
                                  (this.state.searchbar ? "open" : "")
                                }
                                name="searchFor"
                                value={this.state.searchFor}
                                type="search"
                                placeholder="Search Customer"
                                onChange={this.handleSearchBarChange}
                              />
                              <span>
                                <Search
                                  style={{
                                    marginTop: "5px",
                                    borderLeft: "1px solid gainsboro",
                                    paddingLeft: "7px",
                                    color: "gray",
                                  }}
                                />
                              </span>
                            </div>
                          </form>
                        </li>
                        <li>
                          <button
                            className="btn "
                            style={{
                              backgroundColor: "darkblue",
                              color: "white",
                            }}
                            type="button"
                            onClick={() => this.startToggleModal(null)}
                          >
                            Add Customer
                          </button>
                        </li>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="clearfix"></div>
                      <div id="basicScenario" className="product-physical">
                        <Datatable
                          startToggleModal={this.startToggleModal}
                          history={this.props.history}
                          multiSelectOption={false}
                          myData={
                            !this.state.searchFor
                              ? dueCustomers
                              : dueCustomers.filter((cnf) =>
                                  cnf.name
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchFor.toLowerCase()
                                    )
                                )
                          }
                          pageSize={50}
                          pagination={true}
                          class="-striped -highlight"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>
                        <i
                          className="icofont-building"
                          style={{
                            fontSize: "130%",
                            marginRight: "5px",
                            color: "darkblue",
                          }}
                        ></i>
                        Customers
                      </h5>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {" "}
                        <li
                          style={{
                            border: "1px solid gainsboro",
                            borderRadius: "5rem",
                            padding: "0px 20px",
                            background: "whitesmoke",
                            marginRight: "20px",
                          }}
                        >
                          <form
                            className="form-inline search-form"
                            onSubmit={this.handleSubmit}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <input
                                className={
                                  "form-control-plaintext " +
                                  (this.state.searchbar ? "open" : "")
                                }
                                name="searchFor"
                                value={this.state.searchFor}
                                type="search"
                                placeholder="Search Customer"
                                onChange={this.handleSearchBarChange}
                              />
                              <span>
                                <Search
                                  style={{
                                    marginTop: "5px",
                                    borderLeft: "1px solid gainsboro",
                                    paddingLeft: "7px",
                                    color: "gray",
                                  }}
                                />
                              </span>
                            </div>
                          </form>
                        </li>
                        <li>
                          <button
                            className="btn "
                            style={{
                              backgroundColor: "darkblue",
                              color: "white",
                            }}
                            type="button"
                            onClick={() => this.startToggleModal(null)}
                          >
                            Add Customer
                          </button>
                        </li>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="clearfix"></div>
                      <div id="basicScenario" className="product-physical">
                        <Datatable
                          startToggleModal={this.startToggleModal}
                          history={this.props.history}
                          multiSelectOption={false}
                          myData={
                            !this.state.searchFor
                              ? debtALG
                              : debtALG.filter((cnf) =>
                                  cnf.name
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchFor.toLowerCase()
                                    )
                                )
                          }
                          pageSize={50}
                          pagination={true}
                          class="-striped -highlight"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allCnfs: state.cnfs.cnfs,
  };
};

export default connect(mapStateToProps, {
  getAllCustomerRedux,
})(Cnf);
