import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "./monthlyExpenseDatatable";
import { ExportCSV } from "./exportCsv";
import {
  getAllCustomerCurrencySellRedux,
  getAllCustomerTakaTransferRedux,
  getAllCustomerSpendCashRedux,
} from "../../../actions/index";
import { getSingleCustomer } from "../../../firebase/firebase.utils";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

export class MonthlyExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lotNumber: "",
      totalCarton: "",
      totalWeight: "",
      cartonInBd: "",
      weightInBd: "",
      bill: "",
      totalBill: "",
      customer: null,
    };
  }

  componentDidMount = async () => {
    const cnfId = this.props.match.params.name;

    this.props.getAllCustomerCurrencySellRedux(cnfId);
    this.props.getAllCustomerTakaTransferRedux(cnfId);
    this.props.getAllCustomerSpendCashRedux(cnfId);
    const customer = await getSingleCustomer(cnfId);
    this.setState({
      customer: customer,
    });
  };

  getMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    console.log(this.props);
    const name = this.props.match.params.name;
    let expenses = [];
    const { cnfBills, cnfExpenses, cnfSpendCash } = this.props;

    let transfers = [...cnfBills, ...cnfExpenses, ...cnfSpendCash];
    console.log(transfers);
    let sortedTransfers = transfers.sort((a, b) => a.id - b.id);

    let due = 0;
    sortedTransfers = sortedTransfers.map((transaction, index, array) => {
      if (index == 0) {
        if (transaction.from == name) {
          due =
            transaction.method || transaction.category
              ? parseInt(transaction.amount)
              : transaction.type === "rupee"
              ? parseInt(transaction.amount * (100 / transaction.buyRate))
              : parseInt(transaction.amount * transaction.buyRate);
        } else {
          due =
            transaction.method || transaction.category
              ? -parseInt(transaction.amount)
              : transaction.type === "rupee"
              ? -parseInt(transaction.amount * (100 / transaction.sellRate))
              : -parseInt(transaction.amount * transaction.sellRate);
        }
      } else {
        if (transaction.from == name) {
          console.log(due);
          console.log(transaction.amount);
          let value = 0;
          if (transaction.method || transaction.category) {
            value = parseInt(transaction.amount);
          } else {
            value =
              transaction.type === "rupee"
                ? parseInt(transaction.amount * (100 / transaction.buyRate))
                : parseInt(transaction.amount * transaction.buyRate);
          }
          due = due + value;
        } else {
          console.log(due);
          let value = 0;
          if (transaction.method || transaction.category) {
            value = parseInt(transaction.amount);
          } else {
            value =
              transaction.type === "rupee"
                ? parseInt(transaction.amount * (100 / transaction.sellRate))
                : parseInt(transaction.amount * transaction.sellRate);
          }
          due = due - value;
        }
      }
      return { ...transaction, due: due };
    });
    sortedTransfers = sortedTransfers.sort((a, b) => b.id - a.id);

    let printableTransfers = sortedTransfers.map((transfer) => {
      return {
        Date: transfer.date,
        Customer: this.state.customer && this.state.customer.name,
        Method: transfer.method,
        Note: transfer.note,
        Rate: transfer.from == name ? transfer.buyRate : transfer.sellRate,
        amount: transfer.amount,
        Total:
          transfer.method || transfer.category
            ? transfer.from === name
              ? transfer.amount
              : -transfer.amount
            : transfer.type === "rupee"
            ? parseInt(
                transfer.amount *
                  (transfer.from == name
                    ? 100 / transfer.buyRate
                    : -100 / transfer.sellRate)
              )
            : parseInt(
                transfer.amount *
                  (transfer.from == name
                    ? transfer.buyRate
                    : -transfer.sellRate)
              ),
        Due: transfer.due,
      };
    });

    return (
      <Fragment>
        <Breadcrumb
          title={this.state.customer && this.state.customer.name}
          parent="Summary"
        />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-calendar"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "darkblue",
                      }}
                    ></i>
                    {this.state.customer && this.state.customer.name}
                  </h5>
                </div>
                {printableTransfers.length > 0 ? (
                  <ExportCSV
                    csvData={printableTransfers.slice(0, 50)}
                    fileName={
                      this.state.customer ? this.state.customer.name : name
                    }
                  />
                ) : null}
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical row">
                    <div className="col" style={{ padding: 0 }}>
                      <table
                        className={`table table-bordered table-striped table-hover ${
                          window.innerWidth < 600 ? "table-responsive" : ""
                        }`}
                      >
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Note
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Method/Rate/Bank
                            </th>

                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Total
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Note
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Method/Rate/Bank
                            </th>

                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Total
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Due
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Transferred with
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                                fontSize: 12,
                              }}
                            >
                              Entry By
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedTransfers.map((transfer, index) => (
                            <tr key={index}>
                              <td>{transfer.date}</td>
                              <td
                                style={{
                                  color:
                                    transfer.type === "rupee"
                                      ? "#ffbf00"
                                      : "green",
                                }}
                              >
                                {transfer.from == name && transfer.note}
                              </td>
                              <td
                                style={{
                                  color:
                                    transfer.type === "rupee"
                                      ? "#ffbf00"
                                      : "green",
                                }}
                              >
                                {transfer.from == name
                                  ? transfer.method ||
                                    transfer.buyRate ||
                                    (transfer.category == "SALARY"
                                      ? `${transfer.category}-${transfer.subCategory}`
                                      : transfer.category)
                                  : ""}
                              </td>

                              <td
                                style={{
                                  color:
                                    transfer.type === "rupee"
                                      ? "#ffbf00"
                                      : "green",
                                }}
                              >
                                {transfer.from == name && transfer.amount}
                              </td>
                              <td
                                style={{
                                  color:
                                    transfer.type === "rupee"
                                      ? "#ffbf00"
                                      : "green",
                                }}
                              >
                                {transfer.from == name
                                  ? transfer.method || transfer.category
                                    ? `+${transfer.amount}Tk`
                                    : transfer.type === "rupee"
                                    ? `+${parseInt(
                                        transfer.amount *
                                          (100 / transfer.buyRate)
                                      )}Tk`
                                    : `+${parseInt(
                                        transfer.amount * transfer.buyRate
                                      )}Tk`
                                  : ""}
                              </td>
                              <td
                                style={{
                                  color:
                                    transfer.type === "rupee"
                                      ? "#ffbf00"
                                      : "red",
                                }}
                              >
                                {transfer.to == name && transfer.note}
                              </td>
                              <td
                                style={{
                                  color:
                                    transfer.type === "rupee"
                                      ? "#ffbf00"
                                      : "red",
                                }}
                              >
                                {transfer.to == name
                                  ? transfer.method || transfer.sellRate
                                  : ""}
                              </td>
                              <td
                                style={{
                                  color:
                                    transfer.type === "rupee"
                                      ? "#ffbf00"
                                      : "red",
                                }}
                              >
                                {transfer.to == name ? transfer.amount : ""}
                              </td>
                              <td
                                style={{
                                  color:
                                    transfer.type === "rupee"
                                      ? "#ffbf00"
                                      : "red",
                                }}
                              >
                                {transfer.to == name
                                  ? transfer.method
                                    ? `-${transfer.amount}Tk`
                                    : transfer.type === "rupee"
                                    ? `-${parseInt(
                                        transfer.amount *
                                          (100 / transfer.sellRate)
                                      )}Tk`
                                    : `-${parseInt(
                                        transfer.amount * transfer.sellRate
                                      )}Tk`
                                  : ""}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  color:
                                    transfer.due > 0 ? "darkgreen" : "darkred",
                                }}
                              >
                                {transfer.due}Tk
                              </td>
                              <td>
                                <a
                                  href={`https://exchange.alglimited.com/expense/cnf/${
                                    transfer.from == name
                                      ? transfer.to
                                      : transfer.from
                                  }`}
                                  target="_blank"
                                  style={{
                                    fontWeight: "bold",
                                    color: "purple",
                                  }}
                                >
                                  {transfer.from == name
                                    ? transfer.customerTo
                                    : transfer.customerFrom}
                                </a>
                              </td>
                              <td style={{ fontWeight: "bold" }}>
                                {transfer.receiveBy || transfer.expenseBy}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cnfBills: state.cnfs.cnfBills,
    cnfExpenses: state.cnfs.cnfExpenses,
    cnfSpendCash: state.cnfs.cnfSpendCash,
  };
};

export default connect(mapStateToProps, {
  getAllCustomerCurrencySellRedux,
  getAllCustomerTakaTransferRedux,
  getAllCustomerSpendCashRedux,
})(MonthlyExpense);
