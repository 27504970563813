import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  Chrome,
  BarChart,
  Settings,
  Archive,
  LogIn,
  FileText,
  CreditCard,
} from "react-feather";

const countriesAir = [
  "china",
  "india",
  "thailand",
  "singapore",
  "dubai",
  "hongkong",
  "malaysia",
  "pakistan",
];
const countriesSea = ["china", "thailand", "singapore", "hongkong", "malaysia"];
export const MENUITEMSFORADMIN = [
  // {
  //   path: "/dashboard",
  //   title: "Dashboard",
  //   icon: Home,
  //   type: "link",
  //   badgeType: "primary",
  //   active: false,
  // },
  {
    title: "Taka/Currency transfer",

    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/daily-expense",
        title: new Date().toDateString(),
        type: "link",
      },
      {
        path: "/expense/expense-history",
        title: "History",
        type: "link",
      },
    ],
  },
  {
    title: "History",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/approve-expense",
        title: "Pending",
        type: "link",
      },
      {
        path: "/expense/expense-history",
        title: "Monthly",
        type: "link",
      },
      {
        path: "/expense/cnf",
        title: "Customers",
        type: "link",
      },
    ],
  },
  {
    title: "Cash Summary",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/cash-summary",
        title: "Monthly",
        type: "link",
      },
    ],
  },

  {
    title: "Employees",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/employee",
        title: "All Employees",
        type: "link",
      },
    ],
  },

  {
    title: "Register a manager",
    path: "/",
    icon: LogIn,
    type: "link",
    active: false,
  },
];

export const MENUITEMSFORACCOUNTS = [
  // {
  //   path: "/dashboard",
  //   title: "Dashboard",
  //   icon: Home,
  //   type: "link",
  //   badgeType: "primary",
  //   active: false,
  // },
  {
    title: "Taka/Currency transfer",
    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/daily-expense",
        title: new Date().toDateString(),
        type: "link",
      },
      {
        path: "/expense/expense-history",
        title: "History",
        type: "link",
      },
    ],
  },
  {
    title: "History",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/expense-history",
        title: "Monthly",
        type: "link",
      },
      {
        path: "/expense/cnf",
        title: "Customers",
        type: "link",
      },
    ],
  },
  {
    title: "Cash Summary",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/cash-summary",
        title: "Monthly",
        type: "link",
      },
    ],
  },

  {
    title: "Employees",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/employee",
        title: "All Employees",
        type: "link",
      },
    ],
  },
];
