const INITIAL_STATE = {
  spendCash: [],
  pendingSpendCash: [],
  monthlySpendCash: [],
  singleMonthSpendCash: [],
  monthly: [],
  singleMonth: [],
  salary: [],
  expense: [],
};

const spendCashReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_SPEND_CASH":
      return { ...state, spendCash: [...action.payload] };
    case "GET_ALL_SALARY":
      return { ...state, salary: [...action.payload] };

    case "GET_ALL_PENDING_SPEND_CASH":
      return { ...state, pendingSpendCash: [...action.payload] };
    case "GET_ALL_MONTHLY_SPEND_CASH":
      return { ...state, monthlySpendCash: [...action.payload] };
    case "GET_SINGLE_MONTHLY_EXPENSE":
      return { ...state, expense: [...action.payload] };
    case "GET_SINGLE_MONTHLY_SPEND_CASH":
      return { ...state, singleMonthSpendCash: [...action.payload] };
    case "GET_ALL_MONTHLY":
      return { ...state, monthly: [...action.payload] };

    case "GET_SINGLE_MONTHLY":
      return { ...state, singleMonth: [...action.payload] };
    case "CLEAR_SINGLE_MONTHLY":
      return { ...state, singleMonth: [] };
    case "UPLOAD_SPEND_CASH":
      return {
        ...state,
        spendCash: [...state.spendCash, action.payload],
      };
    case "UPDATE_SPEND_CASH":
      const filteredCashInsArray = state.spendCash.filter(
        (cashIn) => cashIn.id !== action.payload.id
      );

      return {
        ...state,
        spendCash: [...filteredCashInsArray, action.payload],
      };
    case "DELETE_SPEND_CASH":
      const updatedCashInsArray = state.spendCash.filter(
        (cashIn) => cashIn.id !== action.payload
      );
      return {
        ...state,
        spendCash: [...updatedCashInsArray],
      };
    default:
      return { ...state };
  }
};
export default spendCashReducer;
