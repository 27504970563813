import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./index.scss";
import App from "./components/app";
import { ScrollContext } from "react-router-scroll-4";
import { Provider } from "react-redux";
import store from "./store";
import Dashboard from "./components/dashboard";
import Login from "./components/auth/login";
import DailyExpense from "./components/expense/daily-expense";

// expense history
import ApproveExpense from "./components/expense-history/approve-expense/approveExpense";
import Office from "./components/expense-history/office/office";
import Cnf from "./components/expense-history/cnf/cnf";
import Employee from "./components/expense-history/employee/employee";
import ExpenseByDay from "./components/expense-history/approve-expense/daily-expense";
import MonthlyExpense from "./components/expense-history/monthly-expense/monthlyexpense";

import MonthlyExpenseByMonth from "./components/expense-history/monthly-expense/monthlyexpenseByMonth";
import InvestMonth from "./components/expense-history/invest/monthlyexpense";
import Invests from "./components/expense-history/invest/monthlyexpenseByMonth";
import OfficeCostMonth from "./components/expense-history/daily-cost/monthlyexpense";
import OfficeCosts from "./components/expense-history/daily-cost/monthlyexpenseByMonth";
import PartnerMonth from "./components/expense-history/partner payment/monthlyexpense";
import PartnerPayments from "./components/expense-history/partner payment/monthlyexpenseByMonth";
import SalaryMonth from "./components/expense-history/salary/monthlyexpense";
import Salaries from "./components/expense-history/salary/monthlyexpenseByMonth";
import OfficeMonth from "./components/expense-history/office/monthlyexpense";
import OfficeExpenses from "./components/expense-history/office/monthlyexpenseByMonth";
import EmployeeMonth from "./components/expense-history/employee/monthlyexpense";
import EmployeeExpenses from "./components/expense-history/employee/monthlyexpenseByMonth";
import CnfMonth from "./components/expense-history/cnf/monthlyexpense";
import CnfExpenses from "./components/expense-history/cnf/monthlyexpenseByMonth";
import CashSummary from "./components/expense-history/cash-summary/monthlyexpense";
import CashSummaryByMonth from "./components/expense-history/cash-summary/monthlyexpenseByMonth";
import CashSummaryByDate from "./components/expense-history/cash-summary/daily-expense";
import MonthlyExpense2 from "./components/monthly-expense/monthlyexpense.js";
import SpendCashByMonth from "./components/monthly-expense/SpendCashByMonth.js";
import Fund from "./components/expense-history/fund/fund.js";

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAdmin: null,
    };
  }

  setCurrentAdmin = (adminObj) => {
    this.setState({ currentAdmin: adminObj });
    console.log(this.state.currentAdmin);
  };

  render() {
    const { currentAdmin } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={() => (
                  <Login setCurrentAdmin={this.setCurrentAdmin} />
                )}
              />
              <App>
                {/* <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/dashboard`}
                  component={Dashboard}
                /> */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/daily-expense`}
                  component={DailyExpense}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/office`}
                  component={Office}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/office/:name`}
                  component={OfficeMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/singleOffice/office/:month`}
                  component={OfficeExpenses}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/cnf`}
                  component={Cnf}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/cnf/:name`}
                  component={CnfMonth}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/employee`}
                  component={Employee}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/employee/:name`}
                  component={EmployeeExpenses}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/approve-expense`}
                  component={ApproveExpense}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/expense-history`}
                  component={MonthlyExpense}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/invest`}
                  component={InvestMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/invest/:month`}
                  component={Invests}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/salary`}
                  component={SalaryMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/salary/:month`}
                  component={Salaries}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/daily-cost`}
                  component={OfficeCostMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/fund`}
                  component={Fund}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/daily-cost/:month`}
                  component={OfficeCosts}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/partner-payment/:partner`}
                  component={PartnerMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/partners/partner-payment/:month`}
                  component={PartnerPayments}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/expense-history/:month`}
                  component={MonthlyExpenseByMonth}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/cash-summary`}
                  component={CashSummary}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/monthly-expense/:name`}
                  component={MonthlyExpense2}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/monthly-expense/:name/:month`}
                  component={SpendCashByMonth}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/cash-summary/:month`}
                  component={CashSummaryByMonth}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/cash-summary-by-day/:date`}
                  component={CashSummaryByDate}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/approve-expense/:date`}
                  component={ExpenseByDay}
                />
              </App>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
