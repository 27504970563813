const INITIAL_STATE = {
  purchasings: [],
};

const purchasingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_PURCHASINGS":
      return { ...state, purchasings: [...action.payload] };

    case "UPLOAD_PURCHASING":
      return {
        ...state,
        purchasings: [...state.purchasings, action.payload],
      };
    case "UPDATE_PURCHASING":
      const filteredPurchasingsArray = state.purchasings.filter(
        (purchasing) => purchasing.id !== action.payload.id
      );

      return {
        ...state,
        purchasings: [...filteredPurchasingsArray, action.payload],
      };
    case "DELETE_PURCHASING":
      const updatedPurchasingsArray = state.purchasings.filter(
        (purchasing) => purchasing.id !== action.payload
      );
      return {
        ...state,
        purchasings: [...updatedPurchasingsArray],
      };
    default:
      return { ...state };
  }
};
export default purchasingsReducer;
