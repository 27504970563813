import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { getAllFundsRedux } from "../../../actions/index";

import { connect } from "react-redux";

export class Fund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
    };
  }

  componentDidMount = async () => {
    this.props.getAllFundsRedux();
  };

  render() {
    const { funds } = this.props;

    let total = 0;

    funds.map((expense) => (total += parseInt(expense.amount)));

    return (
      <Fragment>
        <Breadcrumb title={"Fund"} parent="Office" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#ff8084",
                      }}
                    ></i>
                    ALG Fund
                  </h5>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Cateogry
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Note
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {funds.map((fund, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{fund.category}</td>

                            <td>{fund.note}</td>
                            <td>{fund.amount}Tk</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={2} style={{ border: 0 }}></td>
                          <td style={{ fontWeight: "bold", border: 0 }}>
                            Total
                          </td>
                          <td style={{ fontWeight: "bold" }}>{total}Tk</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentAdmin: state.admins.currentAdmin,
    funds: state.expenses.funds,
  };
};

export default connect(mapStateToProps, {
  getAllFundsRedux,
})(Fund);
