const INITIAL_STATE = {
  expenses: [],
  pendingExpenses: [],
  monthlyExpenses: [],
  monthlyCashSummary: [],
  singleMonthExpense: [],
  singleMonthCashSummary: [],
  monthly: [],
  singleMonth: [],
  todaysCash: null,
  funds: [],
};

const expensesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_TAKA_TRANSFERS":
      return { ...state, expenses: [...action.payload] };
    case "GET_ALL_FUNDS":
      return { ...state, funds: [...action.payload] };

    case "GET_ALL_PENDING_TAKA_TRANSFER":
      return { ...state, pendingExpenses: [...action.payload] };
    case "GET_ALL_MONTHLY_TAKA_TRANSFER":
      return { ...state, monthlyExpenses: [...action.payload] };
    case "GET_ALL_MONTHLY_CASH_SUMMARY":
      return { ...state, monthlyCashSummary: [...action.payload] };
    case "GET_SINGLE_MONTHLY_TAKA_TRANSFER":
      return { ...state, singleMonthExpense: [...action.payload] };
    case "GET_SINGLE_MONTHLY_CASH_SUMMARY":
      return { ...state, singleMonthCashSummary: [...action.payload] };
    case "GET_SINGLE_CASH_SUMMARY":
      return { ...state, todaysCash: action.payload };
    case "GET_ALL_MONTHLY":
      return { ...state, monthly: [...action.payload] };

    case "GET_SINGLE_MONTHLY":
      return { ...state, singleMonth: [...action.payload] };
    case "CLEAR_SINGLE_MONTHLY":
      return { ...state, singleMonth: [] };
    case "UPLOAD_TAKA_TRANSFER":
      return {
        ...state,
        expenses: [...state.expenses, action.payload],
      };
    case "UPDATE_TAKA_TRANSFER":
      const filteredExpenseArray = state.expenses.filter(
        (expense) => expense.id !== action.payload.id
      );

      return {
        ...state,
        expenses: [...filteredExpenseArray, action.payload],
      };
    case "DELETE_TAKA_TRANSFER":
      const updatedExpenseArray = state.expenses.filter(
        (expense) => expense.id !== action.payload
      );
      return {
        ...state,
        expenses: [...updatedExpenseArray],
      };
    default:
      return { ...state };
  }
};
export default expensesReducer;
