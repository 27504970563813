import React, { Component } from "react";
import "./createDocumentModal.css";

import {
  uploadCustomerRedux,
  updateCustomerRedux,
} from "../../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
import man from "./plus image.jpeg";
import { uploadImageRechargeRequest } from "../../../firebase/firebase.utils";
class CreateDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      address: "",
      submit_loader: false,
      file: "",
      loading: false,
      imageUrl: man,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { cnf } = nextProps;
    console.log(cnf);
    console.log("create Lot modal component will receive props is called");
    if (cnf != null) {
      this.setState(
        {
          name: cnf.name,
          address: cnf.address,
          imageUrl: cnf.imageUrl ? cnf.imageUrl : man,
        },
        () => {
          console.log(this.state);
        }
      );
    } else {
      this.setState({
        name: "",
        address: "",
        imageUrl: man,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state);
    console.log(this.props.cnf);
    if (this.state.loading) {
      alert("Please let the image to be uploaded.");
      return;
    }
    if (this.state.submit_loader) {
      return;
    }
    this.setState({ submit_loader: true });
    if (this.props.cnf === null) {
      await this.props.uploadCustomerRedux({
        address: this.state.address,
        name: `${this.props.allCnfs.length + 1}-${this.state.name.replaceAll(
          "_",
          "-"
        )}`,
        due: 0,
        imageUrl: this.state.imageUrl,
        file: "",
      });
      toast.success(`Successfully created CNF ${this.state.name}`);
    } else {
      await this.props.updateCustomerRedux({
        ...this.state,
        cnfId: this.props.cnf.cnfId,
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        file: "",
      });
      toast.success(`successfully updated CNF ${this.state.name}`);
    }

    this.setState({
      name: "",
      address: "",
      submit_loader: false,
    });
    this.props.startToggleModal(null);
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };
  render() {
    console.log(this.props.cnf);
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-4"
              style={{ backgroundColor: "darkblue" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      onClick={() => this.props.startToggleModal(null)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "white" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      {/* <div className="col bg-fixed bg-img-7 request_pag_img">
                        &nbsp;
                      </div> */}

                      <div className="col">
                        <div className="px-3 m-5">
                          <h2
                            className="h2-xl mb-3 fw-6 pb-2"
                            style={{
                              color: "white",
                              textTransform: "none",
                              fontSize: "200%",
                              borderBottom: "2px dotted white",
                            }}
                          >
                            {!this.props.cnf
                              ? "Create New Customer"
                              : "Update Customer"}
                          </h2>
                          <form
                            onSubmit={this.handleSubmit}
                            noValidate="novalidate"
                            className="rounded-field mt-4"
                          >
                            <div className="form-row mb-2">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    fontSize: "130%",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  placeholder="Customer name"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.name}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-2">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    fontSize: "130%",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Address
                                </label>
                                <input
                                  type="text"
                                  required
                                  name="address"
                                  className="form-control"
                                  placeholder="Customer Address"
                                  onChange={this.handleChange}
                                  value={this.state.address}
                                />
                              </div>
                            </div>
                            <div
                              className="form-row mb-1"
                              style={{
                                fontSize: "130%",
                                color: "white",
                                fontWeight: "bold",
                                backgroundColor: "transparent",
                              }}
                            >
                              Upload Image (Passport or NID)
                            </div>
                            <div
                              className="form-row mb-2"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <div
                                className="box-input-file"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {this.state.loading ? (
                                  <div
                                    className="spinner-border"
                                    role="status"
                                    style={{
                                      color: "purple",
                                      paddingTop: "25px",
                                      backgroundColor: "white",
                                      width: 150,
                                      height: 150,
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      border: "1px solid gainsboro",
                                      borderRadius: 5,
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginTop: 30,
                                        padding: 0,
                                        marginRight: 20,
                                      }}
                                    >
                                      <CircleLoader
                                        loading={this.state.loading}
                                        color="blue"
                                        size={35}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <img
                                      className="img-100 lazyloaded blur-up"
                                      src={this.state.imageUrl}
                                      alt="#"
                                      style={{
                                        zIndex: 6,
                                        cursor: "pointer",
                                        maxWidth: 150,
                                        minWidth: 150,
                                        minHeight: 150,
                                        maxHeight: 150,
                                        border: "1px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                      onClick={() => {
                                        document
                                          .getElementById("upload-image-input")
                                          .click();
                                      }}
                                    />

                                    <input
                                      id="upload-image-input"
                                      className="upload"
                                      type="file"
                                      style={{
                                        position: "absolute",
                                        zIndex: 5,
                                        maxWidth: "50px",
                                        marginTop: "10px",
                                      }}
                                      onChange={(e) =>
                                        this._handleImgChange(e, 0)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="form-row">
                              <div
                                className="col pt-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {this.state.submit_loader ? (
                                  <div
                                    className="btn btn-secondary"
                                    style={{ minHeight: 40, minWidth: 120 }}
                                  >
                                    <CircleLoader
                                      loading={this.state.submit_loader}
                                      color="white"
                                      size={25}
                                    />
                                  </div>
                                ) : this.props.cnf == null ? (
                                  <button
                                    type="submit"
                                    className="btn btn-secondary"
                                  >
                                    Create Customer
                                    <i className="icofont-rounded-right"></i>
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-secondary"
                                  >
                                    Update Customer
                                    <i className="icofont-rounded-right"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allCnfs: state.cnfs.cnfs,
  };
};
export default connect(mapStateToProps, {
  uploadCustomerRedux,
  updateCustomerRedux,
})(CreateDocumentModal);
