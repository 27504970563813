const INITIAL_STATE = {
  sourcings: [],
};

const sourcingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_SOURCINGS":
      return { ...state, sourcings: [...action.payload] };

    case "UPLOAD_SOURCING":
      return {
        ...state,
        sourcings: [...state.sourcings, action.payload],
      };
    case "UPDATE_SOURCING":
      const filteredSourcingsArray = state.sourcings.filter(
        (sourcing) => sourcing.id !== action.payload.id
      );

      return {
        ...state,
        sourcings: [...filteredSourcingsArray, action.payload],
      };
    case "DELETE_SOURCING":
      const updatedSourcingsArray = state.sourcings.filter(
        (sourcing) => sourcing.id !== action.payload
      );
      return {
        ...state,
        sourcings: [...updatedSourcingsArray],
      };
    default:
      return { ...state };
  }
};
export default sourcingsReducer;
