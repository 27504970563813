import {
  getAllBookings,
  getAllReceivedExpressBookings,
  updateBooking,
  getSingleBooking,
  uploadLot,
  updateLot,
  getAllLots,
  getAllDeliveredLots,
  uploadOrder,
  updateOrder,
  updateOrderDeliveryStatus,
  updateOrderInInvoice,
  getAllOrdersOfSingleLot,
  deleteSingleOrder,
  getSingleOrder,
  getAllExpressOrders,
  updateExpressOrder,
  getAllDocumentExpressRates,
  uploadExpressRatesDocuments,
  updateExpressRatesDocuments,
  deleteExpressRatesDocuments,
  getAllExpressRatesParcel,
  uploadExpressRatesParcel,
  updateExpressRatesParcel,
  deleteExpressRatesParcel,
  getAllD2DRates,
  updateD2DRates,
  uploadD2DRates,
  deleteD2DRates,
  getAllUsers,
  getAllAdmins,
  getAllRechargeRequest,
  updateRechargeRequestStatus,
  updatePaymentRequestStatus,
  updatePaymentRequestSourcingStatus,
  updatePaymentRequestStatusExpress,
  rechargeUser,
  rechargeUserFromRechargeRequest,
  updateUserStatus,
  updateAdminStatus,
  getAllRechargeDays,
  getAllRechargesOfSingleDate,
  getAllPaymentDays,
  getAllPaymentDaysExpress,
  getAllPaymentsOfSingleDate,
  getAllPaymentsOfSingleDateExpress,
  getAllRefundRequest,
  updateRefund,
  createNotice,
  createIntro,
  updateNotice,
  getAllNotices,
  createBlog,
  updateBlog,
  getAllBlogs,
  getAllIntros,
  uploadImage,
  updateAdmin,
  getAllBookingsOfSingleUser,
  getAllParcelsOfSingleUser,
  getAllRechargeRequestsOfSingleUser,
  getAllRechargeOfSingleUser,
  getAllPaymentOfSingleUser,
  deleteLot,
  makePayment,
  makePaymentExpress,
  makePaymentSourcing,
  getAllPaymentRequest,
  getAllPaymentRequestSourcings,
  getAllPaymentRequestExpress,
  getMultipleOrders,
  getAllOffices,
  uploadOffice,
  updateOffice,
  deleteOffice,
  getAllCnfs,
  getAllCustomers,
  uploadCnf,
  updateCnf,
  uploadCustomer,
  updateCustomer,
  deleteCnf,
  getAllEmployees,
  uploadEmployee,
  updateEmployee,
  deleteEmployee,
  getAllExpenses,
  getAllSpendCash,
  getAllCashIns,
  uploadTakaTransfer,
  uploadForwarder,
  updateForwarder,
  deleteForwarder,
  getAllForwarders,
  uploadSourcing,
  updateSourcing,
  deleteSourcing,
  getAllSourcings,
  getAllSourcingsAgent,
  uploadPurchasing,
  updatePurchasing,
  deletePurchasing,
  getAllPurchasings,
  getAllPurchasingsAgent,
  uploadCurrencySell,
  uploadSpendCash,
  updateTakaTransfer,
  updateCurrencySell,
  updateSpendCash,
  deleteTakaTransfer,
  deleteCurrencySell,
  deleteSpendCash,
  getAllPendingExpenses,
  getAllPendingCashIns,
  getAllPendingTakaTransfer,
  getAllPendingCurrencySell,
  getAllPendingSpendCash,
  getAllLoansCashIns,
  getAllLoansCashOuts,
  getAllLoansCashInCustomer,
  getAllLoansCashOutCustomer,
  getAllPendingTakaTransferByDay,
  getAllPendingCurrencySellByDay,
  getAllPendingSpendCashByDay,
  getAllMonthlyTakaTransfer,
  getAllMonthlyCashSummary,
  getAllMonthlyCurrencySell,
  getAllMonthlySpendCash,
  getSingleMonthlyTakaTransfer,
  getSingleMonthlySpendCash,
  getSingleMonthlyCashSummary,
  getSingleCashSummary,
  getSingleMonthlyCurrencySell,
  getAllMonthly,
  getAllMonthlySalary,
  getAllFunds,
  getSingleMonthly,
  getSingleMonthlyLoanCashIn,
  getSingleMonthlyLoanCashOut,
  getSingleMonthlySalary,
  uploadCnfBill,
  getAllMonthsCnfBill,
  getAllCnfBills,
  getAllCustomerTakaTransfer,
  getAllCustomerSpendCash,
  getAllCustomerCurrencySell,
  getAllCnfExpenses,
  uploadProduct,
  updateProduct,
  deleteProduct,
  getAllProducts,
  getAllSoldProducts,
  getAllSoldProductsMonthly,
  handleProductPay,
  handleProductPayGenerate,
  handleSourcingCashIn,
  handleSourcingCashOut,
  handleSourcingAgentPay,
  handlePurchasingCashIn,
  handlePurchasingCashOut,
  createMonth,
  getAllCustomerLoans,
  getAllInvoiceDue,
  updateSalary,
  getAllSalary,
  getSingleMonthlyExpense,
} from "../firebase/firebase.utils";

export const getAllUsersRedux = () => async (dispatch) => {
  const allUsers = await getAllUsers();
  dispatch({
    type: "GET_ALL_USERS",
    payload: allUsers,
  });
};
export const getAllCustomerLoansRedux = () => async (dispatch) => {
  const allCustomers = await getAllCustomerLoans();
  dispatch({
    type: "GET_ALL_CUSTOMERS",
    payload: allCustomers,
  });
};

export const getAllBookingsOfSingleUserRedux = (userId) => async (dispatch) => {
  const bookingsArray = await getAllBookingsOfSingleUser(userId);
  dispatch({ type: "GET_ALL_BOOKINGS_OF_SINGLE_USER", payload: bookingsArray });
};

export const getAllParcelsOfSingleUserRedux = (userId) => async (dispatch) => {
  const parcelsArray = await getAllParcelsOfSingleUser(userId);
  dispatch({ type: "GET_ALL_PARCELS_OF_SINGLE_USER", payload: parcelsArray });
};
export const getAllRechargeRequestsOfSingleUserRedux =
  (userId) => async (dispatch) => {
    const rechargeRequestArray = await getAllRechargeRequestsOfSingleUser(
      userId
    );
    dispatch({
      type: "GET_ALL_RECHARGE_REQUEST_OF_SINGLE_USER",
      payload: rechargeRequestArray,
    });
  };
export const getAllRechargeOfSingleUserRedux = (userId) => async (dispatch) => {
  const rechargeArray = await getAllRechargeOfSingleUser(userId);
  dispatch({
    type: "GET_ALL_RECHARGE_OF_SINGLE_USER",
    payload: rechargeArray,
  });
};
export const getAllPaymentOfSingleUserRedux = (userId) => async (dispatch) => {
  const paymentArray = await getAllPaymentOfSingleUser(userId);
  dispatch({
    type: "GET_ALL_PAYMENT_OF_SINGLE_USER",
    payload: paymentArray,
  });
};

export const getAllAdminsRedux = () => async (dispatch) => {
  const allAdmins = await getAllAdmins();
  dispatch({
    type: "SET_ALL_ADMINS",
    payload: allAdmins,
  });
};

export const setAllOrders = (ordersArray) => ({
  type: "SET_ALL_ORDERS",
  payload: ordersArray,
});

export const setAllPayments = (paymentsArray) => ({
  type: "SET_ALL_PAYMENTS",
  payload: paymentsArray,
});

export const setAllAdmins = (adminsArray) => ({
  type: "SET_ALL_ADMINS",
  payload: adminsArray,
});

export const setCurrentAdmin = (adminObj) => ({
  type: "SET_CURRENT_ADMIN",
  payload: adminObj,
});

export const setAllProducts = (productsArray) => ({
  type: "SET_ALL_PRODUCTS",
  payload: productsArray,
});

export const rechargeAdminredux = (adminIdArray, balance) => {
  return {
    type: "RECHARGE_ADMIN",
    payload: {
      adminIdArray,
      balance,
    },
  };
};

// bookings
export const getAllBookingsRedux = (bookingStatus) => async (dispatch) => {
  const bookingsArray = await getAllBookings(bookingStatus);
  dispatch({ type: "GET_ALL_BOOKINGS", payload: bookingsArray });
};
export const getAllReceivedExpressBookingsRedux =
  (month) => async (dispatch) => {
    const bookingsArray = await getAllReceivedExpressBookings(month);
    dispatch({ type: "GET_ALL_EXPRESS_ORDERS", payload: bookingsArray });
  };
export const updateBookingRedux = (bookingObj) => async (dispatch) => {
  const updatedBookingObj = await updateBooking(bookingObj);
  dispatch({ type: "UPDATE_BOOKING", payload: updatedBookingObj });
};

//  lots
export const getAllLotsRedux = () => async (dispatch) => {
  const lotsArray = await getAllLots();
  dispatch({ type: "GET_ALL_LOTS", payload: lotsArray });
};
export const getAllDeliveredLotsRedux = () => async (dispatch) => {
  const lotsArray = await getAllDeliveredLots();
  dispatch({ type: "GET_ALL_DELIVERED_LOTS", payload: lotsArray });
};

export const uploadLotRedux = (lotObj) => async (dispatch) => {
  const uploadedlotObj = await uploadLot(lotObj);
  dispatch({ type: "UPLOAD_LOT", payload: uploadedlotObj });
  return uploadedlotObj;
};

export const updateLotRedux = (lotObj) => async (dispatch) => {
  const updatedLotObj = await updateLot(lotObj);
  dispatch({ type: "UPDATE_LOT", payload: updatedLotObj });
};

export const deleteLotRedux = (lotNo) => async (dispatch) => {
  await deleteLot(lotNo);
  dispatch({ type: "DELETE_LOT", payload: lotNo });
};

export const updateProfileImageRedux = (imgUrl) => {
  return {
    type: "UPDATE_PROFILE_IMAGE",
    payload: imgUrl,
  };
};

// Orders
export const getAllOrdersOfSingleLotRedux = (lotObj) => async (dispatch) => {
  const ordersArray = await getAllOrdersOfSingleLot(lotObj);
  dispatch({ type: "GET_ALL_ORDERS", payload: ordersArray });
  return ordersArray;
};
export const getAllInvoiceDueRedux = () => async (dispatch) => {
  const ordersArray = await getAllInvoiceDue();
  dispatch({ type: "GET_ALL_DUE_INVOICES", payload: ordersArray });
  return ordersArray;
};
export const getMultipleOrdersRedux = (invoicesIdArray) => async (dispatch) => {
  const ordersArray = await getMultipleOrders(invoicesIdArray);
  dispatch({ type: "GET_ALL_PAYABLE_ORDERS", payload: ordersArray });
  return ordersArray;
};

export const deleteSingleOrderRedux = (orderObj) => async (dispatch) => {
  await deleteSingleOrder(orderObj);
  dispatch({ type: "DELETE_SINGLE_ORDER", payload: orderObj });
};

export const getSingleOrderRedux = (parcelId) => async (dispatch) => {
  const resultOrderObj = await getSingleOrder(parcelId);
  dispatch({ type: "GET_SINGLE_ORDER", payload: resultOrderObj });
};
export const getSingleBookingRedux = (bookingId) => async (dispatch) => {
  const resultbookingObj = await getSingleBooking(bookingId);
  dispatch({ type: "GET_SINGLE_BOOKING", payload: resultbookingObj });
};

export const uploadOrderRedux = (orderObj) => async (dispatch) => {
  const uploadedOrderObj = await uploadOrder(orderObj);
  dispatch({ type: "UPLOAD_ORDER", payload: uploadedOrderObj });
  return uploadedOrderObj;
};
export const updateOrderRedux = (orderObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrder(orderObj);
  dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
  return updatedOrderObj;
};
export const updateOrderDeliveryStatusRedux =
  (orderObj) => async (dispatch) => {
    const updatedOrderObj = await updateOrderDeliveryStatus(orderObj);
    dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
    return updatedOrderObj;
  };
export const updateOrderInInvoiceRedux = (orderObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrderInInvoice(orderObj);
  dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
  return updatedOrderObj;
};
export const updateOrderBeforeInvoiceRedux = (orderObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrder(orderObj);
  dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
  return updatedOrderObj;
};
export const updateOrderAfterInvoiceRedux = (orderObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrder(orderObj);
  dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
  return updatedOrderObj;
};

// Express
export const getAllExpressOrdersRedux = () => async (dispatch) => {
  const ordersArrayExpress = await getAllExpressOrders();
  dispatch({ type: "GET_ALL_EXPRESS_ORDERS", payload: ordersArrayExpress });
};

export const updateExpressOrderStatusRedux =
  (id, month) => async (dispatch) => {
    const updatedExpressOrder = await updateExpressOrder(id, month);
    dispatch({
      type: "UPDATE_EXPRESS_ORDER_STATUS",
      payload: updatedExpressOrder,
    });
  };

// Express Rates

export const getAllDocumentExpressRatesRedux = () => async (dispatch) => {
  const documentExpressRatesArray = await getAllDocumentExpressRates();
  dispatch({
    type: "GET_ALL_DOCUMENT_EXPRESS_RATES",
    payload: documentExpressRatesArray,
  });
};

export const uploadExpressRatesDocumentsRedux =
  (countryObj) => async (dispatch) => {
    const uploadedExpressRatesDocumentsObj = await uploadExpressRatesDocuments(
      countryObj
    );
    dispatch({
      type: "UPLOAD_EXPRESS_RATES_DOCUMENTS",
      payload: uploadedExpressRatesDocumentsObj,
    });
  };

export const updateExpressRatesDocumentsRedux =
  (countryObj) => async (dispatch) => {
    const updatedExpressRatesDocumentsObj = await updateExpressRatesDocuments(
      countryObj
    );
    dispatch({
      type: "UPDATE_EXPRESS_RATES_DOCUMENTS",
      payload: updatedExpressRatesDocumentsObj,
    });
  };

export const deleteExpressRatesDocumentsRedux =
  (countryId) => async (dispatch) => {
    await deleteExpressRatesDocuments(countryId);
    dispatch({
      type: "DELETE_EXPRESS_RATES_DOCUMENTS",
      payload: countryId,
    });
  };

// OFFICE CRUD
export const getAllOfficeRedux = () => async (dispatch) => {
  const offices = await getAllOffices();
  dispatch({
    type: "GET_ALL_OFFICES",
    payload: offices,
  });
};

export const uploadOfficeRedux = (officeObj) => async (dispatch) => {
  const uploadedOfficeObj = await uploadOffice(officeObj);
  dispatch({
    type: "UPLOAD_OFFICE",
    payload: uploadedOfficeObj,
  });
};

export const updateOfficeRedux = (officeObj) => async (dispatch) => {
  const updatedOfficeObj = await updateOffice(officeObj);
  dispatch({
    type: "UPDATE_OFFICE",
    payload: updatedOfficeObj,
  });
};
export const deleteOfficeRedux = (officeId) => async (dispatch) => {
  await deleteOffice(officeId);
  dispatch({
    type: "DELETE_OFFICE",
    payload: officeId,
  });
};

// CNF CRUD
export const getAllCnfRedux = () => async (dispatch) => {
  const cnfs = await getAllCnfs();
  dispatch({
    type: "GET_ALL_CNFS",
    payload: cnfs,
  });
};

export const getAllCustomerRedux = () => async (dispatch) => {
  const cnfs = await getAllCustomers();
  dispatch({
    type: "GET_ALL_CUSTOMERS",
    payload: cnfs,
  });
};

export const getAllCustomerTakaTransferRedux = (cnfId) => async (dispatch) => {
  const cnfs = await getAllCustomerTakaTransfer(cnfId);
  dispatch({
    type: "GET_ALL_CNF_BILLS",
    payload: cnfs,
  });
};
export const getAllCustomerSpendCashRedux = (cnfId) => async (dispatch) => {
  const cnfs = await getAllCustomerSpendCash(cnfId);
  dispatch({
    type: "GET_ALL_CNF_SPEND_CASH",
    payload: cnfs,
  });
};
export const getAllCustomerCurrencySellRedux = (cnfId) => async (dispatch) => {
  const cnfs = await getAllCustomerCurrencySell(cnfId);
  dispatch({
    type: "GET_ALL_CNF_EXPENSES",
    payload: cnfs,
  });
};

export const uploadCnfRedux = (cnfObj) => async (dispatch) => {
  const uploadedCnfObj = await uploadCnf(cnfObj);
  dispatch({
    type: "UPLOAD_CNF",
    payload: uploadedCnfObj,
  });
};
export const uploadCustomerRedux = (cnfObj) => async (dispatch) => {
  const uploadedCustomerObj = await uploadCustomer(cnfObj);
  dispatch({
    type: "UPLOAD_CUSTOMER",
    payload: uploadedCustomerObj,
  });
};
export const uploadCnfBillRedux = (billObj) => async (dispatch) => {
  await uploadCnfBill(billObj);
  dispatch({
    type: "UPLOAD_CNF_BILL",
    payload: billObj,
  });
};
export const getAllCnfBillMonthsRedux = (month) => async (dispatch) => {
  const allMonths = await getAllMonthsCnfBill(month);
  dispatch({
    type: "ALL_MONTHS_CNF_BILL",
    payload: allMonths,
  });
};

export const updateCnfRedux = (cnfObj) => async (dispatch) => {
  const updatedCnfObj = await updateCnf(cnfObj);
  dispatch({
    type: "UPDATE_CNF",
    payload: updatedCnfObj,
  });
};

export const updateCustomerRedux = (cnfObj) => async (dispatch) => {
  const updatedCnfObj = await updateCustomer(cnfObj);
  dispatch({
    type: "UPDATE_CUSTOMER",
    payload: updatedCnfObj,
  });
};

export const deleteCnfRedux = (cnfId) => async (dispatch) => {
  await deleteCnf(cnfId);
  dispatch({
    type: "DELETE_CNF",
    payload: cnfId,
  });
};
// EMPLOYEE CRUD
export const getAllEmployeeRedux = () => async (dispatch) => {
  const employees = await getAllEmployees();
  dispatch({
    type: "GET_ALL_EMPLOYEES",
    payload: employees,
  });
};

export const uploadEmployeeRedux = (employeeObj) => async (dispatch) => {
  const uploadedEmployeeObj = await uploadEmployee(employeeObj);
  dispatch({
    type: "UPLOAD_EMPLOYEE",
    payload: uploadedEmployeeObj,
  });
};

export const updateEmployeeRedux = (employeeObj) => async (dispatch) => {
  const updatedEmployeeObj = await updateEmployee(employeeObj);
  dispatch({
    type: "UPDATE_EMPLOYEE",
    payload: updatedEmployeeObj,
  });
};

export const deleteEmployeeRedux = (employeeId) => async (dispatch) => {
  await deleteEmployee(employeeId);
  dispatch({
    type: "DELETE_EMPLOYEE",
    payload: employeeId,
  });
};
// DAILY EXPENSE CRUD
export const getAllExpenseRedux = (day) => async (dispatch) => {
  const expenses = await getAllExpenses(day);
  dispatch({
    type: "GET_ALL_TAKA_TRANSFERS",
    payload: expenses,
  });
};
export const getAllSpendCashRedux = (day) => async (dispatch) => {
  const expenses = await getAllSpendCash(day);
  dispatch({
    type: "GET_ALL_SPEND_CASH",
    payload: expenses,
  });
};
export const getAllForwarderRedux = () => async (dispatch) => {
  const forwarders = await getAllForwarders();
  dispatch({
    type: "GET_ALL_FORWARDERS",
    payload: forwarders,
  });
};
export const getAllProductsRedux = () => async (dispatch) => {
  const allProducts = await getAllProducts();
  dispatch({
    type: "GET_ALL_PRODUCTS",
    payload: allProducts,
  });
};
export const getAllSourcingsRedux = (month) => async (dispatch) => {
  const allSourcings = await getAllSourcings(month);
  dispatch({
    type: "GET_ALL_SOURCINGS",
    payload: allSourcings,
  });
};
export const getAllSourcingsAgentRedux = (agent, month) => async (dispatch) => {
  const allSourcings = await getAllSourcingsAgent(agent, month);
  dispatch({
    type: "GET_ALL_SOURCINGS",
    payload: allSourcings,
  });
};
export const getAllPurchasingsRedux = (month) => async (dispatch) => {
  const allPurchasings = await getAllPurchasings(month);
  dispatch({
    type: "GET_ALL_PURCHASINGS",
    payload: allPurchasings,
  });
};
export const getAllPurchasingsAgentRedux =
  (agent, month) => async (dispatch) => {
    const allPurchasings = await getAllPurchasingsAgent(agent, month);
    dispatch({
      type: "GET_ALL_PURCHASINGS",
      payload: allPurchasings,
    });
  };
export const getAllSoldProductsRedux = () => async (dispatch) => {
  const allProducts = await getAllSoldProducts();
  dispatch({
    type: "GET_ALL_SOLD_PRODUCTS",
    payload: allProducts,
  });
};
export const getAllSoldProductsMonthlyRedux = (month) => async (dispatch) => {
  const allProducts = await getAllSoldProductsMonthly(month);
  dispatch({
    type: "GET_ALL_SOLD_PRODUCTS_MONTHLY",
    payload: allProducts,
  });
};
export const getAllCashInsRedux = (day) => async (dispatch) => {
  const cashIns = await getAllCashIns(day);
  dispatch({
    type: "GET_ALL_CURRENCY_SELL",
    payload: cashIns,
  });
};

export const getAllMonthlyTakaTransferRedux = () => async (dispatch) => {
  const expenses = await getAllMonthlyTakaTransfer();
  dispatch({
    type: "GET_ALL_MONTHLY_TAKA_TRANSFER",
    payload: expenses,
  });
};
export const getAllMonthlyCashSummaryRedux = () => async (dispatch) => {
  const months = await getAllMonthlyCashSummary();
  dispatch({
    type: "GET_ALL_MONTHLY_CASH_SUMMARY",
    payload: months,
  });
};
export const getAllMonthlyCurrencySellRedux = () => async (dispatch) => {
  const expenses = await getAllMonthlyCurrencySell();
  dispatch({
    type: "GET_ALL_MONTHLY_CURRENCY_SELL",
    payload: expenses,
  });
};
export const getAllMonthlySpendCashRedux = () => async (dispatch) => {
  const expenses = await getAllMonthlySpendCash();
  dispatch({
    type: "GET_ALL_MONTHLY_SPEND_CASH",
    payload: expenses,
  });
};

export const getAllMonthlyRedux =
  (category, subCategory) => async (dispatch) => {
    const expenses = await getAllMonthly(category, subCategory);
    dispatch({
      type: "GET_ALL_MONTHLY",
      payload: expenses,
    });
  };
export const getAllFundsRedux = () => async (dispatch) => {
  const funds = await getAllFunds();
  dispatch({
    type: "GET_ALL_FUNDS",
    payload: funds,
  });
};
export const getAllSalaryRedux = (name) => async (dispatch) => {
  const funds = await getAllSalary(name);
  dispatch({
    type: "GET_ALL_SALARY",
    payload: funds,
  });
};
export const getAllMonthlySalaryRedux = () => async (dispatch) => {
  const expenses = await getAllMonthlySalary();
  dispatch({
    type: "GET_ALL_MONTHLY",
    payload: expenses,
  });
};

export const getSingleMonthlyTakaTransferRedux =
  (month) => async (dispatch) => {
    const expenses = await getSingleMonthlyTakaTransfer(month);
    dispatch({
      type: "GET_SINGLE_MONTHLY_TAKA_TRANSFER",
      payload: expenses,
    });
  };
export const getSingleMonthlyExpenseRedux =
  (month, name) => async (dispatch) => {
    const expenses = await getSingleMonthlyExpense(month, name);
    dispatch({
      type: "GET_SINGLE_MONTHLY_EXPENSE",
      payload: expenses,
    });
  };
export const getSingleMonthlySpendCashRedux = (month) => async (dispatch) => {
  const expenses = await getSingleMonthlySpendCash(month);
  dispatch({
    type: "GET_SINGLE_MONTHLY_SPEND_CASH",
    payload: expenses,
  });
};
export const getSingleMonthlyCashSummaryRedux = (month) => async (dispatch) => {
  const expenses = await getSingleMonthlyCashSummary(month);
  dispatch({
    type: "GET_SINGLE_MONTHLY_CASH_SUMMARY",
    payload: expenses,
  });
};
export const getSingleCashSummaryRedux = () => async (dispatch) => {
  const cashObj = await getSingleCashSummary();
  dispatch({
    type: "GET_SINGLE_CASH_SUMMARY",
    payload: cashObj,
  });
};
export const createMonthRedux = () => async (dispatch) => {
  const months = await createMonth();
  dispatch({
    type: "GET_ALL_MONTHS",
    payload: months,
  });
};
export const getSingleMonthlyCurrencySellRedux =
  (month) => async (dispatch) => {
    const expenses = await getSingleMonthlyCurrencySell(month);
    dispatch({
      type: "GET_SINGLE_MONTHLY_CURRENCY_SELL",
      payload: expenses,
    });
  };

export const getSingleMonthlyRedux =
  (month, category, subCategory) => async (dispatch) => {
    const expenses = await getSingleMonthly(month, category, subCategory);
    dispatch({
      type: "GET_SINGLE_MONTHLY",
      payload: expenses,
    });
  };
export const getSingleMonthlySalaryRedux =
  (month, category) => async (dispatch) => {
    const expenses = await getSingleMonthlySalary(month, category);
    dispatch({
      type: "GET_SINGLE_MONTHLY",
      payload: expenses,
    });
  };
export const getSingleMonthlyLoanCashInRedux =
  (month, category) => async (dispatch) => {
    const cashIns = await getSingleMonthlyLoanCashIn(month, category);
    dispatch({
      type: "GET_SINGLE_MONTHLY_LOANS_CASHIN",
      payload: cashIns,
    });
  };
export const getSingleMonthlyLoanCashOutRedux =
  (month, category) => async (dispatch) => {
    const expenses = await getSingleMonthlyLoanCashOut(month, category);
    dispatch({
      type: "GET_SINGLE_MONTHLY_LOANS_CASHOUT",
      payload: expenses,
    });
  };
export const clearSingleMonthRedux = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_SINGLE_MONTHLY",
  });
};

export const getAllPendingTakaTransferByDayRedux =
  (day) => async (dispatch) => {
    const takaTransfers = await getAllPendingTakaTransferByDay(day);
    dispatch({
      type: "GET_ALL_TAKA_TRANSFERS",
      payload: takaTransfers,
    });
  };
export const getAllPendingCurrencySellByDayRedux =
  (day) => async (dispatch) => {
    const cashIns = await getAllPendingCurrencySellByDay(day);
    dispatch({
      type: "GET_ALL_CURRENCY_SELL",
      payload: cashIns,
    });
  };
export const getAllPendingSpendCashByDayRedux = (day) => async (dispatch) => {
  const cashIns = await getAllPendingSpendCashByDay(day);
  dispatch({
    type: "GET_ALL_SPEND_CASH",
    payload: cashIns,
  });
};
export const getAllPendingExpensesRedux = () => async (dispatch) => {
  const expenses = await getAllPendingExpenses();
  dispatch({
    type: "GET_ALL_PENDING_EXPENSES",
    payload: expenses,
  });
};
export const getAllPendingTakaTransferRedux = () => async (dispatch) => {
  const expenses = await getAllPendingTakaTransfer();
  dispatch({
    type: "GET_ALL_PENDING_TAKA_TRANSFER",
    payload: expenses,
  });
};
export const getAllPendingCashInsRedux = () => async (dispatch) => {
  const cashIns = await getAllPendingCashIns();
  dispatch({
    type: "GET_ALL_PENDING_CASH_INS",
    payload: cashIns,
  });
};
export const getAllPendingCurrencySellRedux = () => async (dispatch) => {
  const cashIns = await getAllPendingCurrencySell();
  dispatch({
    type: "GET_ALL_PENDING_CURRENCY_SELL",
    payload: cashIns,
  });
};
export const getAllPendingSpendCashRedux = () => async (dispatch) => {
  const cashIns = await getAllPendingSpendCash();
  dispatch({
    type: "GET_ALL_PENDING_SPEND_CASH",
    payload: cashIns,
  });
};
export const getAllLoansCashOutsRedux = () => async (dispatch) => {
  const expenses = await getAllLoansCashOuts();
  dispatch({
    type: "GET_ALL_LOANS_CASH_OUTS",
    payload: expenses,
  });
};
export const getAllLoansCashInsRedux = () => async (dispatch) => {
  const cashIns = await getAllLoansCashIns();
  dispatch({
    type: "GET_ALL_LOANS_CASH_INS",
    payload: cashIns,
  });
};
export const getAllLoansCashOutCustomerRedux =
  (customer) => async (dispatch) => {
    const expenses = await getAllLoansCashOutCustomer(customer);
    dispatch({
      type: "GET_ALL_LOANS_CASH_OUTS_CUSTOMER",
      payload: expenses,
    });
  };
export const getAllLoansCashInCustomerRedux =
  (customer) => async (dispatch) => {
    const cashIns = await getAllLoansCashInCustomer(customer);
    dispatch({
      type: "GET_ALL_LOANS_CASH_INS_CUSTOMER",
      payload: cashIns,
    });
  };

export const uploadTakaTransferRedux = (expenseObj) => async (dispatch) => {
  const uploadedExpenseObj = await uploadTakaTransfer(expenseObj);
  dispatch({
    type: "UPLOAD_TAKA_TRANSFER",
    payload: uploadedExpenseObj,
  });
};
export const uploadForwarderRedux = (forwarderObj) => async (dispatch) => {
  const uploadedForwarderObj = await uploadForwarder(forwarderObj);
  dispatch({
    type: "UPLOAD_FORWARDER",
    payload: uploadedForwarderObj,
  });
};
export const uploadSourcingRedux = (sourcingObj) => async (dispatch) => {
  const uploadedSourcingObj = await uploadSourcing(sourcingObj);
  dispatch({
    type: "UPLOAD_SOURCING",
    payload: uploadedSourcingObj,
  });
};

export const uploadPurchasingRedux = (purchasingObj) => async (dispatch) => {
  const uploadedPurchasingObj = await uploadPurchasing(purchasingObj);
  dispatch({
    type: "UPLOAD_PURCHASING",
    payload: uploadedPurchasingObj,
  });
};

export const uploadProductRedux = (productObj) => async (dispatch) => {
  const uploadedProductObj = await uploadProduct(productObj);
  dispatch({
    type: "UPLOAD_PRODUCT",
    payload: uploadedProductObj,
  });
};

export const uploadCurrencySellRedux = (cashInObj) => async (dispatch) => {
  const uploadedCashInObj = await uploadCurrencySell(cashInObj);
  dispatch({
    type: "UPLOAD_CURRENCY_SELL",
    payload: uploadedCashInObj,
  });
};
export const uploadSpendCashRedux = (cashInObj) => async (dispatch) => {
  const uploadedCashInObj = await uploadSpendCash(cashInObj);
  dispatch({
    type: "UPLOAD_SPEND_CASH",
    payload: uploadedCashInObj,
  });
};

export const updateTakaTransferRedux = (expenseObj) => async (dispatch) => {
  const updatedExpenseObj = await updateTakaTransfer(expenseObj);
  dispatch({
    type: "UPDATE_TAKA_TRANSFER",
    payload: updatedExpenseObj,
  });
};
export const updateForwarderRedux = (forwarderObj) => async (dispatch) => {
  const updatedForwarderObj = await updateForwarder(forwarderObj);
  dispatch({
    type: "UPDATE_FORWARDER",
    payload: updatedForwarderObj,
  });
};
export const updateProductRedux = (productObj) => async (dispatch) => {
  const updatedProductObj = await updateProduct(productObj);
  dispatch({
    type: "UPDATE_PRODUCT",
    payload: updatedProductObj,
  });
};
export const updateSourcingRedux = (sourcingObj) => async (dispatch) => {
  const updatedSourcingObj = await updateSourcing(sourcingObj);
  dispatch({
    type: "UPDATE_SOURCING",
    payload: updatedSourcingObj,
  });
};
export const updateSalaryRedux = (employeeObj) => async (dispatch) => {
  const updatedEmployeeObj = await updateSalary(employeeObj);
  dispatch({
    type: "UPDATE_SALARY",
    payload: updatedEmployeeObj,
  });
};
export const updatePurchasingRedux = (purchasingObj) => async (dispatch) => {
  const updatedPurchasingObj = await updatePurchasing(purchasingObj);
  dispatch({
    type: "UPDATE_PURCHASING",
    payload: updatedPurchasingObj,
  });
};
export const updateCurrencySellRedux = (cashInObj) => async (dispatch) => {
  const updatedCashInObj = await updateCurrencySell(cashInObj);
  dispatch({
    type: "UPDATE_CURRENCY_SELL",
    payload: updatedCashInObj,
  });
};
export const updateSpendCashRedux = (cashInObj) => async (dispatch) => {
  const updatedCashInObj = await updateSpendCash(cashInObj);
  dispatch({
    type: "UPDATE_SPEND_CASH",
    payload: updatedCashInObj,
  });
};
export const handleProductPayRedux = (productObj) => async (dispatch) => {
  const updatedProductObj = await handleProductPay(productObj);
  dispatch({
    type: "UPDATE_PRODUCT",
    payload: updatedProductObj,
  });
};
export const handleProductPayGenerateRedux =
  (productObj) => async (dispatch) => {
    const updatedProductObj = await handleProductPayGenerate(productObj);
    dispatch({
      type: "UPDATE_PRODUCT",
      payload: updatedProductObj,
    });
  };
export const handleSourcingCashInRedux = (sourcingObj) => async (dispatch) => {
  const updatedSourcingObj = await handleSourcingCashIn(sourcingObj);
  dispatch({
    type: "UPDATE_SOURCING",
    payload: updatedSourcingObj,
  });
};
export const handlePurchasingCashInRedux =
  (purchasingObj) => async (dispatch) => {
    const updatedPurchasingObj = await handlePurchasingCashIn(purchasingObj);
    dispatch({
      type: "UPDATE_PURCHASING",
      payload: updatedPurchasingObj,
    });
  };
export const handleSourcingCashOutRedux = (sourcingObj) => async (dispatch) => {
  const updatedSourcingObj = await handleSourcingCashOut(sourcingObj);
  dispatch({
    type: "UPDATE_SOURCING",
    payload: updatedSourcingObj,
  });
};
export const handleSourcingAgentPayRedux =
  (sourcingObj) => async (dispatch) => {
    const updatedSourcingObj = await handleSourcingAgentPay(sourcingObj);
    dispatch({
      type: "UPDATE_SOURCING",
      payload: updatedSourcingObj,
    });
  };
export const handlePurchasingCashOutRedux =
  (purchasingObj) => async (dispatch) => {
    const updatedPurchasingObj = await handlePurchasingCashOut(purchasingObj);
    dispatch({
      type: "UPDATE_PURCHASING",
      payload: updatedPurchasingObj,
    });
  };

export const deleteTakaTransferRedux = (expenseId) => async (dispatch) => {
  await deleteTakaTransfer(expenseId);
  dispatch({
    type: "DELETE_TAKA_TRANSFER",
    payload: expenseId,
  });
};
export const deleteForwarderRedux = (forwarderId) => async (dispatch) => {
  await deleteForwarder(forwarderId);
  dispatch({
    type: "DELETE_FORWARDER",
    payload: forwarderId,
  });
};
export const deleteProductRedux = (productId) => async (dispatch) => {
  await deleteProduct(productId);
  dispatch({
    type: "DELETE_PRODUCT",
    payload: productId,
  });
};
export const deleteSourcingRedux = (id) => async (dispatch) => {
  await deleteSourcing(id);
  dispatch({
    type: "DELETE_SOURCING",
    payload: id,
  });
};
export const deletePurchasingRedux = (id) => async (dispatch) => {
  await deletePurchasing(id);
  dispatch({
    type: "DELETE_PURCHASING",
    payload: id,
  });
};
export const deleteCurrencySellRedux = (cashInId) => async (dispatch) => {
  await deleteCurrencySell(cashInId);
  dispatch({
    type: "DELETE_CURRENCY_SELL",
    payload: cashInId,
  });
};
export const deleteSpendCashRedux = (cashInId) => async (dispatch) => {
  await deleteSpendCash(cashInId);
  dispatch({
    type: "DELETE_SPEND_CASH",
    payload: cashInId,
  });
};

// Parcel Rates

export const getAllExpressRatesParcelRedux = () => async (dispatch) => {
  const expressRatesParcelArray = await getAllExpressRatesParcel();
  dispatch({
    type: "GET_ALL_EXPRESS_RATES_PARCEL",
    payload: expressRatesParcelArray,
  });
};

export const uploadExpressRatesParcelRedux =
  (countryObj) => async (dispatch) => {
    const uploadedExpressRatesParcelObj = await uploadExpressRatesParcel(
      countryObj
    );
    dispatch({
      type: "UPLOAD_EXPRESS_RATES_PARCEL",
      payload: uploadedExpressRatesParcelObj,
    });
  };

export const updateExpressRatesParcelRedux =
  (countryObj) => async (dispatch) => {
    const updatedExpressRatesParcelObj = await updateExpressRatesParcel(
      countryObj
    );
    dispatch({
      type: "UPDATE_EXPRESS_RATES_PARCEL",
      payload: updatedExpressRatesParcelObj,
    });
  };
export const deleteExpressRatesParcelRedux =
  (countryId) => async (dispatch) => {
    await deleteExpressRatesParcel(countryId);
    dispatch({
      type: "DELETE_EXPRESS_RATES_PARCEL",
      payload: countryId,
    });
  };

// D2D Rates
export const getAllD2DRatesRedux =
  (freightType, country) => async (dispatch) => {
    const allD2DRatesAirArray = await getAllD2DRates(freightType, country);
    dispatch({
      type: "GET_ALL_D2D_RATES",
      payload: allD2DRatesAirArray,
    });
  };

export const uploadD2DRatesRedux =
  (freightType, country, productTypeObj) => async (dispatch) => {
    const uploadedD2DRatesObj = await uploadD2DRates(
      freightType,
      country,
      productTypeObj
    );
    dispatch({
      type: "UPLOAD_D2D_RATES",
      payload: uploadedD2DRatesObj,
    });
  };

export const updateD2DRatesRedux =
  (freightType, country, productTypeObj) => async (dispatch) => {
    const updatedD2DRatesObj = await updateD2DRates(
      freightType,
      country,
      productTypeObj
    );
    dispatch({
      type: "UPDATE_D2D_RATES",
      payload: updatedD2DRatesObj,
    });
  };
export const deleteD2DRatesRedux =
  (freightType, country, productTypeId) => async (dispatch) => {
    await deleteD2DRates(freightType, country, productTypeId);
    dispatch({
      type: "DELETE_D2D_RATES",
      payload: { freightType, country, productTypeId },
    });
  };

// Recharge
export const getAllRechargeRequestRedux = () => async (dispatch) => {
  const rechargeRequestArray = await getAllRechargeRequest();
  dispatch({ type: "GET_ALL_RECHARGE_REQUEST", payload: rechargeRequestArray });
};

export const getAllPaymentRequestRedux = () => async (dispatch) => {
  const paymentRequestArray = await getAllPaymentRequest();
  dispatch({ type: "GET_ALL_PAYMENT_REQUEST", payload: paymentRequestArray });
};
export const getAllPaymentRequestSourcingsRedux = () => async (dispatch) => {
  const paymentRequestArray = await getAllPaymentRequestSourcings();
  dispatch({ type: "GET_ALL_PAYMENT_REQUEST", payload: paymentRequestArray });
};
export const getAllPaymentRequestExpressRedux = () => async (dispatch) => {
  const paymentRequestArray = await getAllPaymentRequestExpress();
  dispatch({ type: "GET_ALL_PAYMENT_REQUEST", payload: paymentRequestArray });
};

export const updateRechargeRequestStatusRedux =
  (rechargeRequestObj) => async (dispatch) => {
    const updatedRechargeRequestObj = await updateRechargeRequestStatus(
      rechargeRequestObj
    );
    dispatch({
      type: "UPDATE_RECHARGE_REQUEST_STATUS",
      payload: updatedRechargeRequestObj,
    });
  };
export const updatePaymentRequestStatusRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestStatus(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_STATUS",
      payload: updatedPaymentRequestObj,
    });
  };
export const updatePaymentRequestSourcingStatusRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestSourcingStatus(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_STATUS",
      payload: updatedPaymentRequestObj,
    });
  };
export const updatePaymentRequestStatusExpressRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestStatusExpress(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_STATUS",
      payload: updatedPaymentRequestObj,
    });
  };

export const rechargeUserRedux = (rechargeObj) => async (dispatch) => {
  const rechargedUserObj = await rechargeUser(rechargeObj);
  dispatch({
    type: "RECHARGE_USER",
    payload: rechargedUserObj,
  });
};
export const rechargeUserFromRechargeRequestRedux =
  (rechargeObj) => async (dispatch) => {
    const updatedRechargeRequestObj = await rechargeUserFromRechargeRequest(
      rechargeObj
    );
    dispatch({
      type: "UPDATE_RECHARGE_REQUEST_STATUS",
      payload: updatedRechargeRequestObj,
    });
  };

export const updateUserStatusRedux = (userObj) => async (dispatch) => {
  const updatedUserObj = await updateUserStatus(userObj);
  dispatch({
    type: "UPDATE_USER_STATUS",
    payload: updatedUserObj,
  });
};
export const updateAdminStatusRedux = (adminObj) => async (dispatch) => {
  const updatedAdminObj = await updateAdminStatus(adminObj);
  dispatch({
    type: "UPDATE_ADMIN_STATUS",
    payload: updatedAdminObj,
  });
};

export const getAllRechargeDayRedux = () => async (dispatch) => {
  const rechargeDaysArray = await getAllRechargeDays();
  dispatch({ type: "GET_ALL_RECHARGE_DAY", payload: rechargeDaysArray });
};

export const getAllRechargesOfSingleDateRedux = (date) => async (dispatch) => {
  const rechargesArray = await getAllRechargesOfSingleDate(date);
  dispatch({
    type: "GET_ALL_RECHARGES_OF_SINGLE_DAY",
    payload: rechargesArray,
  });
  return rechargesArray;
};

// payments
export const getAllPaymentDayRedux = () => async (dispatch) => {
  const paymentDaysArray = await getAllPaymentDays();
  dispatch({ type: "GET_ALL_PAYMENT_DAY", payload: paymentDaysArray });
};
export const getAllPaymentDayExpressRedux = () => async (dispatch) => {
  const paymentDaysArray = await getAllPaymentDaysExpress();
  dispatch({ type: "GET_ALL_PAYMENT_DAY", payload: paymentDaysArray });
};

export const getAllPaymentsOfSingleDateRedux = (date) => async (dispatch) => {
  const paymentsArray = await getAllPaymentsOfSingleDate(date);
  dispatch({
    type: "GET_ALL_PAYMENTS_OF_SINGLE_DAY",
    payload: paymentsArray,
  });
  return paymentsArray;
};
export const getAllPaymentsOfSingleDateExpressRedux =
  (date) => async (dispatch) => {
    const paymentsArray = await getAllPaymentsOfSingleDateExpress(date);
    dispatch({
      type: "GET_ALL_PAYMENTS_OF_SINGLE_DAY",
      payload: paymentsArray,
    });
    return paymentsArray;
  };

// refund
export const getAllRefundRequestRedux = (refundStatus) => async (dispatch) => {
  const refundsArray = await getAllRefundRequest(refundStatus);
  dispatch({ type: "GET_ALL_REFUNDS", payload: refundsArray });
};

export const updateRefundRedux = (refundObj) => async (dispatch) => {
  const updatedRefundObj = await updateRefund(refundObj);
  dispatch({ type: "UPDATE_REFUND", payload: updatedRefundObj });
};

// notice
export const createNoticeRedux = (noticeObj) => async (dispatch) => {
  const createdNoticeObj = await createNotice(noticeObj);
  dispatch({ type: "CREATE_NOTICE", payload: createdNoticeObj });
};
export const createIntroRedux = (introObj) => async (dispatch) => {
  const createdIntroObj = await createIntro(introObj);
  dispatch({ type: "CREATE_INTRO", payload: createdIntroObj });
};

export const getAllNoticesRedux = () => async (dispatch) => {
  const noticesArray = await getAllNotices();
  dispatch({ type: "GET_ALL_NOTICES", payload: noticesArray });
};

// blogs
export const createBlogRedux = (blogObj) => async (dispatch) => {
  const createdBlogObj = await createBlog(blogObj);
  dispatch({ type: "CREATE_BLOG", payload: createdBlogObj });
};
export const getAllBlogsRedux = () => async (dispatch) => {
  const blogsArray = await getAllBlogs();
  dispatch({ type: "GET_ALL_BLOGS", payload: blogsArray });
};
export const updateBlogRedux = (blogObj) => async (dispatch) => {
  const updatedBlogObj = await updateBlog(blogObj);
  dispatch({ type: "UPDATE_BLOG", payload: updatedBlogObj });
};

export const getAllIntrosRedux = () => async (dispatch) => {
  const introsArray = await getAllIntros();
  dispatch({ type: "GET_ALL_INTROS", payload: introsArray });
};

export const updateNoticeRedux = (noticeObj) => async (dispatch) => {
  const updatedNoticeObj = await updateNotice(noticeObj);
  dispatch({ type: "UPDATE_NOTICE", payload: updatedNoticeObj });
};

export const uploadImageRedux = (currentAdmin, file) => async (dispatch) => {
  const updatedAdminObj = await uploadImage(currentAdmin, file);
  dispatch({
    type: "UPDATE_ADMIN",
    payload: updatedAdminObj,
  });
};

export const updateAdminRedux = (updatedAdmin) => async (dispatch) => {
  console.log(updatedAdmin);
  const updatedUserObj = await updateAdmin(updatedAdmin);
  dispatch({
    type: "UPDATE_ADMIN",
    payload: updatedUserObj,
  });
};

export const makePaymentRedux =
  (total, invoicesToPay, currentUser, admin, parcelsArray, paymentMethod) =>
  async (dispatch) => {
    const updatedOrdersArray = await makePayment(
      total,
      invoicesToPay,
      currentUser,
      admin,
      parcelsArray,
      paymentMethod
    );
    dispatch({
      type: "UPDATE_MULTIPLE_ORDERS",
      payload: updatedOrdersArray,
    });
  };
export const makePaymentSourcingRedux =
  (total, invoicesToPay, currentUser, admin, parcelsArray, paymentMethod) =>
  async (dispatch) => {
    const updatedOrdersArray = await makePaymentSourcing(
      total,
      invoicesToPay,
      currentUser,
      admin,
      parcelsArray,
      paymentMethod
    );
    dispatch({
      type: "UPDATE_MULTIPLE_SOURCINGS",
      payload: updatedOrdersArray,
    });
  };
export const makePaymentExpressRedux =
  (total, invoicesToPay, currentUser, admin, parcelsArray, paymentMethod) =>
  async (dispatch) => {
    const updatedOrdersArray = await makePaymentExpress(
      total,
      invoicesToPay,
      currentUser,
      admin,
      parcelsArray,
      paymentMethod
    );
    dispatch({
      type: "UPDATE_MULTIPLE_ORDERS_EXPRESS",
      payload: updatedOrdersArray,
    });
  };
