import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllCnfRedux,
  uploadTakaTransferRedux,
  uploadCurrencySellRedux,
  getAllPendingTakaTransferByDayRedux,
  getAllPendingCurrencySellByDayRedux,
  deleteTakaTransferRedux,
  deleteCurrencySellRedux,
  updateTakaTransferRedux,
  updateCurrencySellRedux,
  getSingleCashSummaryRedux,
  getAllExpenseRedux,
  getAllCashInsRedux,
  getAllSpendCashRedux,
} from "../../../actions/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

export class DailyExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "expense",
    };
  }

  componentDidMount = async () => {
    const { expenses, allOffices, allCnfs, allEmployees } = this.props;
    let [day, month, year] = this.props.match.params.date.split("-");
    day = parseInt(day);
    month = parseInt(month) - 1;
    year = parseInt(year);
    let date = new Date(year, month, day);
    this.props.getAllExpenseRedux(date.toLocaleDateString("en-GB"));
    this.props.getAllCashInsRedux(date.toLocaleDateString("en-GB"));
    this.props.getAllSpendCashRedux(date.toLocaleDateString("en-GB"));
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  render() {
    const { expenseObj, type } = this.state;
    const { expenses, allOffices, allCnfs, allEmployees, cashIns, spendCash } =
      this.props;
    let [day, month, year] = this.props.match.params.date.split("-");
    day = parseInt(day);
    month = parseInt(month) - 1;
    year = parseInt(year);
    let date = new Date(year, month, day);
    console.log(date.toDateString());

    console.log(this.props);
    let total = 0;
    let totalCashIns = 0;
    let totalSpendCash = 0;
    expenses.map((expense) => (total += parseInt(expense.amount)));
    spendCash.map((expense) => (totalSpendCash += parseInt(expense.amount)));
    cashIns.map(
      (expense) =>
        (totalCashIns +=
          expense.type === "rupee"
            ? parseInt((expense.amount * 100) / expense.sellRate)
            : parseInt(expense.amount * expense.sellRate))
    );
    return (
      <Fragment>
        <Breadcrumb title={date.toDateString()} parent="Cash Summary" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <Tabs>
            <TabList
              className="nav nav-tabs tab-coupon"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Tab
                className="nav-link transfer-method"
                onClick={(e) => {
                  this.setState({ type: "expense" });
                  this.clickActive(e);
                }}
                style={{ fontSize: 25 }}
              >
                Taka Transfer
              </Tab>

              <Tab
                className="nav-link transfer-method"
                onClick={(e) => {
                  this.setState({ type: "cashIn" });
                  this.clickActive(e);
                }}
                style={{ fontSize: 25 }}
              >
                Currency Transfer
              </Tab>
              <Tab
                className="nav-link transfer-method"
                onClick={(e) => {
                  this.setState({ type: "spendCash" });
                  this.clickActive(e);
                }}
                style={{ fontSize: 25 }}
              >
                Spend Cash
              </Tab>
            </TabList>
          </Tabs>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#ff8084",
                      }}
                    ></i>
                    {date.toDateString()}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table
                      className={`table table-bordered table-striped table-hover ${
                        window.innerWidth < 600 ? "table-responsive" : ""
                      }`}
                    >
                      {type === "expense" ? (
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              From
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              To
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Method
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Note
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Entry By
                            </th>
                          </tr>
                        </thead>
                      ) : type == "spendCash" ? (
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Category
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Sub Category
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              From
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Note
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Entry By
                            </th>
                          </tr>
                        </thead>
                      ) : (
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              From
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              To
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Buy Rate
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Sell Rate
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Note
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Total
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Entry By
                            </th>
                          </tr>
                        </thead>
                      )}
                      {type === "expense" ? (
                        <tbody>
                          {expenses.map((expense, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{expense.customerFrom}</td>
                              <td>{expense.customerTo}</td>
                              <td>{expense.method}</td>
                              <td>{expense.note}</td>
                              <td>{expense.amount}Tk</td>
                              <td>{expense.receiveBy || expense.expenseBy}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={4} style={{ border: 0 }}></td>
                            <td style={{ fontWeight: "bold", border: 0 }}>
                              Total
                            </td>
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              {total}Tk
                            </td>
                          </tr>
                        </tbody>
                      ) : type == "spendCash" ? (
                        <tbody>
                          {spendCash.map((expense, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{expense.category}</td>
                              <td>{expense.subCategory}</td>
                              <td>{expense.customerFrom}</td>

                              <td>{expense.note}</td>
                              <td>{expense.amount}Tk</td>
                              <td>{expense.receiveBy || expense.expenseBy}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={4} style={{ border: 0 }}></td>
                            <td style={{ fontWeight: "bold", border: 0 }}>
                              Total
                            </td>
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              {totalSpendCash}Tk
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {cashIns.map((expense, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{expense.customerFrom}</td>
                              <td>{expense.customerTo}</td>
                              <td>{expense.buyRate}</td>
                              <td>{expense.sellRate}</td>
                              <td>{expense.note}</td>
                              <td>{expense.amount}Tk</td>
                              <td>
                                {expense.type === "rupee"
                                  ? parseInt(
                                      expense.amount * (100 / expense.sellRate)
                                    )
                                  : parseInt(expense.amount * expense.sellRate)}
                                Tk
                              </td>
                              <td>{expense.receiveBy || expense.expenseBy}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={6} style={{ border: 0 }}></td>
                            <td style={{ fontWeight: "bold", border: 0 }}>
                              Total
                            </td>
                            <td style={{ fontWeight: "bold" }} colSpan={3}>
                              {totalCashIns}Tk
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentAdmin: state.admins.currentAdmin,
    expenses: state.expenses.expenses,
    cashIns: state.cashIns.cashIns,
    spendCash: state.spendCash.spendCash,
  };
};

export default connect(mapStateToProps, {
  getAllCnfRedux,
  uploadTakaTransferRedux,
  uploadCurrencySellRedux,
  getAllPendingTakaTransferByDayRedux,
  getAllPendingCurrencySellByDayRedux,
  deleteTakaTransferRedux,
  deleteCurrencySellRedux,
  updateTakaTransferRedux,
  updateCurrencySellRedux,
  getSingleCashSummaryRedux,
  getAllCashInsRedux,
  getAllExpenseRedux,
  getAllSpendCashRedux,
})(DailyExpense);
